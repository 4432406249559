import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import PropTypes from 'prop-types';

import auth from '../../utils/auth';
import AdminStandalone from "./../admin/AdminStandalone";
import {getRedirectSearch, PathLogout} from "src/js/components/routes/NavHelper";

export default function PrivateAccountRoute({component: Component, ...rest}) {
  const isAuthed = auth.isAuthenticated();

  return (
    <Route
      {...rest}
      render={(props) => isAuthed === true
        ? (
          <AdminStandalone history={props.history}>
            <Component {...props} />
          </AdminStandalone>
        )
        : <Redirect to={{pathname: PathLogout, search: getRedirectSearch(props.history.location)}} />}
    />
  )
}

PrivateAccountRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object
  ]),
  authed: PropTypes.bool,
  history: PropTypes.object,
  location: PropTypes.object
};
