import constants from '../constants';

const validations = constants.validations.productItem;

export default function productItemValidator(field, value, options) {
	switch (field) {
		case 'categoryId':
			return validateCategory(value, options);
		case 'name':
			return validateName(value, options);
		case 'nameAr':
			return validateNameAr(value, options);
		case 'detials':
			return validateDetails(value, options);
		case 'detialsAr':
			return validateDetailsAr(value, options);
		case 'imageUrls':
			return validateImageUrls(value, options);
		case 'price':
			return validatePrice(value, options);
	}
}

export function validateCategory(value) {
	const prefix = 'admin.productItem.validation.category';

	if (validations.category.isRequired && !value) {
		return prefix + '.required';
	}
}

export function validateName(value) {
	const prefix = 'admin.productItem.validation.name';

	if (validations.name.isRequired && !value) {
		return prefix + '.required';
	}
	if (validations.name.maxChar && value.length > validations.name.maxChar) {
		return prefix + '.max_char';
	}
}

export function validateNameAr(value) {
	const prefix = 'admin.productItem.validation.name_ar';

	if (validations.nameAr.isRequired && !value) {
		return prefix + '.required';
	}
	if (validations.nameAr.maxChar && value.length > validations.nameAr.maxChar) {
		return prefix + '.max_char';
	}
}

export function validateDetails(value) {
	const prefix = 'admin.productItem.validation.details';

	if (validations.details.isRequired && !value) {
		return prefix + '.required';
	}
	if (validations.details.maxChar && value.length > validations.details.maxChar) {
		return prefix + '.max_char';
	}
}

export function validateDetailsAr(value) {
	const prefix = 'admin.productItem.validation.details_ar';

	if (validations.detailsAr.isRequired && !value) {
		return prefix + '.required';
	}
	if (validations.detailsAr.maxChar && value.length > validations.detailsAr.maxChar) {
		return prefix + '.max_char';
	}
}

export function validateImageUrls(value) {
	const prefix = 'admin.productItem.validation.imageUrls';

	if (validations.imageUrls.isRequired && (!value || !value.length)) {
		return prefix + '.required';
	}
}

export function validatePrice(value) {
	const prefix = 'admin.productItem.validation.price';

	if (validations.price.isRequired && !value) {
		return prefix + '.required';
	}
	if (validations.price.min !== undefined && validations.price.min > value) {
		return prefix + '.min';
	}
}