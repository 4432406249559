import {TNC_URL, TNC_URL_AR} from "./services";

export default class LinkHelper {

  static open(url, inNewTab = false) {
    if (inNewTab) {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) {
        newWindow.opener = null
      }
    } else {
      window.open(url, '_self');
    }
  }

  static openTnc(languageCode) {
    let url;
    if (languageCode === 'ar') {
      url = TNC_URL_AR;
    } else {
      url = TNC_URL;
    }
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null
    }
  }

  static openLink(link, internalOnly = false) {
    let isHandled = false;
    if (link) {
      let scheme = null;
      if (link.includes(':')) {
        const colonSplits = link.split(':');
        if (colonSplits && colonSplits.length > 0) {
          scheme = colonSplits[0];
        }
      }
      if (!scheme) {
        link = "https://" + link;
        scheme = 'https';
      }
      if (scheme === 'http' || scheme === 'https') {
        isHandled = LinkHelper.handleUrl(link, internalOnly);
        if (!internalOnly && !isHandled) {
          isHandled = true;
          LinkHelper.handleExternalLink(link);
        }
      } else {
        if (!internalOnly) {
          isHandled = true;
          LinkHelper.handleExternalLink(link);
        }
      }
    }
    return isHandled;
  }

  static handleUrl(urlWithParams) {
    if (!urlWithParams) {
      return false;
    }
    // urlWithParams = urlWithParams.toLowerCase();
    const urlWithParamsSplits = urlWithParams.split('?');
    if (!urlWithParamsSplits || urlWithParamsSplits.length === 0) {
      return false;
    }

    let url = urlWithParamsSplits[0];
    let params = null;
    if (urlWithParamsSplits.length > 1) {
      params = urlWithParamsSplits[1];
    }

    if (url.toLowerCase().startsWith('http')) {
      url = url.replace(/http:\/\//gi, '');
      url = url.replace(/https:\/\//gi, '');
    }
    const urlSplits = url.split('/');
    const validHosts = ['play.rab7.com'];
    let isHandled = false;
    if (urlSplits && urlSplits.length > 0 && validHosts.includes(urlSplits[0].toLowerCase())) {
      LinkHelper.open(url);
      isHandled = true;
    }
    // if (urlSplits && urlSplits.length > 0 && validHosts.includes(urlSplits[0].toLowerCase())) {
    //   if (urlSplits.length === 3) {
    //     if (urlSplits[1] === 'c') {
    //       const activityId = urlSplits[2];
    //       if (activityId) {
    //         let displayPlace = 0;
    //         if (params) {
    //           const paramsSplits = params.split('&');
    //           if (paramsSplits && paramsSplits.length > 0) {
    //             paramsSplits.forEach((param) => {
    //               const paramSplits = param.split('=');
    //               if (paramSplits && paramSplits.length > 1) {
    //                 if (paramSplits[0] === 'dp') {
    //                   displayPlace = paramSplits[1];
    //                 }
    //               }
    //             });
    //           }
    //         }
    //         if (!displayPlace) {
    //           displayPlace = 2;
    //         }
    //
    //         isHandled = true;
    //         NavigationService.push(ActivityDetailScreen.getNavigationName(), {
    //           activityId: activityId,
    //           viaLink: 1,
    //           dp: displayPlace,
    //         }, 'A:' + activityId);
    //       }
    //     } else if (urlSplits[1] === 'crs') {
    //       const activityId = urlSplits[2];
    //       if (activityId) {
    //         let postId = 0;
    //         if (params) {
    //           const paramsSplits = params.split('&');
    //           if (paramsSplits && paramsSplits.length > 0) {
    //             paramsSplits.forEach((param) => {
    //               const paramSplits = param.split('=');
    //               if (paramSplits && paramSplits.length > 1) {
    //                 if (paramSplits[0] === 'p') {
    //                   postId = paramSplits[1];
    //                 }
    //               }
    //             });
    //           }
    //         }
    //
    //         isHandled = true;
    //         NavigationService.push(ActivityReviewsScreen.getNavigationName(), {
    //           activity: {id: activityId},
    //           activityPostId: postId,
    //         }, 'AR:' + activityId);
    //       }
    //     } else if (urlSplits[1] === 'cr') {
    //       isHandled = true;
    //       NavigationService.push(ActivityReviewDetailScreen.getNavigationName(), {
    //         postId: urlSplits[2],
    //         dp: 29
    //       }, 'ARD:'+ urlSplits[2]);
    //     } else if (urlSplits[1] === 'r') {
    //       isHandled = true;
    //       NavigationService.push(PostDetailScreen.getNavigationName(), {
    //         postId: urlSplits[2]
    //       }, 'R:'+ urlSplits[2]);
    //     } else if (urlSplits[1] === 'pl') {
    //       isHandled = true;
    //       NavigationService.push(PostDetailScreen.getNavigationName(), {
    //         postType: POST_TYPE_PLACE,
    //         postId: urlSplits[2]
    //       }, 'R:'+ urlSplits[2]);
    //     } else if (urlSplits[1] === 'p' || urlSplits[1] === 'dp') {
    //       isHandled = true;
    //       let businessId = null;
    //       if (params) {
    //         const paramsSplits = params.split('&');
    //         if (paramsSplits && paramsSplits.length > 0) {
    //           const firstParam = paramsSplits[0];
    //           const firstParamSplits = firstParam.split('=');
    //           if (firstParamSplits && firstParamSplits.length > 1) {
    //             if (firstParamSplits[0] === 'b') {
    //               businessId = firstParamSplits[1];
    //             }
    //           }
    //         }
    //       }
    //       let businessDeliveryType;
    //       if (urlSplits[1] === 'p') {
    //         businessDeliveryType = STORE_TYPE_EXPRESS;
    //       } else if (urlSplits[1] === 'dp') {
    //         businessDeliveryType = STORE_TYPE_NORMAL;
    //       }
    //       if (businessId && businessDeliveryType) {
    //         OrderScreen.navigateForProductLink(urlSplits[2], {id: businessId, businessDeliveryType: businessDeliveryType});
    //       }
    //     } else if (urlSplits[1] === 'ps') {
    //       isHandled = true;
    //       OrderScreen.navigateForBusinessLink({id: urlSplits[2], businessDeliveryType: STORE_TYPE_EXPRESS});
    //     } else if (urlSplits[1] === 'bps') {
    //       isHandled = true;
    //       OrderScreen.navigateForBranchLink({id: urlSplits[2], business: {businessDeliveryType: STORE_TYPE_EXPRESS}});
    //     } else if (urlSplits[1] === 'dps') {
    //       isHandled = true;
    //       OrderScreen.navigateForBusinessLink({id: urlSplits[2], businessDeliveryType: STORE_TYPE_NORMAL});
    //     } else if (urlSplits[1] === 'dbps') {
    //       isHandled = true;
    //       OrderScreen.navigateForBranchLink({id: urlSplits[2], business: {businessDeliveryType: STORE_TYPE_NORMAL}});
    //     } else if (urlSplits[1] === 'os') {
    //       isHandled = true;
    //       NavigationService.push(BusinessOffersScreen.getNavigationName(), {
    //         business: {
    //           id: urlSplits[2]
    //         },
    //       }, 'OS:'+ urlSplits[2]);
    //     } else if (urlSplits[1] === 'b') {
    //       isHandled = true;
    //       NavigationService.push(BusinessProfileScreen.getNavigationName(), {
    //         businessId: urlSplits[2],
    //       }, 'B:'+ urlSplits[2]);
    //     }
    //   } if (urlSplits.length === 2) {
    //     if (urlSplits[1] === 'sa') {
    //       isHandled = true;
    //       let affiliateId = null;
    //       if (params) {
    //         const paramsSplits = params.split('&');
    //         if (paramsSplits && paramsSplits.length > 0) {
    //           const firstParam = paramsSplits[0];
    //           const firstParamSplits = firstParam.split('=');
    //           if (firstParamSplits && firstParamSplits.length > 1) {
    //             if (firstParamSplits[0] === 'aid') {
    //               affiliateId = firstParamSplits[1];
    //             }
    //           }
    //         }
    //       }
    //       NavigationService.push(AdsScreen.getNavigationName(), {
    //         aid: affiliateId,
    //       }, AdsScreen.getNavigationName());
    //     } else if (urlSplits[1] === 'w') {
    //       isHandled = true;
    //       PointsScreen.navigateToWalletBalance();
    //     } else if (urlSplits[1] === 'aw') {
    //       isHandled = true;
    //       NavigationService.navigate(ActivityWinnersScreen.getPushNavigationName());
    //     }
    //   }
    // }
    return isHandled;
  }

  static handleExternalLink(link) {
    LinkHelper.open(link, true);
  }
}
