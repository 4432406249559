import {get, isArray, isString} from 'lodash';
import constants from '../constants';

const validations = constants.validations.systemUser;

export default function systemUserValidator(field, value, options) {
  switch(field) {
    case 'mobileNumber':
      return validateMobileNumber(value, options);
    case 'email':
      return validateEmail(value, options);
    case 'branchIds':
      return validateBranchIds(value, options);
    case 'access':
      return validateAccess(value, options);
  }
}

export function validateMobileNumber(value, options) {
  const prefix = 'admin.system_user.validation.mobile_number';
  const mobileNumber = (isString(value) && get(value.split('-'), '[1]')) || '';

  if (validations.mobileNumber.isRequired && !value && !options.is_edit) {
    return prefix + '.required';
  }
  if ((mobileNumber.length < validations.mobileNumber.minChar) && !options.is_edit) {
    return 'registration.validation.mobile_number.min_char';
  }
  if ((mobileNumber.length > validations.mobileNumber.maxChar) && !options.is_edit) {
    return 'registration.validation.mobile_number.max_char';
  }
}

export function validateEmail(value, options) {
  const prefix = 'admin.system_user.validation.email';

  if (validations.email.isRequired && !value && !options.is_edit) {
    return prefix + '.required';
  }
}

export function validateBranchIds(value) {
  const prefix = 'admin.system_user.validation.branch_ids';

  if (validations.branchIds.isRequired && !isArray(value)) {
    return prefix + '.required';
  }
}

export function validateAccess(value) {
  const prefix = 'admin.system_user.validation.access';
  if (validations.access.isRequired && (!isArray(value) || value.length === 0)) {
    return prefix + '.required';
  }
}
