import {cloneDeep} from 'lodash';
import initialState from './initialState';
import * as types from '../constants/actionTypes';

export default function redeemOfferReducer(state = cloneDeep(initialState.redeemOffer), action) {
  let newState = cloneDeep(state);

  switch (action.type) {
    case types.RESET_REDEEM_CODE_ERROR_MESSAGE: {
      newState.errMsg = '';
      return newState;
    }

    case types.RESET_REDEEM_CODE_ACTIVITY: {
      newState.activity = null;
      newState.errMsg = '';
      newState.isSuccess = false;
      return newState;
    }

    case '@@router/LOCATION_CHANGE':
    case types.DO_REDEEM_CODE: {
      return newState;
    }

    case types.DO_REDEEM_CODE_SUCCESS: {
      newState = cloneDeep(initialState.redeemOffer);
      newState.activity = null;
      newState.isSuccess = false;
      if (action.data && action.data.isBranchNeeded) {
        newState.activity = action.data;
      } else {
        newState.isSuccess = true;
      }
      return newState;
    }

    case types.DO_REDEEM_CODE_FAILURE: {
      if (action.data && action.data.error) {
        newState.errMsg = action.data.error;
      } else {
        newState.errMsg = 'generic.server_error_msg';
      }
      return newState;
    }

    default:
      return state;
  }
}
