import {isString, isNumber} from 'lodash';
import constants from '../constants';

const validations = constants.validations.redeemPointFormula;

export default function redeemPointFormulaValidator(field, value, options) {
  switch(field) {
    case 'name':
      return validateFormulaName(value, options);
    case 'priority':
      return validatePriority(value, options);
    case 'startAt':
      return validateStartAt(value, options);
    case 'endAt':
      return validateEndAt(value, options);
    case 'minSpendsToRedeem':
      return validateMinSpendsToRedeem(value, options);
    case 'minPointsCanRedeem':
      return validateMinPointsCanRedeem(value, options);
    case 'maxPointsCanRedeem':
      return validateMaxPointsCanRedeem(value, options);
    case 'maxPointsCanRedeemDaily':
      return validateMaxPointsCanRedeemDaily(value, options);
    case 'minWalletBalanceToRedeem':
      return validateMinWalletBalanceToRedeem(value, options);
  }
}

export function validateFormulaName(value) {
  const prefix = 'admin.redeem_point_formula.validation.name';

  if (validations.name.isRequired && (!isString(value) || !value.trim())) {
    return prefix + '.required';
  }

  if(value.length > validations.name.maxChar) {
    return prefix + '.max_char';
  }
}

export function validatePriority(value) {
  const prefix = 'admin.redeem_point_formula.validation.priority';

  if (validations.priority.isRequired && !isNumber(value)) {
    return prefix + '.required';
  }

  if (value < validations.priority.minNum) {
    return prefix + '.min_num';
  }

  if (value > validations.priority.maxNum) {
    return prefix + '.max_num';
  }
}

export function validateStartAt(value) {
  const prefix = 'admin.redeem_point_formula.validation.start_at';

  if (validations.startAt.isRequired && !(value instanceof Date) && (!isString(value) || !value.trim())) {
    return prefix + '.required';
  }
}

export function validateEndAt(value) {
  const prefix = 'admin.redeem_point_formula.validation.end_at';

  if (validations.endAt.isRequired && !(value instanceof Date) && (!isString(value) || !value.trim())) {
    return prefix + '.required';
  }
}

export function validateMinSpendsToRedeem(value) {
  const prefix = 'admin.redeem_point_formula.validation.minSpendsToRedeem';

  if (validations.minSpendsToRedeem.isRequired && !isNumber(value)) {
    return prefix + '.required';
  }

  if (value < validations.minSpendsToRedeem.minNum) {
    return prefix + '.min_num';
  }
}

export function validateMinPointsCanRedeem(value) {
  const prefix = 'admin.redeem_point_formula.validation.minPointsCanRedeem';

  if (validations.minPointsCanRedeem.isRequired && !isNumber(value)) {
    return prefix + '.required';
  }

  if (value < validations.minPointsCanRedeem.minNum) {
    return prefix + '.min_num';
  }
}

export function validateMaxPointsCanRedeem(value) {
  const prefix = 'admin.redeem_point_formula.validation.maxPointsCanRedeem';

  if (validations.maxPointsCanRedeem.isRequired && !isNumber(value)) {
    return prefix + '.required';
  }

  if (value < validations.maxPointsCanRedeem.minNum) {
    return prefix + '.min_num';
  }
}

export function validateMaxPointsCanRedeemDaily(value) {
  const prefix = 'admin.redeem_point_formula.validation.maxPointsCanRedeemDaily';

  if (validations.maxPointsCanRedeemDaily.isRequired && !isNumber(value)) {
    return prefix + '.required';
  }

  if (value < validations.maxPointsCanRedeemDaily.minNum) {
    return prefix + '.min_num';
  }
}

export function validateMinWalletBalanceToRedeem(value) {
  const prefix = 'admin.redeem_point_formula.validation.minWalletBalanceToRedeem';

  if (validations.minWalletBalanceToRedeem.isRequired && !isNumber(value)) {
    return prefix + '.required';
  }

  if (value < validations.minWalletBalanceToRedeem.minNum) {
    return prefix + '.min_num';
  }
}
