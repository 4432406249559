import * as types from 'src/js/constants/actionTypes';

export const showSnackbar = (snackbar) => {
  return {
    type: types.SHOW_SNACKBAR,
    snackbar: snackbar
  };
};

export const hideSnackbar = () => {
  return {
    type: types.HIDE_SNACKBAR,
  };
};
