import React from "react";
import videojs from "video.js";
import {cloneDeep} from "lodash";
import {logDebug} from "src/js/utils/AppLog";

export default class VideoPlayer extends React.Component {

  constructor() {
    super();

    this.state = {
      isError: false,
      errorMessage: null
    }
  }

  componentDidMount() {
    const props = cloneDeep(this.props);
    delete props.loop;
    this.player = videojs(this.videoNode, props, this.onPlayerReady);
  }

  onPlayerReady = () => {
    if (this.player) {
      const {eListenerOnPlay, eListenerOnPause, eListenerOnError, eListenerOnProgress, eListenerOnEnded, eListenerOnLoaded} = this.props;
      if (eListenerOnProgress) {
        this.player.on('timeupdate', this.onTimeUpdate);
      }
      if (eListenerOnPlay) {
        this.player.on('play', this.onPlay);
      }
      if (eListenerOnPause) {
        this.player.on('pause', this.onPause);
      }
      if (eListenerOnError) {
        this.player.on('error', this.onError);
      }
      if (eListenerOnEnded) {
        this.player.on('ended', this.onEnded);
      }
      if (eListenerOnLoaded) {
        eListenerOnLoaded();
      }
    }
  }

  // destroy player on unmount
  componentWillUnmount() {
    this.disposePlayer();
  }

  disposePlayer() {
    const {eListenerOnPlay, eListenerOnPause, eListenerOnError, eListenerOnProgress, eListenerOnEnded} = this.props;
    if (this.player) {
      if (eListenerOnProgress) {
        this.player.off('timeupdate', this.onTimeUpdate);
      }
      if (eListenerOnPlay) {
        this.player.off('play', this.onPlay);
      }
      if (eListenerOnPause) {
        this.player.off('pause', this.onPause);
      }
      if (eListenerOnError) {
        this.player.off('error', this.onError);
      }
      if (eListenerOnEnded) {
        this.player.off('ended', this.onEnded);
      }

      this.player.dispose();
      this.player = null;
    }
  }

  onTimeUpdate = (event) => {
    if (this.props.eListenerOnProgress) {
      const duration = this.player.duration();
      if (isNaN(duration) || !duration) {
        return;
      }
      const currentTime = this.player.currentTime();
      this.props.eListenerOnProgress((currentTime / duration) * 100, duration, currentTime);
    }
  }

  onPlay = () => {
    if (this.props.eListenerOnPlay) {
      this.props.eListenerOnPlay();
    }
  }

  onPause = () => {
    if (this.props.eListenerOnPause) {
      this.props.eListenerOnPause();
    }
  }

  onError = (event) => {
    const errorMessage = this.player.error();
    console.log('videojs-error: '+ this.props.sources[0].src, errorMessage);
    if (this.props.eListenerOnError) {
      this.props.eListenerOnError();
    }
    this.disposePlayer();
    // this.setState({isError: true, errorMessage: errorMessage});
  }

  onEnded = (event) => {
    if (this.props.loop) {
      this.player.currentTime(0);
      this.player.play();
    }
    if (this.props.eListenerOnEnded) {
      this.props.eListenerOnEnded();
    }
  }

  play(hasInteraction, onPlayStart) {
    logDebug('VideoPlayer-play', this.props.sources[0].src);
    try {
      if (this.player) {
        this.player.muted(!hasInteraction);
        const promise = this.player.play();

        const _this = this;
        if (promise !== undefined) {
          promise.then(function () {
            // Autoplay started!
            if (onPlayStart) {
              onPlayStart();
            }
          }).catch(function (error) {
            logDebug('VideoPlayer-error', error);
            if (_this.props.autoplay) {
              // if (_this.props.eListenerOnMuted) {
              //   _this.props.eListenerOnMuted();
              // }
              // _this.play(false);
            } else {
              if (_this.props.eListenerOnError) {
                _this.props.eListenerOnError(error);
              }
            }
          });
        }
      }
    } catch (e) {
       logDebug('VideoPlayer-playError', e);
    }
  }

  pause() {
    try {
      if (this.player) {
        this.player.muted(true);
        this.player.pause();
      }
    } catch (e) {
      logDebug('VideoPlayer-pauseError', e);
    }
  }

  unmute() {
    try {
      if (this.player) {
        this.player.muted(false);
      }
    } catch (e) {
      logDebug('VideoPlayer-pauseError', e);
    }
  }

  isPaused() {
    try {
      if (this.player) {
        return this.player.paused();
      }
    } catch (e) {
      logDebug('VideoPlayer-pauseError', e);
    }
    return false;
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <div style={this.props.style}>
        {this.state.isError ?
          <div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0}}>
            {/*<TextView color={ColorWhite}>{this.state.errorMessage ? this.state.errorMessage : "Error Occured"}</TextView>*/}
            {/*<Button onClick={() => {}} disableElevation={true} variant={'contained'} style={{color: ColorWhite}}>{"Reload"}</Button>*/}
          </div>
          : <div className='data-vjs-player' style={this.props.style}>
            <video
              playsInline
              ref={node => (this.videoNode = node)}
              poster={this.props.poster}
              controlsList="nodownload noremoteplayback"
              className='video-js' style={this.props.style} />
          </div>
        }
      </div>
    );
  }
}
