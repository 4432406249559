import initialState from 'src/js/reducers/initialState';
import * as types from 'src/js/constants/actionTypes';
import {cloneDeep} from "lodash";

export default function uiReducer(state = initialState.ui, action) {

  switch (action.type) {
    case types.SHOW_SNACKBAR: {
      const newState = cloneDeep(state);
      newState.snackbar = {
        show: true,
        message: action.snackbar.message,
        messageId: action.snackbar.messageId,
        type: action.snackbar.type
      }
      return newState;
    }

    case types.HIDE_SNACKBAR: {
      const newState = cloneDeep(state);
      newState.snackbar = {};
      return newState;
    }

    default:
      return state;
  }
}
