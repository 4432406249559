import {isString, isNumber} from 'lodash';
import constants from '../constants';

const validations = constants.validations.branch;

export default function branchValidator(field, value) {
  switch(field) {
    case 'name':
      return validateBranchName(value);
    case 'nameAr':
      return validateBranchNameAr(value);
    case 'address':
      return validateBranchAddress(value);
    case 'addressAr':
      return validateBranchAddressAr(value);
    case 'localityId':
      return validateLocalityId(value);
    case 'subLocalityId':
      return validateSubLocalityId(value);
    case 'latitude':
      return validateLatitude(value);
    case 'longitude':
      return validateLongitude(value);
  }
}

export function validateBranchName(value) {
  const prefix = 'admin.branch.validation.name';

  if (validations.name.isRequired && (!isString(value) || !value.trim())) {
    return prefix + '.required';
  }
}

export function validateBranchNameAr(value) {
  const prefix = 'admin.branch.validation.name_ar';

  if (validations.nameAr.isRequired && (!isString(value) || !value.trim())) {
    return prefix + '.required';
  }
}

export function validateBranchAddress(value) {
  const prefix = 'admin.branch.validation.address';

  if (validations.address.isRequired && (!isString(value) || !value.trim())) {
    return prefix + '.required';
  }
}

export function validateBranchAddressAr(value) {
  const prefix = 'admin.branch.validation.address_ar';

  if (validations.addressAr.isRequired && (!isString(value) || !value.trim())) {
    return prefix + '.required';
  }
}

export function validateLocalityId(value) {
  const prefix = 'admin.branch.validation.locality_id';

  if (validations.localityId.isRequired && !isNumber(value)) {
    return prefix + '.required';
  }
}

export function validateSubLocalityId(value) {
  const prefix = 'admin.branch.validation.sub_locality_id';

  if (validations.subLocalityId.isRequired && !isNumber(value)) {
    return prefix + '.required';
  }
}

export function validateLatitude(value) {
  const prefix = 'admin.branch.validation.latitude';

  if (validations.latitude.isRequired && !value) {
    return prefix + '.required';
  }
}

export function validateLongitude(value) {
  const prefix = 'admin.branch.validation.longitude';

  if (validations.longitude.isRequired && !value) {
    return prefix + '.required';
  }
}
