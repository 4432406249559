import registrationValidator from './registrationValidator';
import branchValidator from './branchValidator';
import giftValidator from './giftValidator';
import earnPointFormulaValidator from './earnPointFormulaValidator';
import redeemPointFormulaValidator from './redeemPointFormulaValidator';
import employeeValidator from './employeeValidator';
import postValidator from './postValidator';
import activityValidator from './activityValidator';
import systemUserValidator from './systemUserValidator';
import profileValidator from './profileValidator';
import productCategoryValidator from './productCategoryValidator';
import productItemValidator from './productItemValidator';

export default function validate(type, field, value, options) {
  switch(type) {
    case 'registration':
      return registrationValidator(field, value, options);
    case 'branch':
      return branchValidator(field, value, options);
    case 'gift':
      return giftValidator(field, value, options);
    case 'earnPointFormula':
      return earnPointFormulaValidator(field, value, options);
    case 'redeemPointFormula':
      return redeemPointFormulaValidator(field, value, options);
    case 'employee':
      return employeeValidator(field, value, options);
    case 'post':
      return postValidator(field, value, options);
    case 'activity':
      return activityValidator(field, value, options);
    case 'systemUser':
      return systemUserValidator(field, value, options);
    case 'profile':
      return profileValidator(field, value, options);
    case 'productCategory':
      return productCategoryValidator(field, value, options);
    case 'productItem':
      return productItemValidator(field, value, options);
  }
}
