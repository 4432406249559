import {Scene} from 'phaser';

export class GCCPreloader extends Scene {
  constructor() {
    super('GCCPreloader');
  }

  init() {
    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;
    //  We loaded this image in our GFKBoot Scene, so we can display it here
    const bgImage = this.add.image(gameWidth / 2, gameHeight / 2, 'background');
    bgImage.setDisplaySize(gameWidth, gameHeight);

    const barWidth = gameWidth - 96;
    const innerBarWidth = barWidth - 8;

    //  A simple progress bar. This is the outline of the bar.
    this.add.rectangle(gameWidth / 2, gameHeight / 2, barWidth, 32).setStrokeStyle(1, 0xEEEEEE);

    //  This is the progress bar itself. It will increase in size from the left based on the % of progress.
    const bar = this.add.rectangle((gameWidth - innerBarWidth) / 2, gameHeight / 2, 4, 28, 0xEEEEEE);

    //  Use the 'progress' event emitted by the LoaderPlugin to update the loading bar
    this.load.on('progress', (progress) => {

      //  Update the progress bar (our bar is 464px wide, so 100% = 464px)
      bar.width = 4 + (innerBarWidth * progress);

    });
  }

  preload() {
    let bgAudioUrl = null;
    let cannonTopImageUrl = null;
    let targetTop1ImageUrl = null;
    let targetTop2ImageUrl = null;
    let targetTop3ImageUrl = null;
    const options = this.game.options;
    if (options) {
      if (options.images && options.images.length > 0) {
        options.images.forEach((item) => {
          if (item.url && (item.url.startsWith('file:') || item.url.startsWith('blob:') || item.url.startsWith('http:') || item.url.startsWith('https:'))) {
            if (item.number === 0) {
              cannonTopImageUrl = item.url;
            } else if (item.number === 1) {
              targetTop1ImageUrl = item.url;
            } else if (item.number === 2) {
              targetTop2ImageUrl = item.url;
            } else if (item.number === 3) {
              targetTop3ImageUrl = item.url;
            }
          }
        });
      }
      if (options.audios && options.audios.length > 0) {
        options.audios.forEach((item) => {
          if (item.url && (item.url.startsWith('file:') || item.url.startsWith('blob:') || item.url.startsWith('http:') || item.url.startsWith('https:'))) {
            if (item.number === 0) {
              bgAudioUrl = item.url;
            }
          }
        });
      }
    }

    this.load.setPath('assets/gameCannonCraze');

    this.load.image('cannonTop', cannonTopImageUrl || 'cannonTop.png');
    this.load.image('targetTop1', targetTop1ImageUrl || 'targetTop1.png');
    this.load.image('targetTop2', targetTop2ImageUrl || 'targetTop2.png');
    this.load.image('targetTop3', targetTop3ImageUrl || 'targetTop3.png');
    this.load.audio('audio_bg', bgAudioUrl || 'audios/audio_bg.mp3');

    this.load.image('rawCannon', 'cannon.png');
    this.load.image('rawTarget', 'target.png');
    this.load.image('ball', 'ball.png');
    this.load.image('colorSplash', 'colorSplash.png');
    this.load.image('plus1', 'plus1.png');
    this.load.image('plus2', 'plus2.png');
    this.load.image('timer', 'timer.png');

    this.load.audio('audio_fire', 'audios/audio_fire.mp3');
    this.load.audio('audio_hit', 'audios/audio_hit.mp3');
    this.load.audio('audio_hit2', 'audios/audio_hit2.mp3');
    this.load.audio('audio_hit3', 'audios/audio_hit3.mp3');
    this.load.audio('audio_game_end', 'audios/audio_game_end.mp3');
    this.load.audio('audio_game_win', 'audios/audio_game_win.mp3');
  }

  create() {
    const cannonWidth = 280;
    const cannonHeight = cannonWidth * 2;
    const renderTexture1 = this.make.renderTexture({x: 0, y: 0, width: cannonWidth, height: cannonHeight}, false);
    const image11 = this.add.image(0, 0, 'rawCannon')
      .setDisplaySize(cannonWidth, cannonHeight)
      .setVisible(false);
    const image12 = this.add.image(0, 0, 'cannonTop')
      .setDisplaySize(cannonWidth-80, cannonWidth-80)
      .setVisible(false);
    renderTexture1.draw(image11, cannonWidth/2, cannonHeight/2, 1);
    renderTexture1.draw(image12, cannonWidth/2, cannonHeight/2 + 8, 1);
    renderTexture1.saveTexture('cannon');
    renderTexture1.destroy(true);

    const targetSize1 = 250;
    const renderTexture2 = this.make.renderTexture({x: 0, y: 0, width: targetSize1, height: targetSize1}, false);
    const image21 = this.add.image(0, 0, 'rawTarget')
      .setDisplaySize(targetSize1, targetSize1)
      .setVisible(false);
    const image22 = this.add.image(0, 0, 'targetTop1')
      .setDisplaySize(targetSize1*.8, targetSize1*.8)
      .setVisible(false);
    renderTexture2.draw(image21, targetSize1/2, targetSize1/2, 1);
    renderTexture2.draw(image22, targetSize1/2, targetSize1/2 - 6, 1);
    renderTexture2.saveTexture('target1');
    renderTexture2.destroy(true);

    const plusWidth = 90;
    const plusHeight = plusWidth/1.2;

    const targetSize2 = 270;
    const renderTexture3 = this.make.renderTexture({x: 0, y: 0, width: targetSize2, height: targetSize2 + plusHeight - 15}, false);
    const image31 = this.add.image(0, 0, 'rawTarget')
      .setDisplaySize(targetSize2, targetSize2)
      .setVisible(false);
    const image32 = this.add.image(0, 0, 'targetTop2')
      .setDisplaySize(targetSize2*.8, targetSize2*.8)
      .setVisible(false);
    const imagePlus33 = this.add.image(0, 0, 'plus1')
      .setDisplaySize(plusWidth, plusHeight)
      .setVisible(false);
    renderTexture3.draw(imagePlus33, targetSize2 - plusWidth/2 - 15, plusHeight/2 + 18, 1);
    renderTexture3.draw(image31, targetSize2/2, targetSize2/2 + plusHeight - 15, 1);
    renderTexture3.draw(image32, targetSize2/2, targetSize2/2 - 7 + plusHeight - 15, 1);
    renderTexture3.saveTexture('target2');
    renderTexture3.destroy(true);

    const targetSize3 = 300;
    const renderTexture4 = this.make.renderTexture({x: 0, y: 0, width: targetSize3, height: targetSize3 + plusHeight - 15}, false);
    const image41 = this.add.image(0, 0, 'rawTarget')
      .setDisplaySize(targetSize3, targetSize3)
      .setVisible(false);
    const image42 = this.add.image(0, 0, 'targetTop3')
      .setDisplaySize(targetSize3*.8, targetSize3*.8)
      .setVisible(false);
    const imagePlus43 = this.add.image(0, 0, 'plus2')
      .setDisplaySize(plusWidth, plusHeight)
      .setVisible(false);
    renderTexture4.draw(imagePlus43, targetSize3 - plusWidth/2 - 15, plusHeight/2 + 18, 1);
    renderTexture4.draw(image41, targetSize3/2, targetSize3/2 + plusHeight - 15, 1);
    renderTexture4.draw(image42, targetSize3/2, targetSize3/2 - 8 + plusHeight - 15, 1);
    renderTexture4.saveTexture('target3');
    renderTexture4.destroy(true);

    this.scene.start('GCCGame');
  }
}
