import * as types from '../constants/actionTypes';
import { wrapCall } from '../utils/serviceUtils';
import { api } from '../utils/services';
import constants from '../constants';

export const showNotificationMenu = () => {
  return {
    type: types.SHOW_NOTIFICATIONS_MENU
  };
}

export const hideNotificationMenu = () => {
  return {
    type: types.HIDE_NOTIFICATIONS_MENU
  };
}

export const doUpdateNotificationState = (id, state) => {
  return wrapCall(
    api.post(`/business/me/notifications/state/${id}`, {state}),
    types.DO_UPDATE_NOTIFICATION_STATE,
    {id, state}
  );
};

export const markNotificationAsRead = (id) => {
  return {
    type: types.DO_UPDATE_NOTIFICATION_STATE_SUCCESS,
    id,
    state: constants.notifications.state.READ,
  };
};

export const fetchNotifications = (page, perPage, isLoadingDisabled) => {
  return wrapCall(
    api.get(`/business/me/notifications?page=${page}&perPage=${perPage || 10}`),
    types.FETCH_NOTIFICATIONS,
    {isLoadingDisabled}
  );
};

export const deleteNotification = (id) => {
  return wrapCall(
    api.delete(`/business/me/notifications/${id}`),
    types.DELETE_NOTIFICATION,
    {id}
  );
};

export const deleteNotifications = (ids) => {
  return wrapCall(
    api.delete(`/business/me/notifications/${ids}`),
    types.DELETE_NOTIFICATIONS,
    {ids}
  );
};

export const showSystemNotificationModal = (systemNotificationId) => {
  return {
    type: types.SHOW_SYSTEM_NOTIFICATION_MODAL,
    id: systemNotificationId
  }
}

export const hideSystemNotificationModal = () => {
  return {
    type: types.HIDE_SYSTEM_NOTIFICATION_MODAL
  }
}
