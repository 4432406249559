import * as types from '../constants/actionTypes';
import {wrapCall} from '../utils/serviceUtils';
import {api} from '../utils/services';
import {logDebug} from "../utils/AppLog";
import {UPDATE_USER_WALLET} from "../constants/actionTypes";

export const changeLocale = (locale) => {
  return {
    type: types.CHANGE_LOCALE,
    locale
  };
};

export const resetHelp = () => {
  return {
    type: types.RESET_HELP
  }
};

export const fetchHelp = (id) => {
  return wrapCall(
    api.get(`/helps/${id}`),
    types.FETCH_HELP,
    {id}
  );
};

export const fetchNationalities = () => {
  return wrapCall(
    api.get(`/business/nationalities`),
    types.FETCH_NATIONALITIES,
  );
}

export const cacheListData = (scrollPosition, type, data) => {
  return {
    type: types.CACHE_LIST_DATA,
    data: {scrollPosition, type, data}
  };
};

export const resetCacheListData = () => {
  logDebug('resetCacheListData');
  return {
    type: types.RESET_CACHE_LIST_DATA,
  };
};

export const updateUserWallet = (wallet) => {
  return {
    type: types.UPDATE_USER_WALLET,
    wallet: wallet
  };
};

export const updateUserInfo = (userName, userImageUrl) => {
  return {
    type: types.UPDATE_USER_INFO,
    userInfo: {
      name: userName,
      imageUrl: userImageUrl
    }
  };
};
