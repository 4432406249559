import PropTypes from 'prop-types';
import React, {forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useRef} from 'react';
import Phaser from "phaser";
import {GAHBoot} from "src/gameArcHunter/scenes/GAHBoot.js";
import {GAHPreloader} from "src/gameArcHunter/scenes/GAHPreloader.js";
import {GAHGame} from "src/gameArcHunter/scenes/GAHGame.js";
import {logDebug} from "src/js/utils/AppLog";

const config = {
  type: Phaser.AUTO,
  transparent: true,
  width: 1080,
  height: 1920,
  parent: 'game-container',
  scale: {
    mode: Phaser.Scale.FIT,
    autoCenter: Phaser.Scale.CENTER_BOTH
  },
  physics: {
    default: 'arcade',
    arcade: {
      // debug: true
    }
  },
  scene: [
    GAHBoot,
    GAHPreloader,
    GAHGame
  ],
};

export const gameArcHunterInfo = () => {
  return {
    'name': [{'en': 'Tap Tap Shots'}, {'ar': 'Tap Tap Shots'}],
    'imageUrl': 'assets/gameArcHunter/ball.png',
    'guide': [{'en': 'Tap Tap Shots'}, {'ar': 'Tap Tap Shots'}],
    'themeColor': '#E0863B',
    'resultType': 2,
    'scoreMid': 10,
    'configs': {
      'images': [
        {'number': 0, 'label': 'Ball', 'url': 'assets/gameArcHunter/ballTop.png'},
        {'number': 1, 'label': 'Ad Banner', 'url': 'assets/gameArcHunter/adImage.png'}],
      'audios': [{'label': 'Background', 'url': 'assets/gameArcHunter/audios/game_bg.m4a'}],
    },
  };
}

export const GameArcHunter = forwardRef((props, ref) => {
  const {width, height, options, bounceLoopSide} = props;

  const game = useRef();

  useImperativeHandle(ref, () => ({
    start, restart, mute
  }));

  const start = () => {
    game.current.scene.switch('GAHGame');
  }

  const restart = (options) => {
    game.current.options.canPlay = options.canPlay;
    GAHEventBus.emit('restart');
  }

  const mute = (mute) => {
    if (game.current.options) {
      game.current.options.mute = mute;
    }
    GAHEventBus.emit('mute', mute);
  }

  const onAppDidFocus = () => {
    if (game.current) {
      game.current.resume();
    }
  }

  const onAppDidBlur = () => {
    if (game.current) {
      game.current.pause();
    }
  }

  // Create the gameArcHunter inside a useLayoutEffect hook to avoid the gameArcHunter being created outside the DOM
  useLayoutEffect(() => {
    if (game.current === undefined) {
      logDebug('DG-game useLayoutEffect');
      if (height > config.height) {
        config.height = height;
      }
      config.width = config.height*width/height;

      const parent = 'game-container';
      game.current = new Phaser.Game({...config, parent});
      game.current.options = options;
      game.current.options.bounceLoopSide = bounceLoopSide;

      if (ref !== null) {
        ref.current = {game: game.current, scene: null};
      }
    }

    return () => {
      if (game.current) {
        logDebug('DG-game destroyed');
        game.current.destroy(true);
        game.current = undefined;
      }
    }
  }, [ref, width, height, options]);

  useEffect(() => {
    GAHEventBus.on('eventOnStart', () => {
      if (options && options.onStart) {
        options.onStart();
      }
    });
    GAHEventBus.on('eventOnEnd', (score) => {
      if (options && options.onEnd) {
        options.onEnd(score);
      }
    });
    window.addEventListener('focus', onAppDidFocus);
    window.addEventListener('blur', onAppDidBlur);

    return () => {
      GAHEventBus.removeListener('eventOnStart');
      GAHEventBus.removeListener('eventOnEnd');
      GAHEventBus.removeAllListeners('mute');
      GAHEventBus.removeAllListeners('restart');

      window.removeEventListener('focus', onAppDidFocus);
      window.removeEventListener('blur', onAppDidBlur);
    }
  }, [options])

  return (
    <div id="game-container" style={{backgroundColor: options.bgColor}}></div>
  );

});

// Props definitions
GameArcHunter.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  options: PropTypes.object
}

export const GAHEventBus = new Phaser.Events.EventEmitter();
