import {isNumber, isArray} from 'lodash';
import constants from '../constants';

const validations = constants.validations.employee;

export default function employeeValidator(field, value, options) {
  switch(field) {
    case 'mobileNumbers':
      return validateMobileNumbers(value, options);
    case 'role':
      return validateRole(value, options);
    case 'branchIds':
      return validateBranchIds(value, options);
    case 'access':
      return validateAccess(value, options);
  }
}

export function validateMobileNumbers(value, options) {
  const prefix = 'admin.employee.validation.mobile_numbers';

  if (validations.mobileNumbers.isRequired && (!isArray(value) || value.length === 0) && !options.is_edit) {
    return prefix + '.required';
  }
}

export function validateRole(value) {
  const prefix = 'admin.employee.validation.role';

  if (validations.role.isRequired && !isNumber(value)) {
    return prefix + '.required';
  }
}

export function validateBranchIds(value) {
  const prefix = 'admin.employee.validation.branch_ids';

  if (validations.branchIds.isRequired && !isArray(value)) {
    return prefix + '.required';
  }
}

export function validateAccess(value, option) {
  const prefix = 'admin.employee.validation.access';
  if ((option.data && option.data.role === 33) && (!isArray(value) || (value.indexOf(1) === -1))) {
    return prefix + '.required';
  }
}
