import {Button, makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Space from "./Space";
import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {ColorSubTitle, ColorTitle} from "src/styles/Colors";
import {BORDER_RADIUS, DRAWER_WIDTH, TOOLBAR_HEIGHT, TOOLBAR_HEIGHT_SM, TOOLBAR_HEIGHT_XS} from "src/styles/Styles";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-container .MuiDialog-paper": {
      margin: 20,
      // marginLeft: DRAWER_WIDTH + 20,
      [theme.breakpoints.down('sm')]: {
        margin: 20,
        marginLeft: 20,
      },
      [theme.breakpoints.down('xs')]: {
        margin: 8,
        marginLeft: 8,
      },
    },
    "& .MuiDialogContent-root": {
      padding: '8px 20px',
      [theme.breakpoints.down('xs')]: {
        padding: '8px 16px',
      }
    },
  },
  dialogNoMargin: {
    [theme.breakpoints.down('xs')]: {
      "& .MuiDialog-container .MuiDialog-paper": {
        margin: 0,
      },
    }
  },
  dialogFullScreenTransparentNoSpace: {
    overflow: 'hidden',
    "& .MuiDialog-paper": {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    "& .MuiDialogContent-root": {
      padding: 0,
    },
  },
  dialogFullScreenTransparent: {
    overflow: 'hidden',
    "& .MuiDialog-paper": {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    "& .MuiDialogContent-root": {
      padding: '8px 20px',
      [theme.breakpoints.down('xs')]: {
        padding: '8px 16px',
      }
    },
  },
  dialogFullScreen: {
    borderTopLeftRadius: BORDER_RADIUS,
    borderTopRightRadius: BORDER_RADIUS,
    overflow: 'hidden',
    marginTop: 1.2 * TOOLBAR_HEIGHT,
    [theme.breakpoints.down('xs')]: {
      marginTop: 1.2 * TOOLBAR_HEIGHT_XS,
    },
    "& .MuiDialogContent-root": {
      padding: '8px 20px',
      [theme.breakpoints.down('xs')]: {
        padding: '8px 16px',
      }
    },
  },
  dialogFullScreenNoSpace: {
    // borderRadius: BORDER_RADIUS,
    // borderTopRightRadius: BORDER_RADIUS,
    overflow: 'hidden',
    "& .MuiDialogContent-root": {
      padding: 0,
    },
  },
  dialogActionsViewOutline: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 8,
    marginBottom: 8,
    minHeight: 40,
    minWidth: 300,
  },
  dialogNoTitleSpace: {
    marginTop: 12,
    [theme.breakpoints.down('xs')]: {
      marginTop: 8,
    }
  },
}));

const DialogView = ({
                      show,
                      fullScreen,
                      marginTop,
                      noSpace,
                      transparent,
                      title,
                      message,
                      content,
                      renderContent,
                      positiveText,
                      onPositiveClicked,
                      neutralText,
                      onNeutralClicked,
                      negativeText,
                      onNegativeClicked,
                      onClose,
}) => {
  const classes = useStyles();
  let className = classes.dialog;
  let styles = null;
  if (fullScreen) {
    if (transparent) {
      if (noSpace) {
        className = classes.dialogFullScreenTransparentNoSpace;
      } else {
        className = classes.dialogFullScreenTransparent;
      }
    } else if (noSpace) {
      className = classes.dialogFullScreenNoSpace;
    } else {
      className = classes.dialogFullScreen;
    }
    if (marginTop) {
      styles = {marginTop: marginTop}
    }
  }
  return (
    <Dialog
      disableBackdropClick
      fullScreen={fullScreen}
      onClose={onClose}
      open={show}
      className={className}
      style={styles}
    >
      {title ?
        <DialogTitle>
          {/*<IconButton color={"primary"} className={classes.dialogCloseButton} onClick={this.onDialogClose}>*/}
          {/*  <CloseIcon />*/}
          {/*</IconButton>*/}
          <Typography variant="h6" align="center" style={{color: ColorTitle}}>
            {title}
          </Typography>
        </DialogTitle>
        : !fullScreen ?
          <div className={classes.dialogNoTitleSpace}/>
          : null
      }
      {message ?
        <DialogContent>
          <Typography align="center" style={{color: ColorSubTitle}}>
            {message}
          </Typography>
        </DialogContent>
        : null
      }
      {content ?
        <DialogContent>
          {content}
        </DialogContent>
        : null
      }
      {renderContent ?
        <DialogContent>
          {renderContent()}
        </DialogContent>
        : null
      }
      {positiveText ?
        <DialogActions>
          <div className={classes.dialogActionsViewOutline}>
            {negativeText ?
              <Button
                color="secondary"
                disableElevation
                variant={'contained'}
                onClick={onNegativeClicked}>
                {negativeText}
              </Button>
              : null
            }
            {negativeText ?
              <Space verticalSpace={2} />
              : null
            }
            {neutralText ?
              <Button
                color="secondary"
                disableElevation
                variant={'outlined'}
                onClick={onNeutralClicked}>
                {neutralText}
              </Button>
              : null
            }
            {neutralText ?
              <Space verticalSpace={2} />
              : null
            }
            {positiveText ?
              <Button
                color="primary"
                disableElevation
                variant={'contained'}
                onClick={onPositiveClicked}>
                {positiveText}
              </Button>
              : null
            }
          </div>
        </DialogActions>
        : null
      }
    </Dialog>
  );
};

export default DialogView;
