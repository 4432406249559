import React from 'react';
import PropTypes from "prop-types";
import {MenuItem, Tooltip} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import Menu from "@material-ui/core/Menu";
import {connect} from "react-redux";
import {baseStyles, withMediaQueries, withMultipleStyles} from "src/styles/Styles";
import Button from "@material-ui/core/Button";
import {map} from "lodash";
import {changeLocale} from "../../actions/appActions";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LanguageIcon from '@material-ui/icons/Language';

const useStyles = () => ({
  buttonLanguage: {
  }
});

class LanguageSelector extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showMenu: false
    }
  }

  showDropdown = (e) => {
    this.setState({showMenu: true, menuElement: e.target});
  }

  render() {
    const {classes, app, isXS, hideIcon, asInner} = this.props;
    const selectedLocale = this.getSelectedLocale();

    const exStyle = asInner ? {width: '100%', height: '100%'} : {};
    return (
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', ...exStyle}}>
        <Tooltip title={<FormattedMessage id='generic.tooltip.changeLanguage' />}>
          <div className={classes.viewRow}>
            <Button
              size={isXS ? 'medium' : 'large'}
              // className={classes.buttonLanguage}
              color={'secondary'}
              id={"buttonLanguage"}
              style={{paddingLeft: 12, paddingRight: 12, exStyle}}
              startIcon={!hideIcon ? <LanguageIcon /> : null}
              endIcon={<ExpandMoreIcon />}
              onClick={(e) => {
                this.setState({showMenu: true, menuElement: e.target});
              }}
            >{selectedLocale.label}
            </Button>
          </div>
        </Tooltip>
        <Menu
          id="menu"
          anchorEl={this.state.menuElement}
          getContentAnchorEl={null}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          transformOrigin={{vertical: 'top', horizontal: 'center'}}
          keepMounted
          open={this.state.showMenu}
          onClose={() => {
            this.setState({showMenu: false, menuElement: null});
          }}>
          {
            map(app.locale, (locale) => {
              return (
                <MenuItem style={{minWidth: 130}} key={locale.label} onClick={() => this.onChangeLanguageClicked(locale)} value={locale}>{locale.label}</MenuItem>
              )
            })
          }
        </Menu>
      </div>
    );
  }

  getSelectedLocale() {
    const {app} = this.props;
    let selectedLocale = null;
    app.locale.forEach((locale) => {
      if (locale.value === app.defaultLocale) {
        selectedLocale = locale;
      }
    });
    return selectedLocale;
  }

  onChangeLanguageClicked(locale) {
    this.setState({showMenu: false, menuElement: null})
    const lastLocale = this.getSelectedLocale();
    if (!lastLocale || lastLocale.value !== locale.value) {
      this.props.changeLocale(locale);
    }
  }
}

LanguageSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  app: PropTypes.object,
  changeLocale: PropTypes.func,
}

function mapStateToProps(state) {
  return {
    app: state.app,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeLocale: (locale) => dispatch(changeLocale(locale)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMultipleStyles(baseStyles, useStyles)(withMediaQueries([
    ['isXS', theme => theme.breakpoints.down('xs'), {defaultMatches: true}],
  ])(LanguageSelector))
);
