import {cloneDeep} from 'lodash';
import initialState from './initialState';
import * as types from '../constants/actionTypes';

export default function registerReducer(state = cloneDeep(initialState.signUp), action) {
  let newState = cloneDeep(state);

  switch (action.type) {
    case types.RESET_LOGIN_ERROR_MESSAGE: {
      newState.errMsg = '';
      return newState;
    }

    case types.DO_REGISTER: {
      newState.isRegistrationSuccess = false;
      return newState;
    }

    // case '@@router/LOCATION_CHANGE':
    // case types.DO_LOGOUT: {
    //   newState = cloneDeep(initialState.registration);
    //   return newState;
    // }

    case types.DO_REGISTER_SUCCESS: {
      newState = cloneDeep(initialState.registration);
      newState.isVerificationCodeGenerated = false;
      newState.isRegistrationSuccess = true;
      return newState;
    }

    case types.DO_REGISTER_FAILURE: {
      newState = cloneDeep(initialState.registration);
      newState.isVerificationCodeGenerated = true;
      if (action.data && action.data.error) {
        newState.errMsg = action.data.error;
      } else {
        newState.errMsg = 'generic.server_error_msg';
      }
      return newState;
    }

    case types.DO_REGISTER_GENERATE_OTP: {
      newState.isVerificationCodeGenerated = false;
      return newState;
    }

    case types.DO_REGISTER_GENERATE_OTP_SUCCESS: {
      newState.isVerificationCodeGenerated = true;
      return newState;
    }

    case types.DO_REGISTER_GENERATE_OTP_FAILURE: {
      if (action.data && action.data.error) {
        newState.errMsg = action.data.error;
      } else {
        newState.errMsg = 'generic.server_error_msg';
      }
      return newState;
    }

    default:
      return state;
  }
}
