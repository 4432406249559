import withStyles from "@material-ui/core/styles/withStyles";
import {
  ColorActivityForm,
  ColorBackground, ColorBackgroundXS,
  ColorButtonSelected,
  ColorButtonSelectedHover,
  ColorCard,
  ColorCardField,
  ColorCardFieldHover,
  ColorCardFieldSelected, ColorCardXS,
  ColorClear,
  ColorField,
  ColorFieldBgWhite,
  ColorFieldBorder, ColorFieldBorderHover,
  ColorFieldDisabled, ColorInactive,
  ColorNegative,
  ColorOrange,
  ColorOrangeDark,
  ColorSecondary,
  ColorTheme,
  ColorTitle,
  ColorWhite
} from "./Colors";
import {Button, useMediaQuery} from "@material-ui/core";
import React from "react";

export const DRAWER_WIDTH = 250;
export const BORDER_RADIUS = 10;
export const IMAGE_BORDER_RADIUS = 8;
export const BORDER_RADIUS_SMALL = 8;
export const CONTAINER_SPACE_TOP = 16;
export const CONTAINER_SPACE_TOP_XS = 12;
export const CONTAINER_SPACE = 24;
export const CONTAINER_SPACE_XS = 16;
export const FIELD_SPACE = 20;
export const FIELD_SPACE_XS = 16;
export const CARD_SPACING = 16;
export const TOOLBAR_HEIGHT = 80; // 64;
export const TOOLBAR_HEIGHT_SM = 64; // 64;
export const TOOLBAR_HEIGHT_XS = 56;
export const BOTTOM_BAR_HEIGHT = 64;
export const BOTTOM_BAR_HEIGHT_XS = 56;

export const CONTENT_MAX_WIDTH = 1780;
export const CONTENT_MAX_WIDTH_PLAY = 1840;
export const MAX_WIDTH_XS = 600;
export const FORM_MAX_WIDTH = 400;
export const FORM_WIDE_MAX_WIDTH = 500;

export const FORM_OUTER_MAX_WIDTH = 1200;
export const FORM_INNER_WIDE_MAX_WIDTH = 800;
export const FORM_INNER_X_WIDE_MAX_WIDTH = 1140;
export const FORM_INNER_MAX_WIDTH = 600;
export const FORM_LOGIN_MAX_WIDTH = 400;
export const VIEW_BESIDE_PREVIEW_MAX_WIDTH = 400;
export const SPACE_NAV_ACTION = 12;
export const SPACE_NAV_ACTION_XS = 8;

export const FONT_WEIGHT_BOLD = 700;
export const FONT_WEIGHT_MEDIUM = 500;
export const FONT_WEIGHT_NORMAL = 400;

export const PREVIEW_WIDTH = 320;
export const PREVIEW_HEIGHT = 600;
export const PREVIEW_WIDTH_MD = 300;
export const PREVIEW_HEIGHT_MD = 560;
export const PREVIEW_WIDTH_XS = 360;
export const PREVIEW_HEIGHT_XS = 672;

export const DEFAULT_BORDER = '1px solid rgba(0,0,0,0.1)';
export const IS_BOTTOM_TAB_PRESENT = false;

export const changeNavColor = (color = ColorBackgroundXS) => {
  document.querySelector("meta[name='theme-color']").setAttribute("content", ""+ color);
}

export const changeBodyColor = (color = ColorBackground) => {
  document.body.style.backgroundColor = color;
  changeNavColor(color);
}

export const WhiteColorButton = withStyles((theme) => ({
  root: {
    color: ColorTitle,
    backgroundColor: ColorWhite,
  },
}))(Button);

export const baseStyles = (theme) => ({
  containerFill: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: window.innerWidth,
  },
  button: {
  },
  buttonCamelCase: {
    textTransform: 'none',
  },
  grow: {
    flexGrow: 1,
  },
  flexGrow: {
    flexGrow: 1,
  },
  flex1: {
    flex: 1,
  },
  dialogActionsView: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: theme.spacing(1.5)
  },
  dialogActionsViewOutline: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: theme.spacing(0.5),
    minWidth: 300,
  },
  dialogCloseButton: {
    position: 'absolute',
    left: 0,
    top: 4,
  },
  displayNone: {
    display: 'none',
  },
  flexRow: {
    display: 'flex',
    width: '100%',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center'
  },
  flexRowWrap: {
    display: 'flex',
    width: '100%',
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center'
  },
  flexRowNoCenter: {
    display: 'flex',
    width: '100%',
    flex: 1,
    flexDirection: 'row',
  },
  flexColumn: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  flexColumn1: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  flexColumnNoCenter: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  flexColumnAbsolute: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  viewRowFullWidth: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center'
  },
  viewRowFullWidthNoCenter: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
  },
  viewRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  viewRowNoCenter: {
    display: 'flex',
    flexDirection: 'row',
  },
  viewColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  viewColumnFullWidth: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  viewColumnFullWidthNoCenter: {
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  viewColumnFullWidthNoCenter1: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  toolbarOffset: theme.mixins.toolbar,
  helper: {
    width: '100%',
    textAlign: 'left',
    paddingLeft: 12,
  },
  helperBottom: {
    width: '100%',
    textAlign: 'left',
    paddingLeft: 12,
    marginTop: 10,
  },
  helperError: {
    width: '100%',
    textAlign: 'left',
    color: ColorNegative,
  },
  helperErrorPadding: {
    width: '100%',
    textAlign: 'left',
    color: ColorNegative,
    paddingLeft: 12,
  },
  expandCollapseBar: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  formActivity: {
    position: 'relative',
    // width: '100%',
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    maxWidth: Math.min(window.innerWidth, FORM_MAX_WIDTH),
    [theme.breakpoints.down('xs')]: {
      maxWidth: window.innerWidth
    }
  },
  formActivityWide: {
    position: 'relative',
    // width: '100%',
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    maxWidth: Math.min(window.innerWidth, FORM_WIDE_MAX_WIDTH),
    [theme.breakpoints.down('xs')]: {
      maxWidth: 600
    }
  },
  formActivityNoPadding: {
    position: 'relative',
    // width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    // maxWidth: Math.min(window.innerWidth, FORM_INNER_WIDE_MAX_WIDTH),
    // borderRadius: BORDER_RADIUS,
    // border: '1px solid rgba(0,0,0,0.03)',
    // backgroundColor: ColorActivityForm
  },
  formActivitySmall: {
    position: 'relative',
    // width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // maxWidth: Math.min(window.innerWidth, FORM_INNER_MAX_WIDTH),
    // borderRadius: BORDER_RADIUS,
    // padding: 16,
    // paddingBottom: 0,
    // border: '1px solid rgba(0,0,0,0.03)',
    // backgroundColor: ColorActivityForm
  },
  formActivityNoBgNoBorder: {
    position: 'relative',
    width: '100%',
    // flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: Math.min(window.innerWidth, FORM_INNER_WIDE_MAX_WIDTH),
    borderRadius: BORDER_RADIUS,
    // border: '1px solid rgba(0,0,0,0.03)',
    // backgroundColor: ColorActivityFormBackground
  },
  formBoxSelection: {
    position: 'relative',
    width: '100%',
    // flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 16,
    maxWidth: Math.min(window.innerWidth, FORM_INNER_X_WIDE_MAX_WIDTH),
    borderRadius: BORDER_RADIUS,
  },
  form: {
    position: 'relative',
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // marginBottom: -FIELD_SPACE,
    maxWidth: Math.min(window.innerWidth, FORM_INNER_WIDE_MAX_WIDTH),
  },
  formWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: Math.min(window.innerWidth, FORM_INNER_MAX_WIDTH),
    zIndex: 1,
    padding: 16,
    marginBottom: 16,
  },
  formWrapperWide: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: Math.min(window.innerWidth, FORM_OUTER_MAX_WIDTH),
    zIndex: 1,
    marginBottom: 16,
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: 12,
      marginBottom: 2,
    },
  },
  formLogin: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: Math.min(window.innerWidth, FORM_LOGIN_MAX_WIDTH),
    // zIndex: 1,
    padding: 16,
    color: ColorClear
  },
  formLoginAfter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: Math.min(window.innerWidth, FORM_LOGIN_MAX_WIDTH),
    // zIndex: 1,
    padding: 16,
    backgroundColor: ColorCard,
    [theme.breakpoints.down('xs')]: {
      backgroundColor: ColorClear,
    }
  },
  imageRounded: {
    borderRadius: BORDER_RADIUS,
    backgroundColor: ColorField
  },
  textFieldNonEditable: {
    backgroundColor: ColorFieldDisabled,
  },
  textFieldBgWhite: {
    backgroundColor: ColorFieldBgWhite,
    // border: '1px solid '+ ColorFieldBgWhite,
    border: '1px solid '+ ColorFieldBorder,
  },
  textFieldBgClear: {
    backgroundColor: ColorClear,
    border: '1px solid '+ ColorFieldBorder,
  },
  buttonBaseApp: {
    display: 'flex',
    flex: 1,
    borderRadius: BORDER_RADIUS_SMALL,
    flexDirection: 'column',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.05)',
    }
  },
  buttonBaseAppDisabled: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    borderRadius: BORDER_RADIUS_SMALL,
  },
  inputField: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    borderRadius: BORDER_RADIUS,
    border: '1px solid '+ ColorFieldBorder,
    backgroundColor: ColorField,
    padding: 12,
    paddingTop: 6,
    paddingBottom: 6,
  },
  focusVisible: {},
  typoBold: {
    fontWeight: 'bold'
  },
  typoMedium: {
    fontWeight: '500'
  },
  typoNormal: {
    fontWeight: 'normal'
  },
  buttonRemoveSmall: {
    background: ColorOrange,
    '&:hover': {
      background: ColorOrangeDark,
    }
  },
  viewFullWidthWrap: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  viewFullWidthWrapCenter: {
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  mapContainer: {
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    // backgroundColor: ColorField,
    borderRadius: BORDER_RADIUS,
    padding: 8,
  },
  boxesViewField: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: BORDER_RADIUS,
    margin: -22,
    padding: 4,
  },
  boxesViewFieldError: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: BORDER_RADIUS,
    border: `1px solid red`,
    margin: -23,
    padding: 4,
  },
  boxesView: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
  },
  boxesCard: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: BORDER_RADIUS,
    overflow: 'hidden',
    justifyContent: 'flex-start',
    width: 212,
    padding: 6,
    margin: 8,
    border: '1px solid rgba(0,0,0,0.05)',
    backgroundColor: ColorCardField,
    '&:hover': {
      backgroundColor: ColorCardFieldHover,
    },
    [theme.breakpoints.down('xs')]: {
      width: 164,
      margin: 6,
    },
  },
  boxesCardSelected: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: BORDER_RADIUS,
    border: '2px solid '+ ColorTheme,
    backgroundColor: ColorCardFieldSelected,
    overflow: 'hidden',
    width: 216,
    padding: 8,
    margin: 6,
    [theme.breakpoints.down('xs')]: {
      width: 162,
      margin: 4,
    },
  },
  boxesCardImage: {
    borderRadius: BORDER_RADIUS,
    width: 200,
    height: 200/4*3,
    [theme.breakpoints.down('xs')]: {
      width: 152,
      height: 152/4*3,
    },
  },
  imagePickerWrapper: {
    borderRadius: BORDER_RADIUS,
    overflow: 'hidden',
    padding: 8,
    border: '1px solid rgba(0,0,0,0.05)',
    backgroundColor: ColorActivityForm,
    minWidth: 260,
    width: '100%',
  },
  imagePickerPlaceholder: {
    width: 48,
    height: 48,
    borderRadius: BORDER_RADIUS,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cardCheck: {
    position: 'absolute', top: 2, right: 2, backgroundColor: '#eee', borderRadius: 20
  },
  chipRoot: {height: '100%', display: 'flex', flexDirection: 'row'},
  chipLabel: { overflowWrap: 'break-word', whiteSpace: 'normal', textOverflow: 'clip', flex: 1, },
  viewLoading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  viewLoadingInside: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  toolbarHeight: {
    height: TOOLBAR_HEIGHT,
    [theme.breakpoints.down('xs')]: {
      height: TOOLBAR_HEIGHT_XS,
    }
  },
  containerError: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 400,
    padding: 24,
    // [theme.breakpoints.down('xs')]: {
    //   padding: 16,
    //   minHeight: 300,
    // },
  },
  titleColorButton: {
    color: ColorTitle,
    border: '1px solid '+ ColorFieldBorder,
    '&:hover': {
      border: '1px solid '+ ColorTitle,
    },
  },
  selectedColorButton: {
    color: ColorTheme,
    backgroundColor: ColorButtonSelected,
    border: '1px solid '+ ColorTheme,
    '&:hover': {
      backgroundColor: ColorButtonSelectedHover,
    },
  },
  whiteColorButton: {
    color: ColorTitle,
    backgroundColor: ColorWhite,
    border: '1px solid '+ ColorSecondary,
    '&:hover': {
      backgroundColor: '#EEEEEE',
    },
  },
  whiteSecondaryColorButton: {
    color: ColorSecondary,
    backgroundColor: ColorWhite,
    border: '1px solid '+ ColorSecondary,
    '&:hover': {
      backgroundColor: '#EEEEEE',
    },
  },
  transparentSecondaryColorButton: {
    color: ColorSecondary,
    border: '1px solid '+ ColorSecondary,
    '&:hover': {
      backgroundColor: '#EEEEEE',
    },
  },
  rewardsColorButton: {
    color: ColorSecondary,
    backgroundColor: '#CED5DE',
    '&:hover': {
      backgroundColor: '#DEE5EE',
    },
    [theme.breakpoints.down('xs')]: {
      backgroundColor: '#DEE5EE',
      '&:hover': {
        backgroundColor: '#CEC5CE',
      },
    },
  },
  absoluteFill: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
  absoluteTopLeft: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  absoluteTopRight: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  absoluteFillCenter: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageIconButton: {
    opacity: 1,
    '&:hover': {
      opacity: 0.8,
    }
  },
  dialog: {
    // minWidth: 400,
    // minHeight: 300,
    [theme.breakpoints.down('xs')]: {
      "& .MuiDialog-container .MuiDialog-paper": {
        margin: 8,
        // minWidth: 340,
        // minHeight: 255,
      },
    }
  },
  dialogContent: {
    // minWidth: 400,
    // minHeight: 200,
    marginLeft: 0,
    marginRight: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 16,
  },

  funCardButtonColorConsume: {
    backgroundColor: ColorOrangeDark,
    '&:hover': {
      backgroundColor: '#d25b47',
    }
  },
  funCardItemWrapper: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: BORDER_RADIUS,
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  funButtonBase: {
    display: 'flex',
    flex: 1,
    width: '100%',
    height: '100%',
    borderRadius: BORDER_RADIUS,
    flexDirection: 'column',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.05)',
    }
  },
  funCardItem: {
    borderRadius: BORDER_RADIUS,
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'hidden'
  },
  funCardItemImage: {
    borderRadius: BORDER_RADIUS,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    width: '68%',
    height: '100%',
  },
  funCardBusinessImage: {
    borderRadius: IMAGE_BORDER_RADIUS,
    width: 60,
    height: 60,
    border: DEFAULT_BORDER
  },
  funRewardMedalView: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 48,
    height: 48,
    opacity: 0.95
  },
  negativeButton: {
    color: ColorInactive,
    backgroundColor: '#FFE8E6',
    border: '1px solid rgba(234,67,53,0.25)',
    '&:hover': {
      backgroundColor: 'rgb(255,209,207)',
    },
  },
});

export const withMultipleStyles = (...params) => {
  return withStyles(theme => {
    let styles = {};
    for (let len = params.length, key = 0; key < len; key++) {
      styles = Object.assign(styles, params[key](theme));
    }
    return styles;
  });
};

export const withMediaQueries = (queries = []) => Component => props => {
  const mediaProps = {}
  queries.forEach(q => {
    mediaProps[q[0]] = useMediaQuery(q[1])
  })
  return <Component {...mediaProps} {...props} />
}
