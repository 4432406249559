import * as types from '../constants/actionTypes';
import { wrapCall } from '../utils/serviceUtils';
import { api } from '../utils/services';
import auth from '../utils/auth';
import { getFilterAsQueryString } from '../utils/routeUtils';

export const resetPostsData = (fields) => {
  return {
    type: types.RESET_POSTS_DATA,
    fields
  };
};

export const showPostCommentModal = (id) => {
  return {
    type: types.SHOW_POST_COMMENT_MODAL,
    id
  };
}

export const hidePostCommentModal = () => {
  return {
    type: types.HIDE_POST_COMMENT_MODAL
  };
}

export const updatePostData = (field, value) => {
  return {
    type: types.UPDATE_POST_COMMENT_DATA,
    field,
    value
  };
}

export const validatePostData = (field, value) => {
  return {
    type: types.VALIDATE_POST_COMMENT_DATA,
    field,
    value
  }
};

export const fetchPosts = (id, search, page, perPage, filters) => {
  return wrapCall(
    api.get(`/business/posts?page=${page}&perPage=${perPage || 10}${id ? '&businessId=' + id : ''}${search ? '&search=' + search : ''}${filters ? '&' + getFilterAsQueryString(filters) : ''}`),
    types.FETCH_POSTS,
    {id}
  );
};

export const fetchPost = (id) => {
  return wrapCall(
    api.get(`/business/posts/${id}`),
    types.FETCH_POST,
    {id}
  );
};

export const createPost = (data) => {
  return wrapCall(
    api.post(`/business/posts`, data),
    types.CREATE_POST
  );
};

export const createPostLike = (id, like) => {
  return wrapCall(
    api.post(`/business/postLikes`, {postId: id, like}),
    types.CREATE_POST_LIKE,
    {id, like}
  );
};

export const updatePost = (id, data) => {
  return wrapCall(
    api.put(`/business/posts/${id}`, data),
    types.UPDATE_POST
  );
}

export const deletePost = (id) => {
  return wrapCall(
    api.delete(`/business/posts/${id}`),
    types.DELETE_POST,
    {id}
  );
};

export const fetchPostComments = (id, search, page, perPage, isLoadingDisabled, filters) => {
  return wrapCall(
    api.get(`/business/postComments?page=${page}&perPage=${perPage || 10}${id ? '&postId=' + id : ''}${search ? '&search=' + search : ''}${filters ? '&' + getFilterAsQueryString(filters) : ''}`),
    types.FETCH_POST_COMMENTS,
    {id, isLoadingDisabled}
  );
};

export const createPostComment = (data, createdBy) => {
  let formData = new FormData();
  for (let key in data) {
    formData.append(key, data[key]);
  }
  formData.append('token', auth.getToken());

  return wrapCall(
    api.post(`/business/postComments`, formData, {'Content-Type': 'multipart/form-data'}),
    types.CREATE_POST_COMMENT,
    {comment: data, createdBy}
  );
};

export const deletePostComment = (id) => {
  return wrapCall(
    api.delete(`/business/postComments/${id}`),
    types.DELETE_POST_COMMENT,
    {id}
  );
};

export const createPostCommentLike = (id, like) => {
  return wrapCall(
    api.post(`/business/postCommentLikes`, {commentId: id, like}),
    types.CREATE_POST_COMMENT_LIKE,
    {id, like}
  );
};
