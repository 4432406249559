import { cloneDeep, isPlainObject, isEmpty, isArray, isNumber, concat, map, filter, extend, find } from 'lodash';

import initialState from './initialState';
import * as types from '../constants/actionTypes';
import constants from '../constants';

export default function notificationsReducer(state = cloneDeep(initialState.admin), action) {
  switch (action.type) {

    case types.SHOW_NOTIFICATIONS_MENU: {
      state.notifications.showModal = true;
      return state;
    }

    case types.HIDE_NOTIFICATIONS_MENU: {
      //state.notifications = cloneDeep(initialState.admin.notifications);
      return state;
    }

    case types.FETCH_NOTIFICATIONS: {
      state.notifications.errMsg = '';
      return state;
    }

    case types.FETCH_NOTIFICATIONS_SUCCESS: {
      const data = isPlainObject(action.data) && cloneDeep(action.data);
      if (!isEmpty(data) && isArray(data.data)) {
        if (data.page === 1) {
          state.notifications.paginated = data;
        } else {
          state.notifications.paginated.data = concat(state.notifications.paginated.data, data.data);
          state.notifications.paginated.page = data.page;
          state.notifications.paginated.perPage = data.perPage;
          state.notifications.paginated.canLoadMore = data.canLoadMore;
          if (typeof data.unreadCount !== 'undefined') {
            state.notifications.paginated.unreadCount = data.unreadCount
          }
        }
        state.notifications.paginated.data = addIds(state.notifications.paginated.data);
        state.notifications.paginated.totalSize = calculateTotalNotificationsSize(state.notifications.paginated);
      }
      // state.notifications.paginated.data = [
      //   {
      //     id: 1,
      //     title: 'Message Title',
      //     createdAt: '2019-05-30T14:19:40.000Z',
      //     createdBy: {username: 'test', name: 'Test User', imageUrl: 'https://rab7.dev.sennem.com/v1/images/c84ecef0-80ac-11e9-bd9a-c75c37c43b1b.png'}
      //   }
      // ]
      // state.notifications.paginated.totalSize = 10;
      return state;
    }

    case types.DO_UPDATE_NOTIFICATION_STATE: {
      state.notifications.errMsg = '';
      return state;
    }

    case types.DO_UPDATE_NOTIFICATION_STATE_SUCCESS: {
      const { id } = action;
      const postTypeNotifications = [
        constants.notifications.type.TYPE_REPLY_IN_COMMENT,
      ];
      const activityTypeNotifications = [
        constants.notifications.type.TYPE_COMMENT_IN_ACTIVITY,
        constants.notifications.type.TYPE_REPLY_IN_COMMENT_IN_ACTIVITY,
      ];
      const selectedNotification = state.notifications.paginated.data.find(({ id }) => id === action.id);
      const isSelectedNotificationOfTypePost = selectedNotification && postTypeNotifications.includes(selectedNotification.type);
      const isSelectedNotificationOfTypeActivity = selectedNotification && activityTypeNotifications.includes(selectedNotification.type);
      let totalNotificationsMarkedRead = 0;

      state.notifications.paginated.data = map(state.notifications.paginated.data, (notification) => {
        let isSelectedNotification = id === notification.id;

        // mark all notification from same post
        const isOfTypePost = postTypeNotifications.includes(notification.type);
        const isOfSamePost = isSelectedNotificationOfTypePost
          && isOfTypePost
          && notification.post.id === selectedNotification.post.id;

        // mark all notification from same activity
        const isOfTypeActivity = activityTypeNotifications.includes(notification.type);
        const isOfSameActivity = isSelectedNotificationOfTypeActivity
          && isOfTypeActivity
          && notification.activity.id === selectedNotification.activity.id;

        if (isSelectedNotification || isOfSamePost || isOfSameActivity) {
          notification.state = constants.notifications.state.READ;
          totalNotificationsMarkedRead++;
        }

        return notification;
      });

      state.notifications.paginated.unreadCount -= totalNotificationsMarkedRead;

      return state;
    }

    case types.DELETE_NOTIFICATION_SUCCESS: {
      const data = action.data;
      const deletedId = action.id;
      state.notifications.paginated.data = filter(state.notifications.paginated.data, (notification) => {
        return notification.id != deletedId;
      });
      if (data && isNumber(data.unreadCount)) {
        state.notifications.paginated.unreadCount = data.unreadCount;
      }
      if (state.notifications.paginated.totalSize > 0) {
        state.notifications.paginated.totalSize -= 1;
      }
      return state;
    }

    case types.DELETE_NOTIFICATION_FAILURE:
    case types.DELETE_NOTIFICATIONS_FAILURE:
    case types.DO_UPDATE_NOTIFICATION_STATE_FAILURE: {
      if (action.data && action.data.error) {
        state.notifications.errMsg = action.data.error;
      } else {
        state.notifications.errMsg = 'generic.server_error_msg';
      }
      return state;
    }

    case types.SHOW_SYSTEM_NOTIFICATION_MODAL: {
      const selectedSystemNotification = find(state.notifications.paginated.data, { id: action.id });
      
      if (selectedSystemNotification && selectedSystemNotification.type === constants.notifications.type.TYPE_SYSTEM) {
        state.notifications.showSystemNotification = action.id;
      }
      else {
        console.log('system notification ' + action.id + ' does not exists');
      }

      return state;
    }

    case types.HIDE_SYSTEM_NOTIFICATION_MODAL: {
      state.notifications.showSystemNotification = '';
      return state;
    }

    default:
      return state;
  }

}

const addIds = (data) => {
  return map(data, (item, index) => {
    if (!item.id) {
      if (item.systemNotification) {
        item = extend({}, item.systemNotification, item);
        item.id = (item.systemNotification.id || index);
      }
    }
    return item;
  });
}

const calculateTotalNotificationsSize = (data) => {
  const count = data.data.length;
  if (data.page === 1 && count === 0) return 0;

  if (!data.canLoadMore) {
    return ((data.page - 1) * data.perPage) + count;
  }

  return ((data.page - 1) * data.perPage) + count + 1;
};
