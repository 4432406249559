import React from 'react';

const AppImage = ({style, imageUrl, source, borderRadius, resizeMode, ...props}) => {

  if (!resizeMode) {
    resizeMode = 'cover';
  }
  if (resizeMode === 'stretch') {
    resizeMode = 'fill';
  }
  if (!style) {
    style = {};
  }
  style.objectFit = resizeMode;
  if (borderRadius) {
    style.borderRadius = borderRadius;
  }
  return (
    <img
      alt={""}
      src={imageUrl || source}
      style={style}
      {...props}/>
  );
};

export default AppImage;
