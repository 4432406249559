import {isString} from 'lodash';
import constants from '../constants';

const validations = constants.validations.post;

export default function postValidator(field, value, options) {
  switch(field) {
    case 'feedback':
      return validateFeedback(value, options);
  }
}

export function validateFeedback(value) {
  const prefix = 'admin.post.validation.address';

  if (validations.address.isRequired && (!isString(value) || !value.trim())) {
    return prefix + '.required';
  }
}
