import {Scene} from 'phaser';
import {GPREventBus} from "src/gamePacManRush/GamePacManRush";
import {addTimerEvent, checkOverlap} from "src/game/GameHelper";
import {logDebug} from "src/js/utils/AppLog";

const SPEED_PAC = 1.5;
const SPEED_PAC_INCREASE = SPEED_PAC/10;
const SPEED_SAW = 250;
const SPEED_SAW_INCREASE = SPEED_SAW/10;
const SAW_DELAY = 2500;
const SAW_DELAY_REDUCE = 100;

export class GPRGame extends Scene {

  constructor() {
    super('GPRGame');
  }

  onEventRestart = () => {
    GPREventBus.off('mute', this.onEventMute);
    GPREventBus.off('restart', this.onEventRestart);
    this.scene.stop('GPRGame');
    this.scene.start('GPRMainMenu');
  };

  onEventMute = (mute) => {
    if (this.audioEat) {
      this.audioEat.setMute(mute);
    }
    if (this.audioCrash) {
      this.audioCrash.setMute(mute);
    }
    if (this.audioGameEnd) {
      this.audioGameEnd.setMute(mute);
    }
    if (this.audioGameWin) {
      this.audioGameWin.setMute(mute);
    }
  };

  create() {
    this.stringGameOver = this.game.options.locale && this.game.options.locale == 'ar' ? 'انتهت اللعبة' : 'GAME OVER';
    this.stringWellDone = this.game.options.locale && this.game.options.locale == 'ar' ? 'أحسنت' : 'WELL DONE';
    const stringHintDetail = this.game.options.locale && this.game.options.locale == 'ar' ? 'انقر لتغيير الاتجاه' : 'Tap to change direction';

    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;

    this.moveDir = 0;
    this.ballCollided = false;
    this.ballEaten = false;
    this.score = 0;
    this.scoreToWin = this.game.options.scoreToWin || 7;
    this.gameEnded = false;

    this.audioEat = this.sound.add('audio_eat').setMute(this.game.options.mute)
      .setVolume(0.5);
    this.audioCrash = this.sound.add('audio_crash').setMute(this.game.options.mute);
      // .setVolume(0.7);

    this.audioGameEnd = this.sound.add('audio_game_end').setMute(this.game.options.mute);
    this.audioGameEnd.setVolume(0.2);
    this.audioGameWin = this.sound.add('audio_game_win').setMute(this.game.options.mute);
    this.audioGameWin.setVolume(0.5);
    GPREventBus.on('mute', this.onEventMute);
    GPREventBus.on('restart', this.onEventRestart);

    const bgImage = this.add.image(gameWidth / 2, gameHeight / 2, 'background');
    bgImage.setDisplaySize(gameWidth, gameHeight);

    this.textScore = this.add.text(gameWidth / 2, 120, this.score, {
      // fontFamily: 'Poppins',
      fontSize: 128, color: '#fff',
      stroke: '#48464F', strokeThickness: 4,
      align: 'center',
      backgroundColor: '#334B4ABB',
      padding: {
        left: 20,
        right: 20,
        top: 2,
        bottom: 0
      },
      cornerRadius: 4,
    }).setOrigin(0.5)
      .setDepth(1)
      .setShadow(0, 0, 0xffffff, 4);

    const ballWidth = 400 * .7;
    const ballHeight = 320 * .7;
    const ballImageSize = ballHeight * .8;

    this.trackCenterX = gameWidth/2;
    this.trackCenterY = gameWidth/2 + ((gameHeight-gameWidth) * .75);
    this.trackRadius = gameWidth/2 - 40 - 20 - ballHeight/2 + 4;

    const outerBorder = 10;
    const innerBorder = 8;
    this.add.circle(this.trackCenterX, this.trackCenterY, gameWidth/2 - 40, 0x375654);
    const adImageSize = 2 * (gameWidth/2 - 40 - 2*outerBorder - ballHeight + 10 - 12);
    this.adImage1 = this.add.image(this.trackCenterX, this.trackCenterY, 'adImage1')
      .setDisplaySize(adImageSize, adImageSize);
    this.add.circle(this.trackCenterX, this.trackCenterY, gameWidth/2 - 40 - 2*outerBorder - ballHeight/2).setAlpha(0.8).setStrokeStyle(ballHeight, 0x16E7E0);
    this.add.circle(this.trackCenterX, this.trackCenterY, gameWidth/2 - 40 - 2*outerBorder - ballHeight + 10 - 6).setStrokeStyle(2*innerBorder, 0x375654);

    this.ball = this.physics.add.image(this.trackCenterX - this.trackRadius, this.trackCenterY, 'ball')
      .setDisplaySize(ballImageSize, ballImageSize);

    this.pacman = this.add.container(this.trackCenterX, this.trackCenterY + this.trackRadius);
    this.pacmanSprite = this.physics.add.sprite(0, 0, 'pacmanS', 'pac1.png')
      .setDisplaySize(ballWidth, ballHeight);
    this.pacman.add(this.pacmanSprite);
    this.pacmanBody = this.physics.add.image((ballWidth-ballHeight)/2, 0, 'striker')
      .setDisplaySize(ballImageSize, ballImageSize)
      .setBodySize(ballImageSize *.7, ballImageSize *.7);
    this.pacman.add(this.pacmanBody);
    this.frameNames = this.anims.generateFrameNames('pacmanS', {
      prefix: 'pac',
      suffix: '.png',
      start: 1,
      end: 16,
    });
    this.anims.create({
      key: 'animPacmanMove',
      frames: this.frameNames,
      frameRate: 24,
      repeat: -1
    });

    this.sawsData = {
      saws: [],
      sawColliders: [],
      activeSawIndexes: new Set(),
    }

    this.textHintDetail = this.add.text(gameWidth / 2, gameHeight - 100, stringHintDetail, {
      fontFamily: 'Poppins',
      fontSize: 60, color: '#ffffff',
      stroke: '#48464F', strokeThickness: 2,
      align: 'center'
    }).setOrigin(0.5)
      .setAlpha(0.9);
    addTimerEvent(this, 2000, () => {
      this.textHintDetail.setVisible(false);
    });

    this.gameOverText = this.add.text(gameWidth / 2, 320, this.stringGameOver, {
      // fontFamily: 'Poppins',
      fontSize: 120, color: '#666',
      backgroundColor: '#eee',
      stroke: '#000', strokeThickness: 4,
      align: 'center', padding: 24,
    }).setOrigin(0.5).setVisible(false).setDepth(1);

    this.ballOverlap = this.physics.add.overlap(this.pacmanSprite, this.ball, this.onBallOverlap);
    this.readyToPlayGame();
  }

  update() {
    const speed = SPEED_PAC + SPEED_PAC_INCREASE * this.score;
    let angleChange = 0;
    let deltaAngle = 0;
    if (this.moveDir === 1) {
      angleChange = speed;
      deltaAngle = -80;
    } else if (this.moveDir === -1) {
      angleChange = -speed;
      deltaAngle = 80;
    }
    if (angleChange) {
      let angle = this.startAngle + angleChange/100;
      this.startAngle = angle;

      const x = this.trackCenterX + this.trackRadius * Math.cos(angle);
      const y = this.trackCenterY + this.trackRadius * Math.sin(angle);
      this.pacman.setPosition(x, y);

      let directionX = this.trackCenterX - x;
      let directionY = this.trackCenterY - y;
      let moonRotationAngle = Math.atan2(directionY, directionX) + deltaAngle;
      this.pacman.setRotation(moonRotationAngle);
    }
  }

  attachPointers = () => {
    this.input.on('pointerdown', this.onClick);
  }

  detachPointers = () => {
    this.input.off('pointerdown', this.onClick);
  }

  onClick = () => {
    if (!this.moveDir) {
      this.sawMoveTimer = addTimerEvent(this, 100, this.processSaw);
    }

    if (!this.moveDir || this.moveDir === -1) {
      this.startAngle = this.getRevolutionAngle(this.pacman.x, this.pacman.y, this.trackCenterX, this.trackCenterY, this.trackRadius);
      this.moveDir = 1;
      this.pacmanSprite.anims.play('animPacmanMove');
    } else {
      this.startAngle = this.getRevolutionAngle(this.pacman.x, this.pacman.y, this.trackCenterX, this.trackCenterY, this.trackRadius);
      this.moveDir = -1;
      this.pacmanSprite.anims.play('animPacmanMove');
    }
  }

  processSaw = () => {
    // reset out indexes
    if (this.sawsData.activeSawIndexes.size > 0) {
      let indexesToReset = null;
      this.sawsData.activeSawIndexes.forEach((sawIndex) => {
        const saw = this.sawsData.saws[sawIndex];
        if (saw.y > this.game.config.height + saw.displayHeight) {
          if (!indexesToReset) {
            indexesToReset = [];
          }
          indexesToReset.push(sawIndex);
          saw.setPosition(this.game.config.width/2, -saw.displayHeight);
          saw.setVisible(false);
        }
      });
      if (indexesToReset && indexesToReset.length > 0) {
        indexesToReset.forEach((indexToReset) => {
          this.sawsData.activeSawIndexes.delete(indexToReset);
        });
      }
    }

    let sawIndex = -1;
    for (let i = 0; i < this.sawsData.saws.length; i++) {
      if (!this.sawsData.activeSawIndexes.has(i)) {
        sawIndex = i;
        break;
      }
    }
    if (sawIndex === -1) {
      const sawSize = this.game.config.width/4;
      const saw = this.physics.add.image(0, -sawSize, 'saw');
      saw.setDisplaySize(sawSize, sawSize);
      saw.setOffset(saw.body.width * .05, saw.body.height * .65);
      saw.setBodySize(saw.body.width * .9, saw.body.height * .3, false);
      this.sawsData.sawColliders.push(this.physics.add.collider(this.pacmanBody, saw, this.onBallCollide));

      this.sawsData.saws.push(saw);
      sawIndex = this.sawsData.saws.length - 1;
    }
    if (sawIndex >= 0) {
      this.sawsData.activeSawIndexes.add(sawIndex);
      const saw = this.sawsData.saws[sawIndex];
      saw.setPosition(Phaser.Math.Between(saw.displayWidth/4, this.game.config.width - saw.displayWidth/4), -saw.displayHeight);
      saw.setVelocityY(SPEED_SAW + SPEED_SAW_INCREASE * this.score);
      saw.setVisible(true);
    }
    this.sawMoveTimer = addTimerEvent(this, SAW_DELAY - SAW_DELAY_REDUCE * this.score, this.processSaw);
    if (this.lastProcess) {
      logDebug('processSaw', new Date() - this.lastProcess);
    }
    this.lastProcess = new Date();
  }

  getRevolutionAngle(pointX, pointY, circleCenterX, circleCenterY, circleRadius) {
    // Calculate the difference between point and circle center coordinates
    let dx = pointX - circleCenterX;
    let dy = pointY - circleCenterY;

    // Calculate the angle using Math.atan2 (adjust for zero radius case)
    let angle = Math.atan2(dy, dx) + (circleRadius === 0 ? Math.PI / 2 : 0);

    // Adjust angle to be between 0 and 2PI (optional)
    angle = (angle + Math.PI * 2) % (Math.PI * 2);

    return angle;
  }

  onBallCollide = (ball, otherObject) => {
    const isOverlapped = checkOverlap(ball, otherObject);
    if (isOverlapped && !this.ballCollided) {
      this.ballCollided = true;
      this.pacman.setAlpha(0.5);
      this.audioCrash.play();
      this.endGame();
    }
  }

  onBallOverlap = (ball, otherObject) => {
    this.pacmanSprite.anims.pause();
    const isOverlapped = checkOverlap(ball, otherObject);
    if (isOverlapped && !this.ballEaten) {
      if (this.score === 0) {
        GPREventBus.emit('eventOnStart');
      }
      this.ballEaten = true;
      this.score++;
      this.textScore.setText(this.score);

      this.ballOverlap.active = false;

      addTimerEvent(this, 200, () => {
        this.ball.setVisible(false);
        this.updateBallPosition();
        this.audioEat.play();

        if (this.score >= this.scoreToWin) {
          this.endGame();
        } else {
          addTimerEvent(this, 200, () => {
            logDebug('ballOverlapEnd');
            this.pacmanSprite.anims.resume();
            this.ballEaten = false;
            this.ballOverlap.active = true;
            this.ball.setVisible(true);
          });
        }
      });
    }
  }

  updateBallPosition = () => {
    let rotationSpeed = Phaser.Math.Between(-3, 3);
    if (rotationSpeed === 0) {
      rotationSpeed = -1;
    }
    const currentAngle = this.getRevolutionAngle(this.ball.x, this.ball.y, this.trackCenterX, this.trackCenterY, this.trackRadius);
    const newAngle = currentAngle + rotationSpeed;
    const x = this.trackCenterX + this.trackRadius * Math.cos(newAngle);
    const y = this.trackCenterY + this.trackRadius * Math.sin(newAngle);
    this.ball.setPosition(x, y);
  };

  readyToPlayGame = () => {
    this.moveDir = 0;
    if (!this.gameEnded) {
      this.attachPointers();
    }
    this.pacman.setVisible(true);
    this.sawsData.sawColliders.forEach((sawCollider) => {
      sawCollider.active = true;
    });
    this.onClick();
  }

  endGame = () =>  {
    const isWin = this.score >= this.scoreToWin;
    this.gameEnded = true;

    this.detachPointers();
    this.moveDir = 0;
    this.pacmanSprite.setVelocity(0, 0);
    this.pacmanBody.setVelocity(0, 0);
    this.pacmanSprite.anims.stop();
    if (this.sawMoveTimer) {
      this.time.removeEvent(this.sawMoveTimer);
      this.sawMoveTimer = null;
    }
    this.sawsData.saws.forEach((saw) => {
      saw.setVelocityY(0);
    });
    this.sawsData.sawColliders.forEach((sawCollider) => {
      sawCollider.active = false;
    });

    if (isWin) {
      this.audioGameWin.play();
      this.gameOverText.setText(this.stringWellDone);
    } else {
      // this.audioGameEnd.play();
      this.gameOverText.setText(this.stringGameOver);
    }

    addTimerEvent(this, 1000, () => {
      this.gameOverText.setVisible(true);
      addTimerEvent(this, 1000, () => {
        GPREventBus.emit('eventOnEnd', this.score);
      });
    });
  }
}
