export const getHexToRgb = (hex) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

export const getColorWithAlpha = (color, alpha = 1) => {
  if (color.startsWith('#')) {
    color = getHexToRgb(color);
    return 'rgba(' + color.r + ',' + color.g + ',' + color.b + ',' + alpha + ')';
  } else if (color.startsWith('rgba')) {
    const splits = color.replace('(rgb', '').replace(')', '').split(',');
    return 'rgba('+ splits[0] +','+ splits[1] +','+ splits[2] +','+ alpha +')';
  }
  return color.replace(')', ','+ alpha +')').replace('rgb', 'rgba');
}

export const addTimerEvent = (scene, delay, callback) => {
  return scene.time.addEvent({
    delay: delay,
    callback: callback
  })
}

export const addRepeatTimerEvent = (scene, delay, callback, startAt = delay, repeat = -1) => {
  return scene.time.addEvent({
    delay: delay,
    repeat: repeat,
    callback: callback,
    startAt: startAt
  })
}

export const checkOverlap = (object1, object2) => {
  return Phaser.Geom.Intersects.RectangleToRectangle(object1.getBounds(), object2.getBounds());
}

export const getOverlap = (object1, object2) => {
  return Phaser.Geom.Intersects.GetRectangleToRectangle(object1.getBounds(), object2.getBounds());
}

export const getCenterPoint = (point1, point2) => {
  // Calculate the center coordinates
  const centerX = (point1.x + point2.x) / 2;
  const centerY = (point1.y + point2.y) / 2;

  // Return a new point object representing the center
  return new Phaser.Geom.Point(centerX, centerY);
}

export const checkOverlapInside = (object1, object2, delta = 0.8) => {
  const effDelta = 1 - delta;
  const object1X = object1.x - object1.displayWidth/2;
  const object1Y = object1.y - object1.displayHeight/2;
  const object2X = object2.x - object2.displayWidth/2;
  const object2Y = object2.y - object2.displayHeight/2;
  return object2X >= object1X
    && object2Y >= object1Y
    && object2X + object2.displayWidth <= object1X + object1.displayWidth
    && object2Y + object2.displayHeight <= object1Y + object1.displayHeight;
}

export const shakeObject = (scene, object, duration = 100, magnitude = 10, axis = 0) => {
  let originalX = object.x;
  let originalY = object.y;

  scene.time.addEvent({
    delay: 10,
    repeat: duration / 10, // Adjust based on desired shake frequency
    callback: () => {
      let randomOffsetX = Phaser.Math.RND.between(-magnitude, magnitude);
      let randomOffsetY = (axis === 0 || axis === 2) ? Phaser.Math.RND.between(-magnitude, magnitude) : 0;
      object.setPosition(originalX + randomOffsetX, originalY + randomOffsetY);
    },
    callbackScope: this // Optional, set context for 'this'
  });

  scene.time.addEvent({
    delay: duration + 20,
    callback: () => {
      object.setPosition(originalX, originalY); // Reset position after shake
    }
  });
}

export const formatSeconds = (seconds) => {
  // Ensure seconds is a number and non-negative
  seconds = Math.max(0, Math.floor(seconds)); // Floor to handle decimals and ensure non-negative

  // Calculate minutes and remaining seconds
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  // Format minutes and seconds with leading zeros
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

  // Return the formatted time string
  return `${formattedMinutes}:${formattedSeconds}`;
}
