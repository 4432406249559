import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {addLocaleData, IntlProvider} from 'react-intl';
import enLocaleData from 'react-intl/locale-data/en';
import arLocaleData from 'react-intl/locale-data/ar';
import messages_en from '../constants/translations/en/en';
import messages_ar from '../constants/translations/ar/ar';

import App from './App';

import {jssPreset, responsiveFontSizes, StylesProvider, ThemeProvider} from '@material-ui/core/styles';
import {create} from 'jss';
import rtl from 'jss-rtl'
import {
  ColorAccent,
  ColorAccentHover,
  ColorBackground,
  ColorBackgroundXS,
  ColorClear,
  ColorField,
  ColorFieldBorder,
  ColorFieldBorderHover,
  ColorFieldHover,
  ColorNegative, ColorOrange,
  ColorSecondary,
  ColorSubTitle,
  ColorTheme,
  ColorTitle,
  ColorTitleDisabled,
  ColorWhite
} from "src/styles/Colors";
import CssBaseline from "@material-ui/core/CssBaseline";
import {BORDER_RADIUS} from "src/styles/Styles";
import AppUtils from "../utils/AppUtils";
import {createTheme} from "@material-ui/core";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

addLocaleData([...enLocaleData, ...arLocaleData]);

const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
});

const messages = {
  'en': messages_en,
  'ar': messages_ar
};

class Root extends Component {
  render() {
    const {app} = this.props;
    const language = app.defaultLocale;

    return (
      <IntlProvider locale={language} messages={messages[language]}>
        <ThemeProvider theme={this.getTheme(app.isLTR ? 'ltr' : 'rtl')}>
          <StylesProvider jss={jss}>
            <CssBaseline />
            <App />
          </StylesProvider>
        </ThemeProvider>
      </IntlProvider>
    );
  }

  getTheme(direction) {
    const breakpoints = createBreakpoints({})
    let theme = createTheme({
      direction: direction,
      spacing: 8,
      palette: {
        primary: {
          main: ColorTheme,
        },
        secondary: {
          main: ColorSecondary,
        },
        error: {
          main: ColorNegative,
        },
        warning: {
          main: ColorOrange,
        },
        info: {
          main: ColorSecondary,
        },
        background: {
          default: ColorBackground,
          [breakpoints.down('xs')]: {
            default: ColorBackgroundXS,
          },
        },
        text: {
          primary: ColorTitle,
          secondary: ColorSubTitle,
          disabled: ColorTitleDisabled,
        },
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 1080,
          lg: 1280,
          xl: 1920,
          listC1: 700,
          listC2: 900,
          drawer: 1080,
        },
      },
      '&': {
        fontFamily: direction === 'rtl' ? 'Noto Kufi Arabic, sans-serif' : 'Poppins, sans-serif',
      },
      typography: {
        fontFamily: direction === 'rtl' ? 'Noto Kufi Arabic, sans-serif' : 'Poppins, sans-serif',
      },
      input: {
        fontFamily: direction === 'rtl' ? 'Noto Kufi Arabic, sans-serif' : 'Poppins, sans-serif',
      },
      overrides: {
        // MuiAppBar: {
        //   root: {
        //     height: TOOLBAR_HEIGHT,
        //     // [breakpoints.down('sm')]: {
        //     //   height: TOOLBAR_HEIGHT_SM,
        //     // },
        //     [breakpoints.down('xs')]: {
        //       height: TOOLBAR_HEIGHT_XS,
        //     },
        //   }
        // },
        MuiButton: {
          root: {
            '&': {
              borderRadius: BORDER_RADIUS,
              textTransform: 'none',
            }
          }
        },
        MuiFab: {
          root: {
            '&': {
              backgroundColor: ColorAccent,
              color: '#FFF'
            },
            '&:hover': {
              backgroundColor: ColorAccentHover,
            },
            '&.Mui-focused': {
              backgroundColor: ColorAccentHover,
            }
          }
        },
        MuiFilledInput: {
          root: {
            '&': {
              borderRadius: BORDER_RADIUS,
              border: '1px solid '+ ColorFieldBorder,
              backgroundColor: ColorField,
            },
            '&.MuiFilledInput-adornedEnd': {
              paddingRight: 0,
            },
            '&:hover': {
              backgroundColor: ColorFieldHover,
              border: '1px solid '+ ColorFieldBorderHover,
            },
            '&.Mui-focused': {
              backgroundColor: ColorFieldHover,
              border: '2px solid '+ ColorTheme,
            },
            '&.Mui-disabled': {
              backgroundColor: ColorClear,
              border: '1px solid '+ AppUtils.colorWithAlpha(ColorFieldBorder, 0.3),
            },
            '&.Mui-error': {
              border: '1px solid red',
            }
          }
        },
        MuiPaper: {
          rounded: {
            '&': {
              borderRadius: BORDER_RADIUS
            }
          }
        },
        MuiMobileStepper: {
          root: {
            maxWidth: "100%",
            flexGrow: 1
          },
          progress: {
            width: "100%"
          }
        },
        MuiDropzonePreviewList: {
          removeButton: {
            transform: 'translate(-6px, 4px) scale(0.8)',
            elevation: 0,
            opacity: 0.8,
            '&': {
              backgroundColor: 'rgba(0,0,0,0.4)',
              color: ColorWhite,
            },
            '&:hover': {
              backgroundColor: ColorNegative,
              color: ColorWhite,
              opacity: 1,
            },
            '&.Mui-focused': {
              backgroundColor: ColorNegative,
              color: ColorWhite,
              opacity: 1,
            }
          },
        },
        MuiTypography: {
          h6: {
            lineHeight: 1.35
          },
          subtitle1: {
            lineHeight: 1.4
          },
          body1: {
            lineHeight: 1.4
          },
          subtitle2: {
            lineHeight: 1.45
          },
          body2: {
            lineHeight: 1.45
          },
          caption: {
            lineHeight: 1.5
          }
        }
      }
    });
    theme = responsiveFontSizes(theme);
    return theme;
  }
}

Root.propTypes = {
  app: PropTypes.object
};

function mapStateToProps(state) {
  return {
    app: state.app
  };
}

const mapDispatchToProps = () => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
