import {isString, isNumber} from 'lodash';
import constants from '../constants';

const validations = constants.validations.earnPointFormula;

export default function earnPointFormulaValidator(field, value, options) {
  switch(field) {
    case 'name':
      return validateFormulaName(value, options);
    case 'priority':
      return validatePriority(value, options);
    case 'startAt':
      return validateStartAt(value, options);
    case 'endAt':
      return validateEndAt(value, options);
    case 'spendsPerPoint':
      return validateSpendsPerPoint(value, options);
  }
}

export function validateFormulaName(value) {
  const prefix = 'admin.earn_point_formula.validation.name';

  if (validations.name.isRequired && (!isString(value) || !value.trim())) {
    return prefix + '.required';
  }

  if(value.length > validations.name.maxChar) {
    return prefix + '.max_char';
  }
}

export function validatePriority(value) {
  const prefix = 'admin.earn_point_formula.validation.priority';

  if (validations.priority.isRequired && !isNumber(value)) {
    return prefix + '.required';
  }

  if (value < validations.priority.minNum) {
    return prefix + '.min_num';
  }

  if (value > validations.priority.maxNum) {
    return prefix + '.max_num';
  }
}

export function validateStartAt(value) {
  const prefix = 'admin.earn_point_formula.validation.start_at';

  if (validations.startAt.isRequired && !(value instanceof Date) && (!isString(value) || !value.trim())) {
    return prefix + '.required';
  }
}

export function validateEndAt(value) {
  const prefix = 'admin.earn_point_formula.validation.end_at';

  if (validations.endAt.isRequired && !(value instanceof Date) && (!isString(value) || !value.trim())) {
    return prefix + '.required';
  }
}

export function validateSpendsPerPoint(value) {
  const prefix = 'admin.earn_point_formula.validation.spends_per_point';

  if (validations.spendsPerPoint.isRequired && !isNumber(value)) {
    return prefix + '.required';
  }

  if (value < validations.spendsPerPoint.minNum) {
    return prefix + '.min_num';
  }
}
