import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import '../styles/styles.scss';
import * as serviceWorker from './utils/serviceWorker';
import configureStore, {history} from './store/configureStore';
import Root from './components/Root';

require('../images/favicon.png');

const store= configureStore();

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Root/>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('app')
);

if (module.hot) {
  module.hot.accept('./components/Root', () => {
    const {AppContainer} = require('react-hot-loader')
    const NewRoot = require('./components/Root').default;
    window.HELP_IMPROVE_VIDEOJS = false;

    render(
      <Provider store={store}>
        <AppContainer>
          <ConnectedRouter history={history}>
            <NewRoot store={store} history={history}/>
          </ConnectedRouter>
        </AppContainer>
      </Provider>,
      document.getElementById('app')
    );
  });
}

serviceWorker.register();
