'use strict';
import React from 'react';

import CircularProgress from "@material-ui/core/CircularProgress";
import {withStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = () => ({
  container: {
    display: 'flex',
    flex: 1,
    // backgroundColor: ColorWhite,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: Number.MAX_SAFE_INTEGER,
    alignItems: 'center',
    justifyContent: 'center',
  }
});

class ViewLoading extends React.Component {

  render() {
    const {classes} = this.props;
    return <div className={classes.container}>
      <CircularProgress />
    </div>
  }
}

ViewLoading.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(useStyles)(ViewLoading);
