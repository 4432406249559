import React from 'react';
import initialState from 'src/js/reducers/initialState';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHeart as faHeartRegular} from '@fortawesome/free-regular-svg-icons/faHeart';
import {faThumbsUp as faThumbsUpRegular} from '@fortawesome/free-regular-svg-icons/faThumbsUp';
import {faThumbsDown as faThumbsDownRegular} from '@fortawesome/free-regular-svg-icons/faThumbsDown';
import {
  faAd,
  faAlignJustify, faArrowDown, faArrowDownLong,
  faArrowLeft, faArrowLeftLong,
  faArrowRight, faArrowRightFromBracket,
  faArrowsLeftRight, faArrowTrendUp, faArrowUp,
  faArrowUpLong,
  faAt,
  faAward,
  faBan,
  faBars,
  faBell,
  faBolt,
  faBookmark,
  faBox,
  faBoxOpen,
  faBriefcase,
  faBriefcaseMedical,
  faBuilding,
  faBusinessTime,
  faCalendarAlt,
  faCamera,
  faChartBar,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle, faCirclePlay, faCirclePlus, faCircleXmark,
  faCity,
  faCodeBranch,
  faCog,
  faCoins,
  faComment,
  faComments,
  faCompress,
  faCrosshairs,
  faCrown,
  faCube,
  faDatabase, faDice,
  faDirections,
  faDonate, faDownload,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExpand,
  faEye,
  faEyeSlash, faFilm,
  faFilter, faFrown,
  faGift,
  faGifts,
  faGlobe,
  faHeart,
  faHome,
  faI,
  faImage,
  faInfo,
  faInfoCircle,
  faLandmark, faLayerGroup,
  faLink,
  faList,
  faListAlt,
  faListUl,
  faLocationCrosshairs, faLock,
  faLongArrowAltDown,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faLongArrowAltUp, faLongArrowRight,
  faMap,
  faMapMarkedAlt,
  faMapMarkerAlt, faMedal,
  faMicrophone,
  faMinus,
  faPause,
  faPen,
  faPercentage,
  faPhone,
  faPhoneAlt,
  faPhoneSlash,
  faPlane, faPlaneDeparture,
  faPlay,
  faPlus,
  faPoll,
  faQuestion, faQuestionCircle,
  faQuestionCircle as faQuestionCircleS,
  faRedoAlt,
  faReply, faRotate, faScrewdriverWrench,
  faSearch,
  faShare, faShareAlt,
  faShippingFast,
  faShoppingBag,
  faShoppingCart,
  faSitemap,
  faSlash,
  faSlidersH, faSmile,
  faSortAlphaDown,
  faSortAlphaDownAlt,
  faSortAmountDown,
  faSortAmountDownAlt,
  faStar,
  faStop,
  faStore,
  faTag,
  faTags,
  faTasks,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrophy,
  faTruck,
  faUpload,
  faUser,
  faUserMd,
  faUsers,
  faUtensils,
  faVideo,
  faVolumeMute,
  faVolumeUp, faVolumeXmark,
  faWallet,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCopy,
  faClock,
  faCreditCard,
  faMessage,
  faPaperPlane,
  faQuestionCircle as faQuestionCircleRegular,
  faStar as faStarRegular,
} from '@fortawesome/free-regular-svg-icons';
import {
  faFacebookF,
  faInstagram,
  faSnapchat,
  faTiktok,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {ColorTheme} from "src/styles/Colors";

export const IconAd = 100;
export const IconAdd = 101;
export const IconAddress = 102;
export const IconAlphaAsc = 103;
export const IconAlphaDesc = 104;
export const IconAmountAsc = 105;
export const IconAmountDesc = 106;
export const IconArrowLeft = 107;
export const IconArrowRight = 108;
export const IconAt = 109;
export const IconArrowLeftRight = 110;
export const IconArrowUpLong = 111;
export const IconAddCircle = 112;
export const IconArrowRightLong = 113;
export const IconArrowLeftLong = 114;
export const IconArrowUp = 115;
export const IconArrowDown = 116;
export const IconArrowDownLong = 117;

export const IconBranch = 200;
export const IconBusiness = 201;
export const IconBusinessCategory = 202;
export const IconBusinessInvitation = 203;
export const IconBusinessRequest = 204;
export const IconBan = 205;
export const IconBox = 206;
export const IconBars = 207;

export const IconCalender = 301;
export const IconCall = 302;
export const IconCamera = 303;
export const IconCart = 304;
export const IconCheck = 305;
export const IconChevronDown = 306;
export const IconChevronRight = 307;
export const IconChevronUp = 308;
export const IconCircle = 309;
export const IconCity = 310;
export const IconClear = 311;
export const IconClock = 312;
export const IconCoins = 313;
export const IconComment = 314;
export const IconConfirmation = 315;
export const IconCross = 316;
export const IconCurrentLocation = 317;
export const IconCard = 318;
export const IconChart = 319;
export const IconChevronLeft = 320;
export const IconCopy = 321;
export const IconCommunity = 322;
export const IconChallenge = 323;
export const IconCheckCircle = 324;
export const IconCollapse = 325;
export const IconCrossCircle = 326;
export const IconCrown = 327;

export const IconDelete = 400;
export const IconDelivery = 401;
export const IconDetails = 402;
export const IconDirection = 403;
export const IconDiscount = 404;
export const IconDislike = 405;
export const IconDislikeOutline = 406;
export const IconDistrict = 407;
export const IconDonate = 408;
export const IconDriver = 409;
export const IconDineIn = 410;
export const IconDotsH = 411;
export const IconDotsV = 412;
export const IconDownload = 413;

export const IconEarn = 500;
export const IconEdit = 501;
export const IconEmail = 502;
export const IconError = 503;
export const IconErrorCircle = 504;
export const IconExpand = 505;

export const IconFavouriteOff = 600;
export const IconFavouriteOn = 601;
export const IconFeed = 602;
export const IconFilter = 603;
export const IconForward = 604;
export const IconFlight = 605;
export const IconFlash = 607;
export const IconFacebook = 608;
export const IconFlip = 609;
export const IconFlightDeparture = 610;

export const IconGift = 700;
export const IconGifts = 701;
export const IconGlobe = 702;

export const IconHeart = 800;
export const IconHide = 801;
export const IconHome = 802;
export const IconHappy = 803;
export const IconHelp = 804;
export const IconHeartOutline = 805;

export const IconInfo = 900;
export const IconInfoCircle = 901;
export const IconIReview = 902;
export const IconInstagram = 903;
export const IconInsight = 904;

export const IconLandmark = 1200;
export const IconLike = 1201;
export const IconLikeOutline = 1202;
export const IconList = 1203;
export const IconLocation = 1204;
export const IconLocationCurrent = 1205;
export const IconLongArrowLeft = 1206;
export const IconLongArrowRight = 1207;
export const IconLink = 1208;
export const IconLayerGroup = 1209;
export const IconLogout = 1210;

export const IconMap = 1300;
export const IconMapMarked = 1301;
export const IconMessage = 1302;
export const IconMinus = 1303;
export const IconMore = 1304;
export const IconMoreH = 1305;
export const IconMoreGame = 1306;

export const IconNavBack = 1400;
export const IconNotification = 1401;

export const IconOffer  = 1500;
export const IconOrder  = 1501;

export const IconPause = 1600;
export const IconPercent = 1601;
export const IconPhone = 1602;
export const IconPlay = 1603;
export const IconPoints = 1604;
export const IconPoll = 1605;
export const IconPostTitle = 1606;
export const IconProduct = 1607;
export const IconPhoneSlash = 1608;
export const IconPromote = 1609;
export const IconPackage = 1610;
export const IconPost = 1611;
export const IconPhoto = 1612;
export const IconPaperPlane = 1613;
export const IconPassword = 1614;

export const IconQuestion = 1700;
export const IconQuestionCircle = 1701;

export const IconRating = 1800;
export const IconRecord = 1801;
export const IconRedo = 1802;
export const IconRefund = 1803;
export const IconRelation = 1804;
export const IconReply = 1805;
export const IconReport = 1806;
export const IconRequestList = 1807;
export const IconResumePost = 1808;
export const IconRequirement = 1809;
export const IconRefresh = 1810;
export const IconReward = 1811;
export const IconMyReward = 1812;

export const IconSearch = 1900;
export const IconSetting = 1901;
export const IconShare = 1902;
export const IconShow = 1903;
export const IconSpent = 1904;
export const IconStop = 1905;
export const IconStore = 1906;
export const IconStorePickup = 1907;
export const IconSlash = 1908;
export const IconSnapchat = 1909;
export const IconStar = 1910;
export const IconSuccess = 1911;
export const IconStarRegular = 1912;

export const IconTnc = 2000;
export const IconTransaction = 2001;
export const IconTiktok = 2002;
export const IconTwitter = 2003;
export const IconTrophy = 2004;
export const IconTrend = 2005;
export const IconTabPlay = 2006;
export const IconTabBuild = 2007;

export const IconUser = 2100;
export const IconUnhappy = 2101;
export const IconUpload = 2102;
export const IconUnmute = 2103;
export const IconUp = 2104;

export const IconVolumeOff = 2200;
export const IconVolumeUp = 2201;
export const IconVideo = 2202;
export const IconView = 2203;

export const IconWallet = 2300;
export const IconWork = 2301;
export const IconWebsite = 2302;
export const IconWarning = 2303;
export const IconWinner = 2304;

export const IconYoutube = 2500;


export const AppIcon = ({style, icon, color, size, transform}) => {
  if (!color) {
    color = ColorTheme;
  }
  if (!size) {
    size = 'sm';
  }
  if (!style) {
    style = {};
  }
  style.color = color;
  icon = getIcon(icon);
  return (
    <FontAwesomeIcon icon={icon} style={style} size={size} transform={transform}/>
  );
};

export default AppIcon;

function getIcon(iconName) {
  let icon = null;
  switch (iconName) {
    case IconAd: icon = faAd;
      break;
    case IconAdd: icon = faPlus;
      break;
    case IconAddress: icon = faAt;
      break;
    case IconAlphaAsc: icon = faSortAlphaDown;
      break;
    case IconAlphaDesc: icon = faSortAlphaDownAlt;
      break;
    case IconAmountAsc: icon = faSortAmountDownAlt;
      break;
    case IconAmountDesc: icon = faSortAmountDown;
      break;
    case IconArrowLeft: icon = faArrowLeft;
      break;
    case IconArrowLeftLong: icon = faArrowLeftLong;
      break;
    case IconArrowRight: icon = faArrowRight;
      break;
    case IconArrowRightLong: icon = faLongArrowRight;
      break;
    case IconAt: icon = faAt;
      break;
    case IconArrowLeftRight: icon = faArrowsLeftRight;
      break;
    case IconArrowUpLong: icon = faArrowUpLong;
      break;
    case IconArrowUp: icon = faArrowUp;
      break;
    case IconArrowDown: icon = faArrowDown;
      break;
    case IconArrowDownLong: icon = faArrowDownLong;
      break;
    case IconAddCircle: icon = faCirclePlus;
      break;

    case IconBranch: icon = faCodeBranch;
      break;
    case IconBusiness: icon = faBriefcase;
      break;
    case IconBusinessCategory: icon = faSitemap;
      break;
    case IconBusinessInvitation: icon = faBusinessTime;
      break;
    case IconBusinessRequest: icon = faBriefcaseMedical;
      break;
    case IconBan: icon = faBan;
      break;
    case IconBox: icon = faBox;
      break;
    case IconBars: icon = faBars;
      break;

    case IconCalender: icon = faCalendarAlt;
      break;
    case IconCall: icon = faPhone;
      break;
    case IconCamera: icon = faCamera;
      break;
    case IconCart: icon = faShoppingCart;
      break;
    case IconCheck: icon = faCheck;
      break;
    case IconCheckCircle: icon = faCheckCircle;
      break;
    case IconChevronDown: icon = faChevronDown;
      break;
    case IconChevronRight: icon = faChevronRight;
      break;
    case IconChevronLeft: icon = faChevronLeft;
      break;
    case IconCopy: icon = faCopy;
      break;
    case IconCommunity: icon = faUsers;
      break;
    case IconChallenge: icon = faFilm;
      break;
    case IconChevronUp: icon = faChevronUp;
      break;
    case IconCircle: icon = faCircle;
      break;
    case IconCity: icon = faCity;
      break;
    case IconClear: icon = faTimesCircle;
      break;
    case IconClock: icon = faClock;
      break;
    case IconCoins: icon = faCoins;
      break;
    case IconComment: icon = faMessage;
      break;
    case IconConfirmation: icon = faQuestionCircleRegular;
      break;
    case IconCross: icon = faTimes;
      break;
    case IconCurrentLocation: icon = faCrosshairs;
      break;
    case IconCard: icon = faCreditCard;
      break;
    case IconChart: icon = faChartBar;
      break;
    case IconCollapse: icon = faCompress;
      break;
    case IconCrossCircle: icon = faCircleXmark;
      break;
    case IconCrown: icon = faCrown;
      break;

    case IconDelete: icon = faTrash;
      break;
    case IconDelivery: icon = faShippingFast;
      break;
    case IconDetails: icon = faListUl;
      break;
    case IconDirection: icon = faDirections;
      break;
    case IconDiscount: icon = faTag;
      break;
    case IconDislike: icon = faThumbsDown;
      break;
    case IconDislikeOutline: icon = faThumbsDownRegular;
      break;
    case IconDistrict: icon = faBuilding;
      break;
    case IconDonate: icon = faDonate;
      break;
    case IconDriver: icon = faTruck;
      break;
    case IconDineIn: icon = faUtensils;
      break;
    case IconDotsV: icon = faEllipsisV;
      break;
    case IconDotsH: icon = faEllipsisH;
      break;
    case IconDownload: icon = faDownload;
      break;

    case IconEarn: icon = faLongArrowAltDown;
      break;
    case IconEdit: icon = faPen;
      break;
    case IconEmail: icon = faEnvelope;
      break;
    case IconError: icon = faExclamation;
      break;
    case IconErrorCircle: icon = faExclamationCircle;
      break;
    case IconExpand: icon = faExpand;
      break;

    case IconFavouriteOn: icon = faHeart;
      break;
    case IconFavouriteOff: icon = faHeartRegular;
      break;
    case IconFeed: icon = faComments;
      break;
    case IconFilter: icon = faFilter;
      break;
    case IconForward: icon = faShare;
      break;
    case IconFlight: icon = faPlane;
      break;
    case IconFlightDeparture: icon = faPlaneDeparture;
      break;
    case IconFlash: icon = faBolt;
      break;
    case IconFacebook: icon = faFacebookF;
      break;
    case IconFlip: icon = faCopy;
      break;

    case IconGift: icon = faGift;
      break;
    case IconGifts: icon = faGifts;
      break;
    case IconGlobe: icon = faGlobe;
      break;

    case IconHeart: icon = faHeart;
      break;
    case IconHeartOutline: icon = faHeartRegular;
      break;
    case IconHide: icon = faEyeSlash;
      break;
    case IconHome: icon = faHome;
      break;
    case IconHappy: icon = faSmile;
      break;
    case IconHelp: icon = faQuestionCircleS;
      break;

    case IconInfo: icon = faInfo;
      break;
    case IconInfoCircle: icon = faInfoCircle;
      break;
    case IconIReview: icon = faI;
      break;
    case IconInstagram: icon = faInstagram;
      break;
    case IconInsight: icon = faPoll;
      break;

    case IconLandmark: icon = faLandmark;
      break;
    case IconLike: icon = faThumbsUp;
      break;
    case IconLikeOutline: icon = faThumbsUpRegular;
      break;
    case IconList: icon = faList;
      break;
    case IconLocation: icon = faMapMarkerAlt;
      break;
    case IconLocationCurrent: icon = faLocationCrosshairs;
      break;
    case IconLayerGroup: icon = faLayerGroup;
      break;
    case IconLongArrowLeft: icon = faLongArrowAltLeft;
      break;
    case IconLongArrowRight: icon = faLongArrowAltRight;
      break;
    case IconLink: icon = faLink;
      break;
    case IconLogout: icon = faArrowRightFromBracket;
      break;

    case IconMap: icon = faMap;
      break;
    case IconMapMarked: icon = faMapMarkedAlt;
      break;
    case IconMessage: icon = faComments;
      break;
    case IconMinus: icon = faMinus;
      break;
    case IconMore: icon = faEllipsisV;
      break;
    case IconMoreH: icon = faEllipsisH;
      break;
    case IconMoreGame: icon = faList;
      break;

    case IconNavBack: icon = initialState.app.isLTR ? faArrowLeft : faArrowRight;
      break;
    case IconNotification: icon = faBell;
      break;

    case IconOffer: icon = faTags;
      break;
    case IconOrder: icon = faShoppingBag;
      break;

    case IconPause: icon = faPause;
      break;
    case IconPercent: icon = faPercentage;
      break;
    case IconPhone: icon = faPhone;
      break;
    case IconPhoneSlash: icon = faPhoneSlash;
      break;
    case IconPromote: icon = faBookmark;
      break;
    case IconPackage: icon = faBoxOpen;
      break;
    case IconPlay: icon = faPlay;
      break;
    case IconPoints: icon = faDatabase;
      break;
    case IconPoll: icon = faPoll;
      break;
    case IconPostTitle: icon = faAlignJustify;
      break;
    case IconProduct: icon = faCube;
      break;
    case IconPost: icon = faEdit;
      break;
    case IconPhoto: icon = faImage;
      break;
    case IconPaperPlane: icon = faPaperPlane;
      break;
    case IconPassword: icon = faLock;
      break;

    case IconQuestion: icon = faQuestion;
      break;
    case IconQuestionCircle: icon = faQuestionCircle;
      break;

    case IconRating: icon = faStar;
      break;
    case IconRecord: icon = faMicrophone;
      break;
    case IconRedo: icon = faRedoAlt;
      break;
    case IconRefund: icon = faRedoAlt;
      break;
    case IconRelation: icon = faHeart;
      break;
    case IconReply: icon = faReply;
      break;
    case IconReport: icon = faExclamationTriangle;
      break;
    case IconRequestList: icon = faListAlt;
      break;
    case IconResumePost: icon = faUserMd;
      break;
    case IconRequirement: icon = faTasks;
      break;
    case IconRefresh: icon = faRotate;
      break;
    case IconReward: icon = faMedal;
      break;
    case IconMyReward: icon = faTags;
      break;

    case IconSearch: icon = faSearch;
      break;
    case IconSetting: icon = faCog;
      break;
    case IconShare: icon = faShareAlt;
      break;
    case IconShow: icon = faEye;
      break;
    case IconSpent: icon = faLongArrowAltUp;
      break;
    case IconStop: icon = faStop;
      break;
    case IconStore: icon = faStore;
      break;
    case IconStorePickup: icon = faStore;
      break;
    case IconSlash: icon = faSlash;
      break;
    case IconSnapchat: icon = faSnapchat;
      break;
    case IconStar: icon = faStar;
      break;
    case IconSuccess: icon = faCheck;
      break;
    case IconStarRegular: icon = faStarRegular;
      break;

    case IconTnc: icon = faSlidersH;
      break;
    case IconTransaction: icon = faBars;
      break;
    case IconTiktok: icon = faTiktok;
      break;
    case IconTwitter: icon = faTwitter;
      break;
    case IconTrophy: icon = faTrophy;
      break;
    case IconTrend: icon = faArrowTrendUp;
      break;
    case IconTabPlay: icon = faDice;
      break;
    case IconTabBuild: icon = faScrewdriverWrench;
      break;

    case IconUser: icon = faUser;
      break;
    case IconUnhappy: icon = faFrown;
      break;
    case IconUpload: icon = faUpload;
      break;
    case IconUnmute: icon = faVolumeXmark;
      break;

    case IconVolumeOff: icon = faVolumeMute;
      break;
    case IconVolumeUp: icon = faVolumeUp;
      break;
    case IconVideo: icon = faVideo;
      break;
    case IconView: icon = faEye;
      break;

    case IconWallet: icon = faWallet;
      break;
    case IconWork: icon = faBriefcase;
      break;
    case IconWebsite: icon = faGlobe;
      break;
    case IconWarning: icon = faExclamation;
      break;

    case IconYoutube: icon = faYoutube;
      break;

    default: icon = null;
  }
  return icon;
}
