export default class CampaignHelper {

  static getName(campaign) {
    if (campaign) {
      return "#"+ campaign.id +" "+ campaign.title;
    }
    return null;
  }

}
