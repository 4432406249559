import React from "react";

const View = ({...props}) => {
  let style = props.style;
  if (!props.className) {
    if (!style) {
      style = {};
    }
    style.display = 'flex';
    if (!style.flexDirection) {
      style.flexDirection = 'column';
    }
  }

  return (
    <div {...props} style={style}>{props.children}</div>
  );
};

export default View;
