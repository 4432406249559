import * as types from '../constants/actionTypes';
import { wrapCall } from '../utils/serviceUtils';
import { api } from '../utils/services';
import auth from '../utils/auth';

export const showSupportChatModal = (id) => {
  return {
    type: types.SHOW_SUPPORT_CHAT_MODAL,
    id
  };
}

export const hideSupportChatModal = () => {
  return {
    type: types.HIDE_SUPPORT_CHAT_MODAL
  };
}

export const fetchSupportMessages = (search, page, perPage, user, isLoadingDisabled) => {
  return wrapCall(
    api.get(`/business/messages?page=${page}&perPage=${perPage || 20}${search ? '&search=' + search : ''}&anotherIdType=4&anotherId=1`),
    types.FETCH_SUPPORT_MESSAGES,
    {user, isLoadingDisabled}
  );
};

export const createSupportMessage = (data) => {
  let formData = new FormData();
  for (let key in data) {
    formData.append(key, data[key]);
  }
  formData.append('createdForId', 1);
  formData.append('createdForType', 4);
  formData.append('token', auth.getToken());

  return wrapCall(
    api.post(`/business/messages`, formData, {'Content-Type': 'multipart/form-data'}),
    types.CREATE_SUPPORT_MESSAGE
  );
};

export const deleteSupportMessage = (id) => {
  return wrapCall(
    api.delete(`/business/messages/${id}`),
    types.DELETE_SUPPORT_MESSAGE,
    {id}
  );
};
