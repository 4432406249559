export const SpaceXXSmall = 4;
export const SpaceXSmall = 8;
export const SpaceSmall = 12;
export const SpaceNormal = 16;
export const SpaceLarge = 20;
export const SpaceXLarge = 24;
export const SpaceXXLarge = 28;
export const SpaceXXXLarge = 32;

export const HeightElementMinimum = 48;
export const HeightElementMinimumAdd = 48;
