import {Scene} from 'phaser';
import {GREventBus} from "src/gameRoshambo/GameRoshambo";
import {addTimerEvent, shakeObject} from "src/game/GameHelper";

export class GRGame extends Scene {

  constructor() {
    super('GRGame');
  }

  onEventRestart = () => {
    GREventBus.off('mute', this.onEventMute);
    GREventBus.off('restart', this.onEventRestart);
    GREventBus.off('simulate', this.onEventSimulate);
    this.scene.stop('GRGame');
    this.scene.start('GRGame');
  };

  onEventMute = (mute) => {
    if (this.audioGameBg) {
      this.audioGameBg.setMute(mute);
    }
    if (this.audioHit) {
      this.audioHit.setMute(mute);
    }
    if (this.audioMiss) {
      this.audioMiss.setMute(mute);
    }
    if (this.audioDraw) {
      this.audioDraw.setMute(mute);
    }
    if (this.audioGameEnd) {
      this.audioGameEnd.setMute(mute);
    }
    if (this.audioGameWin) {
      this.audioGameWin.setMute(mute);
    }
  };

  onEventSimulate = () => {
    this.autoSimulation = true;
    addTimerEvent(this, 1000, () => {
      this.autoPlay();
    });
  }

  autoPlay = () => {
    if (!this.gameEnded) {
      this.onClick(this.getRandomSymbolId());
    }
  }

  getRoundText = () => {
    if (this.game.options.locale && this.game.options.locale == 'ar') {
      return "الجولة    "+ this.round +"   من   "+ this.tryCount;
    }
    return "Round   "+ this.round +"  of  "+ this.tryCount;
  }

  getScoreText = () => {
    if (this.game.options.locale && this.game.options.locale == 'ar') {
      return `الفوز:  ${this.score}    |    خسارة:  ${this.scoreN}`;
    }
    return `Wins:  ${this.score}    |    Lose:  ${this.scoreN}`;
  }

  create() {
    this.stringGameOver = this.game.options.locale && this.game.options.locale == 'ar' ? 'انتهت اللعبة' : 'GAME OVER';
    this.stringWellDone = this.game.options.locale && this.game.options.locale == 'ar' ? 'أحسنت' : 'WELL DONE';
    this.stringRoundWon = this.game.options.locale && this.game.options.locale == 'ar' ? 'لقد فزت في الجولة' : 'You Won Round';
    this.stringRoundLost = this.game.options.locale && this.game.options.locale == 'ar' ? 'لقد خسرت الجولة' : 'You Lost Round';
    this.stringRoundDraw = this.game.options.locale && this.game.options.locale == 'ar' ? 'الجولة تعادل' : 'Round Draw';
    const stringHint = this.game.options.locale && this.game.options.locale == 'ar' ? 'اختر واحدة' : 'Choose One';
    const stringRock = this.game.options.locale && this.game.options.locale == 'ar' ? 'حجر' : 'Rock';
    const stringPaper = this.game.options.locale && this.game.options.locale == 'ar' ? 'ورقة' : 'Paper';
    const stringScissor = this.game.options.locale && this.game.options.locale == 'ar' ? 'مقص' : 'Scissor';

    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;

    this.gameEnded = false;
    this.score = 0;
    this.scoreN = 0;
    this.round = 0;
    this.scoreToWin = this.game.options.scoreToWin || 1;
    this.tryCount = this.game.options.tryCount || 5;
    this.isGameStartInvoked = false;

    this.canClick = true;
    this.playerMoveId = null;

    if (this.audioGameBg) {
      this.audioGameBg.stop();
      this.audioGameBg = null;
      GREventBus.off('mute', this.onEventMute);
    }
    this.audioGameBg = this.sound.add('audio_bg', {loop: true})
      .setMute(this.game.options.mute);
    this.audioGameBg.play();
    this.audioHit = this.sound.add('audio_hit')
      .setMute(this.game.options.mute)
      .setVolume(0.5);
    this.audioMiss = this.sound.add('audio_miss')
      .setMute(this.game.options.mute);
    this.audioDraw = this.sound.add('audio_draw')
      .setMute(this.game.options.mute);
      // .setVolume(0.7);
    this.audioGameEnd = this.sound.add('audio_game_end')
      .setMute(this.game.options.mute)
      .setVolume(0.2);
    this.audioGameWin = this.sound.add('audio_game_win')
      .setMute(this.game.options.mute)
      .setVolume(0.7);

    GREventBus.on('mute', this.onEventMute);
    GREventBus.on('restart', this.onEventRestart);
    GREventBus.on('simulate', this.onEventSimulate);

    const bgImage = this.add.image(gameWidth / 2, gameHeight / 2, 'background');
    bgImage.setDisplaySize(gameWidth, gameHeight);
    const dynamicColor = Phaser.Display.Color.HexStringToColor(this.game.options.themeColor).color; // Convert hex string to Phaser color
    const dynamicAlpha = 0.2;
    const graphics = this.add.graphics();
    graphics.fillStyle(dynamicColor, dynamicAlpha);
    graphics.fillRect(0, 0, gameWidth, gameHeight);

    this.textRound = this.add.text(gameWidth / 2, 70, this.getRoundText(), {
      fontFamily: 'Poppins',
      fontSize: 60,
      fontStyle: 'bold',
      color: '#333333',
      stroke: '#ffffff',
      strokeThickness: 2,
      align: 'center',
    }).setOrigin(0.5)
      .setDepth (1)
      .setShadow(0, 0, 0xffffff, 4);

    const lineSeparator = this.add.graphics();
    lineSeparator.fillStyle(0x333333, 1)
      .fillRoundedRect(gameWidth/2 - 225, 115, 450, 4, 2);

    this.textScore = this.add.text(gameWidth / 2, 164, this.getScoreText(), {
      fontFamily: 'Poppins',
      fontSize: 50,
      fontStyle: 'bold',
      color: '#333333',
      stroke: '#ffffff',
      strokeThickness: 2,
      align: 'center',
    }).setOrigin(0.5)
      .setDepth(1)
      .setShadow(0, 0, 0xffffff, 4);

    this.textHint = this.add.text(gameWidth / 2, 300, stringHint, {
      fontFamily: 'Poppins',
      fontSize: 70,
      fontStyle: '500',
      color: '#666666',
      stroke: '#ffffff',
      strokeThickness: 4,
      align: 'center',
    }).setOrigin(0.5);

    const objectFirstX = 440;
    const objectSpace = 150;
    const objectHeight = Math.min(500, (gameHeight - objectFirstX - 3*objectSpace - 20)/3);
    const objectWidth = objectHeight*2 + 50;
    const objectX = gameWidth/2-objectWidth/2;
    this.rock = this.addObject('R', stringRock, 'rock', 'adImage1', objectX, objectFirstX, objectWidth, objectHeight);
    this.paper = this.addObject('P', stringPaper, 'paper', 'adImage2', objectX, objectFirstX+objectHeight+objectSpace, objectWidth, objectHeight);
    this.scissor = this.addObject('S', stringScissor, 'scissor', 'adImage3', objectX, objectFirstX+2*(objectHeight+objectSpace), objectWidth, objectHeight);

    const objectRFirstY = 450;
    const objectRSpace = 150;
    const objectRWidth = Math.min(600, (gameWidth - objectRSpace - 32)/2);
    const objectRHeight = objectRWidth*2;
    this.playY1 = 280 + objectRHeight/2;
    this.playY2 = Math.max(this.playY1 + 100, gameHeight - objectRHeight - 200 + objectRHeight/2);
    this.playYEnd = this.playY1 + (this.playY2 - this.playY1)*.5;
    this.velocity = (this.playY2 - this.playY1) * 3;
    this.roundOverTextInitialY = this.playYEnd;

    // this.playerPlay = this.addPlayerObject('R', 'rockR', 'adImage1', 16, objectRFirstY, objectRWidth, objectRHeight);
    // this.botPlay = this.addPlayerObject('R', 'rockR', 'adImage1', gameWidth-objectRWidth-16, objectRFirstY, objectRWidth, objectRHeight, true);

    this.playerRock = this.addPlayerObject('R', 'rockR', 'adImage1', 16, objectRFirstY, objectRWidth, objectRHeight);
    this.playerPaper = this.addPlayerObject('P', 'paperR', 'adImage2', 16, objectRFirstY, objectRWidth, objectRHeight);
    this.playerScissor = this.addPlayerObject('S', 'scissorR', 'adImage3', 16, objectRFirstY, objectRWidth, objectRHeight);
    this.botRock = this.addPlayerObject('R', 'rockR', 'adImage1', gameWidth-objectRWidth-16, objectRFirstY, objectRWidth, objectRHeight, true);
    this.botPaper = this.addPlayerObject('P', 'paperR', 'adImage2', gameWidth-objectRWidth-16, objectRFirstY, objectRWidth, objectRHeight, true);
    this.botScissor = this.addPlayerObject('S', 'scissorR', 'adImage3', gameWidth-objectRWidth-16, objectRFirstY, objectRWidth, objectRHeight, true);

    this.roundOverText = this.add.text(gameWidth / 2, this.roundOverTextInitialY, this.stringRoundWon, {
      fontFamily: 'Poppins',
      fontSize: 70,
      fontStyle: '500',
      color: '#333333',
      backgroundColor: 'rgba(255,255,255,0.3)',
      stroke: '#ffffff',
      strokeThickness: 2,
      align: 'center',
      padding: 12,
    }).setOrigin(0.5);
    this.physics.add.existing(this.roundOverText);

    this.gameOverText = this.add.text(gameWidth / 2, 320, this.stringGameOver, {
      fontFamily: 'Poppins',
      fontSize: 120,
      fontStyle: '500',
      color: '#666',
      backgroundColor: 'rgba(255,255,255,0.5)',
      stroke: '#000',
      strokeThickness: 4,
      align: 'center',
      padding: 24,
    }).setOrigin(0.5).setVisible(false).setDepth(1);
    this.physics.add.existing(this.gameOverText);

    this.readyToPlay();
  }

  update() {
    if (this.roundOverText.visible && this.roundOverText.y <= this.playY1-this.roundOverText.height*4) {
      this.roundOverText.body.setVelocityY(0);
    }
    if (this.gameOverText.visible && this.gameOverText.y >= 320) {
      this.gameOverText.body.setVelocityY(0);
    }

    if (this.inPlaying) {
      if (this.playBoundsHit === 8) {
        this.processGameStop();
      }

      else if (this.playBoundsHit === 7) {
        if (this.playerPlay && (this.playerPlay.y >= this.playYEnd - 60 || this.botPlay.y >= this.playYEnd - 60)) {
          if (!this.inStopping) {
            this.processGameStopping();
          }
        } else if (this.inStopping) {
          if (this.playerEnd.y >= this.playYEnd || this.botEnd.y >= this.playYEnd) {
            if (this.lastBoundsHit === 1) {
              this.playBoundsHit++;
              this.lastBoundsHit = 2;
            }
          }
          if (this.playerEnd.y < this.playYEnd - 10) {
            this.playerEnd.angle = -(this.playYEnd - this.playerEnd.y) / (this.playY2 - this.playY1) * 10;
            this.botEnd.angle = (this.playYEnd - this.playerEnd.y) / (this.playY2 - this.playY1) * 10;
          } else if (this.playerEnd.y > this.playYEnd + 10) {
            this.playerEnd.angle = (this.playerEnd.y - this.playYEnd) / (this.playY2 - this.playY1) * 10;
            this.botEnd.angle = -(this.playerEnd.y - this.playYEnd) / (this.playY2 - this.playY1) * 10;
          } else if (this.playerEnd.y >= this.playYEnd - 10 || this.playerEnd.y <= this.playYEnd + 10) {
            this.playerEnd.angle = 0;
            this.botEnd.angle = 0;
          }
        }
      }

      else if (this.playBoundsHit < 7) {
        if (this.playerPlay) {
          if (this.playerPlay.y <= this.playY1 || this.botPlay.y <= this.playY1) {
            this.playerPlay.body.setVelocityY(this.velocity);
            this.botPlay.body.setVelocityY(this.velocity);
            if (this.lastBoundsHit === 2) {
              this.playBoundsHit++;
              this.lastBoundsHit = 1;
            }
          } else if (this.playerPlay.y >= this.playY2 || this.botPlay.y >= this.playY2) {
            this.playerPlay.body.setVelocityY(-this.velocity - 100);
            this.botPlay.body.setVelocityY(-this.velocity - 100);
            if (this.lastBoundsHit === 1) {
              this.playBoundsHit++;
              this.lastBoundsHit = 2;
            }
          }
          if (this.playerPlay.y < this.playYEnd - 10) {
            this.playerPlay.angle = -(this.playYEnd - this.playerPlay.y) / (this.playY2 - this.playY1) * 10;
            this.botPlay.angle = (this.playYEnd - this.playerPlay.y) / (this.playY2 - this.playY1) * 10;
          } else if (this.playerPlay.y > this.playYEnd + 10) {
            this.playerPlay.angle = (this.playerPlay.y - this.playYEnd) / (this.playY2 - this.playY1) * 10;
            this.botPlay.angle = -(this.playerPlay.y - this.playYEnd) / (this.playY2 - this.playY1) * 10;
          } else if (this.playerPlay.y >= this.playYEnd - 10 || this.playerPlay.y <= this.playYEnd + 10) {
            this.playerPlay.angle = 0;
            this.botPlay.angle = 0;
          }
        }
      }
    }
  }

  attachPointers = () => {
    this.input.on('pointerdown', this.onPointerDown);
  }

  detachPointers = () => {
    this.input.off('pointerdown', this.onPointerDown);
  }

  readyToPlay = () => {
    this.round++;
    this.textRound.setText(this.getRoundText());
    this.canClick = true;
    this.inPlaying = false;
    this.inStopping = false;
    this.textHint.setVisible(true);
    this.rock.setVisible(true);
    this.paper.setVisible(true);
    this.scissor.setVisible(true);

    this.gameOverText.y = -120;
    this.gameOverText.body.y = -120;
    this.gameOverText.setVisible(false);
    this.roundOverText.y = this.roundOverTextInitialY;
    this.roundOverText.body.y = this.roundOverTextInitialY;
    this.roundOverText.setVisible(false);

    this.lastBoundsHit = 2;
    this.playBoundsHit = 0;
    this.playerRock.setVisible(false);
    this.playerPaper.setVisible(false);
    this.playerScissor.setVisible(false);

    // this.botPlay.y = this.playY1;
    // this.botPlay.body.y = this.playY1;
    // this.botPlay.setVisible(false);
    this.botRock.setVisible(false);
    this.botPaper.setVisible(false);
    this.botScissor.setVisible(false);

    this.attachPointers();
    if (this.autoSimulation) {
      this.onEventSimulate();
    }
  }

  onPointerDown = (pointer) => {
    const pointX = pointer.x;
    const pointY = pointer.y;
    let id = null;
    if (Phaser.Geom.Rectangle.Contains(new Phaser.Geom.Rectangle(this.rock.x - this.rock.width/2, this.rock.y - this.rock.height/2, this.rock.width, this.rock.height), pointX, pointY)) {
      id = 'R';
    } else if (Phaser.Geom.Rectangle.Contains(new Phaser.Geom.Rectangle(this.paper.x - this.paper.width/2, this.paper.y - this.paper.height/2, this.paper.width, this.paper.height), pointX, pointY)) {
      id = 'P';
    } else if (Phaser.Geom.Rectangle.Contains(new Phaser.Geom.Rectangle(this.scissor.x - this.scissor.width/2, this.scissor.y - this.scissor.height/2, this.scissor.width, this.scissor.height), pointX, pointY)) {
      id = 'S';
    }
    if (id) {
      this.onClick(id);
    }
  }

  onClick = (playerMoveId) => {
    this.detachPointers();
    if (this.canClick) {
      this.canClick = false;
      this.playerMoveId = playerMoveId;

      const selection = this.getSelectionSymbol(this.playerMoveId);
      shakeObject(this, selection, 500);
      addTimerEvent(this, 500, () => {
        if (this.playerMoveId === 'R') {
          this.playerPlay = this.playerRock;
          this.botPlay = this.botRock;
        } else if (this.playerMoveId === 'P') {
          this.playerPlay = this.playerPaper;
          this.botPlay = this.botPaper;
        } else {
          this.playerPlay = this.playerScissor;
          this.botPlay = this.botScissor;
        }
        this.rock.setVisible(false);
        this.paper.setVisible(false);
        this.scissor.setVisible(false);
        this.textHint.setVisible(false);

        this.playerPlay.y = this.playY1;
        this.playerPlay.body.y = this.playY1;
        this.playerPlay.setVisible(true);
        this.botPlay.y = this.playY1;
        this.botPlay.body.y = this.playY1;
        this.botPlay.setVisible(true);

        addTimerEvent(this, 300, () => {
          if (!this.isGameStartInvoked) {
            GREventBus.emit('eventOnStart');
            this.isGameStartInvoked = true;
          }
          this.inPlaying = true;
          this.playerPlay.setVisible(true);
          this.botPlay.setVisible(true);
          this.playerPlay.body.setVelocityY(1000);
          this.botPlay.body.setVelocityY(1000);
        });
      });
    }
  }

  processGameStopping = () => {
    this.inStopping = true;
    this.playerPlay.setVisible(false);
    this.playerPlay.body.setVelocityY(0);
    this.botPlay.setVisible(false);
    this.botPlay.body.setVelocityY(0);

    this.playerEnd = this.getPlayerSymbol(this.playerMoveId);
    this.playerEnd.angle = this.playerPlay.angle;
    this.playerEnd.y = this.playerPlay.y;
    this.playerEnd.body.setVelocityY(this.velocity);
    this.playerEnd.setVisible(true);

    this.botMoveId = this.getRandomSymbolId();
    if (this.round === this.tryCount && this.score === this.scoreN) {
      if (this.botMoveId !== 'R') {
        this.botMoveId = 'R';
      } else {
        this.botMoveId = 'S';
      }
    }
    this.botEnd = this.getBotSymbol(this.botMoveId);
    this.botEnd.angle = this.botPlay.angle;
    this.botEnd.y = this.botPlay.y;
    this.botEnd.body.setVelocityY(this.velocity);
    this.botEnd.setVisible(true);

    this.playerPlay = null;
    this.botPlay = null;
  }

  processGameStop = () => {
    this.inPlaying = false;

    const playerMoveId = this.playerMoveId;
    const botMoveId = this.botMoveId;

    this.playerEnd.body.setVelocityY(0);
    this.playerEnd.y = this.playYEnd;
    this.botEnd.body.setVelocityY(0);
    this.botEnd.y = this.playYEnd;

    let roundText = null;
    if (botMoveId === playerMoveId) {
      roundText = this.stringRoundDraw;
      this.audioDraw.play();
    } else if (botMoveId === 'R' && playerMoveId === 'P' || botMoveId === 'P' && playerMoveId === 'S' || botMoveId === 'S' && playerMoveId === 'R') {
      roundText = this.stringRoundWon;
      this.score++;
      this.audioHit.play();
    } else if (botMoveId === 'P' && playerMoveId === 'R' || botMoveId === 'S' && playerMoveId === 'P' || botMoveId === 'R' && playerMoveId === 'S') {
      roundText = this.stringRoundLost;
      this.scoreN++;
      this.audioMiss.play();
    }
    this.textScore.setText(this.getScoreText());
    this.roundOverText.setText(roundText);
    this.roundOverText.setVisible(true);
    this.roundOverText.body.setVelocityY(-600);

    addTimerEvent(this, 3000, () => {
      if (this.round === this.tryCount) {
        this.endGame();
      } else {
        this.readyToPlay();
      }
    });
  }

  getRandomSymbolId = () => {
    const possibleIds = ['R', 'P', 'S'];
    const randomIndex = Math.round(Math.random() * 100) % 3;
    return possibleIds[randomIndex];
  }

  getBotSymbol = (id) => {
    if (id === 'R') {
      return this.botRock;
    }
    if (id === 'P') {
      return this.botPaper;
    }
    if (id === 'S') {
      return this.botScissor;
    }
  }

  getPlayerSymbol = (id) => {
    if (id === 'R') {
      return this.playerRock;
    }
    if (id === 'P') {
      return this.playerPaper;
    }
    if (id === 'S') {
      return this.playerScissor;
    }
  }

  getSelectionSymbol = (id) => {
    if (id === 'R') {
      return this.rock;
    }
    if (id === 'P') {
      return this.paper;
    }
    if (id === 'S') {
      return this.scissor;
    }
  }

  addObject = (id, title, symbol, imageId, x, y, width, height) => {
    const container = this.add.container(x+width/2, y+height/2);

    const background = this.add.graphics();
    background.fillStyle(0x000000, 0.15);
    background.fillRoundedRect(-width/2, -height/2, width, height, 10);
    container.add(background);

    const image1Size = height-20-60;
    const image1 = this.add.image(-width/2+10+image1Size/2, -height/2+10+image1Size/2, symbol).setDisplaySize(image1Size, image1Size);
    container.add(image1);

    const text = this.add.text(-width/2+10+image1Size/2, -height/2+10+image1Size+10, title, {
      fontFamily: 'Poppins',
      fontSize: 40,
      fontStyle: 'bold',
      color: '#ffffff',
      stroke: '#000000',
      strokeThickness: 2,
      align: 'center'
    }).setOrigin(0.5, 0);
    container.add(text);

    const text2 = this.add.text(-30, -40, '~', {
      fontFamily: 'Poppins',
      fontSize: 80,
      color: '#ffffff',
      stroke: '#000000',
      strokeThickness: 2,
      align: 'center'
    }).setOrigin(0.5, 0);
    container.add(text2);

    const image2Size = height-20;
    const image2 = this.add.image(-width/2+width-10-image2Size/2, -height/2+10+image2Size/2, imageId).setDisplaySize(image2Size, image2Size);
    container.add(image2);

    container.setSize(width, height);
    return container;
  }

  addPlayerObject = (id, symbol, imageId, x, y, width, height, flip = false) => {
    const container = this.add.container(x+width/2, y+height/2);

    // Add background rectangle with black color and opacity
    const background = this.add.graphics();
    background.fillStyle(0x000000, 0.0); // Black with 20% opacity
    background.fillRoundedRect(-width/2, -height/2, width, height, 10); // Rounded rectangle
    container.add(background);

    // Add an interactive zone for the background
    const backgroundZone = this.add.zone(-width/2, -height/2, width, height)
      .setOrigin(0)
      .setInteractive();
    container.add(backgroundZone);

    // Add first image (100x100)
    const image1Size = width-20;
    const image1 = this.add.image(-width/2+10+image1Size/2, -height/2+10+image1Size/2, symbol).setDisplaySize(image1Size, image1Size);
    // image1.rotation = Math.PI * 2;
    if (flip) {
      image1.flipX = true;
    }
    container.add(image1);

    // const text2 = this.add.text(-width/2+width/2, -height/2+height/2-68, '~', {
    //   fontFamily: 'Poppins',
    //   fontSize: 80,
    //   color: '#ffffff',
    //   stroke: '#000000',
    //   strokeThickness: 2,
    //   align: 'center'
    // }).setOrigin(0.5, 0);
    // container.add(text2);

    // Add second image to the right of the first image and text
    const image2Size = width-20;
    const image2 = this.add.image(-width/2+10+image2Size/2, -height/2+height-10-image2Size/2, imageId).setDisplaySize(image2Size, image2Size);
    container.add(image2);

    container.setSize(width, height);
    this.physics.world.enable(container);
    return container;
  }

  endGame = () =>  {
    const isWin = this.score > this.scoreN;
    this.gameEnded = true;

    this.roundOverText.setVisible(false);
    this.gameOverText.setVisible(true);
    this.gameOverText.body.setVelocityY(1000);
    if (isWin) {
      this.audioGameWin.play();
      this.gameOverText.setText(this.stringWellDone);
    } else {
      this.audioGameEnd.play();
      this.gameOverText.setText(this.stringGameOver);
    }

    addTimerEvent(this, 1000, () => {
      GREventBus.emit('eventOnEnd', isWin ? 1 : 0);
    });
  }
}
