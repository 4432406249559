import {Scene} from 'phaser';

export class GRBoot extends Scene {

  constructor() {
    super('GRBoot');
  }

  preload() {
    this.load.image('background', 'assets/gameRoshambo/bg.png');
  }

  create() {
    this.scene.start('GRPreloader');
  }
}
