import {initializeApp} from 'firebase/app';
import {getMessaging, getToken, onMessage, isSupported} from "firebase/messaging";
import constants from '../constants';
import browserStorage from '../utils/browserStorage';

export const NOTIFICATION_TOKEN_KEY = 'rab_v1_token_notification';

const firebaseConfig = {
  apiKey: "AIzaSyBkft21HZCY7XhSjeFcUM0nqo1sFQmdQvM",
  authDomain: "rab7-414819.firebaseapp.com",
  projectId: "rab7-414819",
  storageBucket: "rab7-414819.appspot.com",
  messagingSenderId: "646042338606",
  appId: "1:646042338606:web:2eaaadadcdb4e2912626bf"
};

const firebaseApp = initializeApp(firebaseConfig);
let messaging = null;
let messageHandler = null;

export const isFcmSupported = () => {
  return isSupported()
    .then((supported) => {
      if (supported) {
        console.log('FCM:supported', supported);
        messaging = getMessaging(firebaseApp);
        onMessage(messaging, (payload) => {
          console.log('FCM:ForegroundMessageReceived:', payload);
          if (messageHandler) {
            messageHandler(payload);
          }
        });
      }
    }).catch((err) => {
      console.log('FCM:supportedError', err);
    });
};

export const askForPermissionToReceiveNotifications = () => {
  return Notification.requestPermission()
    .then((permission) => {
      console.log('FCM:requestPermission', permission);
      if (permission === 'granted') {
        return getToken(messaging, {vapidKey: constants.firebase.publicVapidKey});
      }
    }).catch((err) => {
      console.log('FCM:permissionError', err);
    });
};

export const registerMessageHandler = (messageHandlerL) => {
  messageHandler = messageHandlerL;
}

export const saveToken = (token) => {
  browserStorage.setItem(NOTIFICATION_TOKEN_KEY, token);
};

export const getSavedToken = () => {
  return browserStorage.getItem(NOTIFICATION_TOKEN_KEY);
};

export const deleteToken = () => {
  browserStorage.removeItem(NOTIFICATION_TOKEN_KEY);
};
