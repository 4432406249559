import {Scene} from 'phaser';

export class GPRBoot extends Scene {

  constructor() {
    super('GPRBoot');
  }

  preload() {
    this.load.image('background', 'assets/gamePacManRush/bg.png');
  }

  create() {
    this.scene.start('GPRPreloader');
  }
}
