import queryString from 'query-string';

import constants from '../constants';

export const defaultNavMapping = {
  [constants.access.types.COMMENTS_POST]: '/home/comments/posts',
  [constants.access.types.COMMENTS_ACTIVITY]: '/home/comments/activities',
  [constants.access.types.CHAT_EMPLOYEE]: '/home/chats/employees',
  [constants.access.types.CHAT_CUSTOMER]: '/home/chats/customers',
  [constants.access.types.TRANSACTIONS]: '/home/transactions',
  [constants.access.types.INSIGHTS]: '/home/insights/campaigns',
  [constants.access.types.MANAGEMENT]: '/home/management/branches',
  [constants.access.types.USER_BALANCE]: '/profile',
  [constants.access.types.PROFILE]: '/profile'
};

export const getFilterAsQueryString = (filters) => {
  return queryString.stringify(filters, {sort: false, arrayFormat: 'comma'});
}
