import React from "react";
import TextView from "./TextView";

const AppText = ({...props}) => {
  return (
    <TextView {...props}>{props.children}</TextView>
  );
};

export default AppText;
