import React from 'react';

export default class FocusHelperView extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.addEventListener('focus', this.onAppDidFocus);
    window.addEventListener('blur', this.onAppDidBlur);
  }

  componentWillUnmount() {
    window.removeEventListener('focus', this.onAppDidFocus);
    window.removeEventListener('blur', this.onAppDidBlur);
  }

  onAppDidFocus = () => {
    if (this.props.onAppDidFocus) {
      this.props.onAppDidFocus();
    }
  };

  onAppDidBlur = () => {
    if (this.props.onAppDidBlur) {
      this.props.onAppDidBlur();
    }
  };

  render() {
    return (<div/>);
  }
}
