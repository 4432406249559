import {isString, isNumber, isEmpty, some} from 'lodash';
import constants from '../constants';

const validations = constants.validations.activity;

export default function activityValidator(field, value, options) {
  const isQuestionType = options.type === constants.activity.types.QUESTION;
  const fieldsToIgnore = isQuestionType ? [
    'imageFile', 
  ] : [
    'qTitle'
  ];

  if (fieldsToIgnore.includes(field)) {
    return;
  }

  switch(field) {
    case 'type':
      return validateType(value);
    case 'title':
      return validateTitle(value);
    case 'startAt':
        return validateStartAt(value, options);
    case 'endAt':
      return validateEndAt(value, options);
    case 'imageFile':
      return validateImageFile(value, options);
    case 'qTitle':
      return validateQuestionTitle(value, options);
    case 'qOptions':
      return validateOptions(value, options);
    case 'cQuestions':
      return validateQuestions(value, options);
  }
}

export function validateType(value) {
  const prefix = 'admin.activity.validation.type';

  if (validations.type.isRequired && !isNumber(value)) {
    return prefix + '.required';
  }
}

export function validateTitle(value) {
  const prefix = 'admin.activity.validation.title';

  if (validations.title.isRequired && (!isString(value) || !value.trim())) {
    return prefix + '.required';
  }
}

export function validateStartAt(value) {
  const prefix = 'admin.activity.validation.start_at';

  if (validations.startAt.isRequired && !(value instanceof Date) && (!isString(value) || !value.trim())) {
    return prefix + '.required';
  }
}

export function validateEndAt(value) {
  const prefix = 'admin.activity.validation.end_at';

  if (validations.endAt.isRequired && !(value instanceof Date) && (!isString(value) || !value.trim())) {
    return prefix + '.required';
  }
}

export function validateImageFile(value, options) {
  const prefix = 'admin.activity.validation.image_file';

  if (validations.imageFile.isRequired && !options.imageUrl && !(value instanceof File)) {
    return prefix + '.required';
  }
}

export function validateOptions(value = [], options) {
  const prefix = 'admin.activity.validation.options';

  if (!value.length && options.qTitle) {
    return prefix + '.required';
  }

  const errors = value.map(({name, nameAr}) => {
    const errorObj = {};

    if (!name && validations.qOptions.name.required) {
      errorObj.name = prefix + '.name.required';
    }
    else if (name.length > validations.qOptions.name.maxChar) {
      errorObj.name = prefix + '.name.max_char';
    }

    if (nameAr.length > validations.qOptions.nameAr.maxChar) {
      errorObj.nameAr = prefix + '.name_ar.max_char';
    }

    return errorObj;
  });

  const hasErrors = some(errors, (obj) => !isEmpty(obj));

  return hasErrors ? errors : undefined;
}

export function validateQuestions(value) {
  const prefix = 'admin.activity.validation.cQuestions';

  if (!(value instanceof Array)) {
    return;
  }

  const errors = value.map(({ options }) => {
    if (!options.length) {
      return prefix + '.required';
    }

    return null;
  });

  const hasErrors = some(errors, (obj) => obj !== null);

  return hasErrors ? errors : undefined;
}

export function validateQuestionTitle(value) {
  const prefix = 'admin.activity.validation.qTitle';

  if (!value) {
    return prefix + '.required';
  }

  return null;
}
