import {Scene} from 'phaser';

export class Boot extends Scene {

  constructor() {
    super('Boot');
  }

  preload() {
    //  The GFKBoot Scene is typically used to load in any assets you require for your GFKPreloader, such as a gameTapTapShots logo or background.
    //  The smaller the file size of the assets, the better, as the GFKBoot Scene itself has no preloader.

    this.load.image('background', 'assets/gameTapTapShots/bg.png');

    // console.log('this.gameTapTapShots.config.configAssets', this.gameTapTapShots.configAssets);
    // this.load.image('bg1', this.gameTapTapShots.configAssets.bgUrl);
  }

  create() {
    this.scene.start('Preloader');
  }
}
