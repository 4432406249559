import constants from '../constants';

const validations = constants.validations.productCategory;

export default function productCategoryValidator(field, value, options) {
	switch (field) {
		case 'name':
			return validateName(value, options);
		case 'nameAr':
			return validateNameAr(value, options);
	}
}

export function validateName(value) {
	const prefix = 'admin.productCategory.validation.name';

	if (validations.name.isRequired && !value) {
		return prefix + '.required';
	}
	if (validations.name.maxChar && value.length > validations.name.maxChar) {
		return prefix + '.max_char';
	}
}

export function validateNameAr(value) {
	const prefix = 'admin.productCategory.validation.name_ar';

	if (validations.nameAr.isRequired && !value) {
		return prefix + '.required';
	}
	if (validations.nameAr.maxChar && value.length > validations.nameAr.maxChar) {
		return prefix + '.max_char';
	}
}
