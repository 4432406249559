import * as types from '../constants/actionTypes';

let loadingCounter = 0;

export default function blockedLoadingReducer(state, action) {

  switch (action.type) {
    case types.DO_REGISTRATION:
    case types.DO_PROFILE_UPDATE:
    case types.DO_ACCOUNT_UPDATE:
    case types.DO_INITIATE_VERIFY_ACCOUNT:
    // case types.ADD_REWARD_IN_ACTIVITY:
    // case types.MANAGE_ACTIVITY:
    case types.MAKE_ACTION_IN_ACTIVITY_BLOCKING:
    case types.ACTIVITY_REPORT:
    case types.ACTIVITY_POST_REPORT:
    case types.ACTIVITY_POST_DELETE:
    case types.DO_CHANGE_PASSWORD:
    case types.DECLARE_ACTIVITY_WINNERS:
    case types.FETCH_ACTIVITY_INSIGHT:
    case types.DO_LOGIN_PLAY_JOIN:
    case types.NOTIFY_ACTIVITY_WINNER:
    case types.MANAGE_SCREEN:
    case types.CONTROL_SCREEN_CAMPAIGN_REQUESTS:
    case types.DO_CHANGE_CONTACT_MOBILE_NUMBER:
    {
      if (!action.isLoadingDisabled) {
        loadingCounter += 1;
      }
    }
    break;

    case types.DO_REGISTRATION_SUCCESS:
    case types.DO_REGISTRATION_FAILURE:
    case types.DO_PROFILE_UPDATE_SUCCESS:
    case types.DO_PROFILE_UPDATE_FAILURE:
    case types.DO_ACCOUNT_UPDATE_SUCCESS:
    case types.DO_ACCOUNT_UPDATE_FAILURE:
    case types.DO_INITIATE_VERIFY_ACCOUNT_SUCCESS:
    case types.DO_INITIATE_VERIFY_ACCOUNT_FAILURE:
    // case types.ADD_REWARD_IN_ACTIVITY_SUCCESS:
    // case types.ADD_REWARD_IN_ACTIVITY_FAILURE:
    // case types.MANAGE_ACTIVITY_SUCCESS:
    // case types.MANAGE_ACTIVITY_FAILURE:
    case types.MAKE_ACTION_IN_ACTIVITY_BLOCKING_SUCCESS:
    case types.MAKE_ACTION_IN_ACTIVITY_BLOCKING_FAILURE:
    case types.ACTIVITY_REPORT_SUCCESS:
    case types.ACTIVITY_REPORT_FAILURE:
    case types.ACTIVITY_POST_REPORT_SUCCESS:
    case types.ACTIVITY_POST_REPORT_FAILURE:
    case types.ACTIVITY_POST_DELETE_SUCCESS:
    case types.ACTIVITY_POST_DELETE_FAILURE:
    case types.DO_CHANGE_PASSWORD_SUCCESS:
    case types.DO_CHANGE_PASSWORD_FAILURE:
    case types.DECLARE_ACTIVITY_WINNERS_SUCCESS:
    case types.DECLARE_ACTIVITY_WINNERS_FAILURE:
    case types.FETCH_ACTIVITY_INSIGHT_SUCCESS:
    case types.FETCH_ACTIVITY_INSIGHT_FAILURE:
    case types.DO_LOGIN_PLAY_JOIN_SUCCESS:
    case types.DO_LOGIN_PLAY_JOIN_FAILURE:
    case types.NOTIFY_ACTIVITY_WINNER_SUCCESS:
    case types.NOTIFY_ACTIVITY_WINNER_FAILURE:
    case types.MANAGE_SCREEN_SUCCESS:
    case types.MANAGE_SCREEN_FAILURE:
    case types.CONTROL_SCREEN_CAMPAIGN_REQUESTS_SUCCESS:
    case types.CONTROL_SCREEN_CAMPAIGN_REQUESTS_FAILURE:
    case types.DO_CHANGE_CONTACT_MOBILE_NUMBER_SUCCESS:
    case types.DO_CHANGE_CONTACT_MOBILE_NUMBER_FAILURE:
    {
      if (!action.isLoadingDisabled) {
        loadingCounter -= 1;
      }
    }
    break;

    case types.DO_LOGOUT:
      loadingCounter = 1;
      break;
    case '@@router/LOCATION_CHANGE':
    case types.RESET_LOADING:
      loadingCounter = 0;
  }

  return (loadingCounter > 0) ? true : false;

}
