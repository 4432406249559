'use strict';
import React from 'react';

import CircularProgress from "@material-ui/core/CircularProgress";
import {Card} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = () => ({
  container: {
    display: 'flex',
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: Number.MAX_SAFE_INTEGER,
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    width: 80,
    height: 80,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    borderRadius: 8,
    opacity: 0.95
  },
});

class Loader extends React.Component {

  render() {
    const {classes} = this.props;
    return <div className={classes.container}>
      <Card className={classes.card}>
        <CircularProgress />
      </Card>
    </div>
  }
}

Loader.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(useStyles())(Loader);
