import {cloneDeep, isEmpty} from 'lodash';

import {history} from '../store/configureStore';
import auth from '../utils/auth';
import {setAuthHeader} from '../utils/services';
import initialState from './initialState';
import * as types from '../constants/actionTypes';
import {formatProfileData} from './profileReducer';
import {DO_LOGIN_SCREEN} from "../constants/actionTypes";

export default function loginReducer(state = cloneDeep(initialState.login), action) {
  let newState = cloneDeep(state);

  switch (action.type) {
    case types.RESET_LOGIN_ERROR_MESSAGE: {
      newState.errMsg = '';
      return newState;
    }

    // case '@@router/LOCATION_CHANGE':
    // case types.DO_LOGOUT: {
    //   const isRegistrationSuccess = newState.isRegistrationSuccess;
    //   const isPasswordChangeSuccess = newState.isPasswordChangeSuccess;
    //   newState = cloneDeep(initialState.login);
    //   if (isPasswordChangeSuccess) {
    //     newState.isPasswordChangeSuccess = isPasswordChangeSuccess;
    //   }
    //   if (isRegistrationSuccess) {
    //     newState.isRegistrationSuccess = isRegistrationSuccess;
    //   }
    //   return newState;
    // }

    case types.DO_LOGIN: {
      newState.isRegistrationSuccess = false;
      newState.isPasswordChangeSuccess = false;
      return newState;
    }

    case types.DO_LOGIN_SUCCESS:
    case types.DO_LOGIN_MFA_SUCCESS:
    case types.DO_LOGIN_PLAY_JOIN_SUCCESS:
    case types.DO_LOGIN_SCREEN_SUCCESS: {
      if (action.data && action.data.codeRequired) {
        newState = cloneDeep(initialState.login);
        newState.codeRequired = true;
        return newState;
      } else if (action.data && (!action.data.token || !action.data.user)) {
        newState = cloneDeep(initialState.login);
        return newState;
      }

      newState = cloneDeep(initialState.login);
      const jwtToken = (action.data && action.data.token) || '';
      let user = (action.data && action.data.user) || {};
      if (!isEmpty(user)) {
        user = formatProfileData(user);
      }
      auth.setTokenUser(jwtToken, cloneDeep(user));
      setAuthHeader(jwtToken);

      if (jwtToken && !isEmpty(user) && !action.skipMove) {
        setTimeout(() => {
          history.replace(action.moveToPath || '/');
        }, 200);
      }
      return newState;
    }

    case types.DO_LOGIN_FAILURE:
    case types.DO_LOGIN_PLAY_JOIN_FAILURE:
    case types.DO_LOGIN_SCREEN_FAILURE: {
      if (action.data && action.data.error) {
        newState.errMsg = action.data.error;
      } else {
        newState.errMsg = 'generic.server_error_msg';
      }
      return newState;
    }

    case types.DO_REGISTER_SUCCESS: {
      newState.isRegistrationSuccess = true;
      return newState;
    }

    case types.DO_CHANGE_PASSWORD_SUCCESS: {
      newState.isPasswordChangeSuccess = true;
      return newState;
    }

    default:
      return state;
  }
}
