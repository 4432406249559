import * as types from '../constants/actionTypes';
import { wrapCall } from '../utils/serviceUtils';
import { api } from '../utils/services';
import auth from '../utils/auth';

export const resetEmployeeChatsData = (fields) => {
  return {
    type: types.RESET_EMPLOYEE_CHATS_DATA,
    fields
  };
};

export const showEmployeeChatModal = (id) => {
  return {
    type: types.SHOW_EMPLOYEE_CHAT_MODAL,
    id
  };
}

export const hideEmployeeChatModal = () => {
  return {
    type: types.HIDE_EMPLOYEE_CHAT_MODAL
  };
}

export const updateEmployeeChatData = (field, value) => {
  return {
    type: types.UPDATE_EMPLOYEE_CHAT_DATA,
    field,
    value
  };
}

export const validateEmployeeChatData = (field, value) => {
  return {
    type: types.VALIDATE_EMPLOYEE_CHAT_DATA,
    field,
    value
  }
};

export const fetchEmployeeChats = (search, page, perPage) => {
  return wrapCall(
    api.get(`/business/conversations?page=${page}&perPage=${perPage || 10}${search ? '&search=' + search : ''}&anotherIdType=3`),
    types.FETCH_EMPLOYEE_CHATS
  );
};

export const fetchEmployeeMessages = (id, search, page, perPage, user, isLoadingDisabled) => {
  return wrapCall(
    api.get(`/business/messages?page=${page}&perPage=${perPage || 20}${search ? '&search=' + search : ''}&anotherIdType=3&anotherId=${id}`),
    types.FETCH_EMPLOYEE_MESSAGES,
    {id, user, isLoadingDisabled}
  );
};

export const createEmployeeMessage = (id, data) => {
  let formData = new FormData();
  for (let key in data) {
    formData.append(key, data[key]);
  }
  formData.append('createdForId', id);
  formData.append('createdForType', 3);
  formData.append('token', auth.getToken());

  return wrapCall(
    api.post(`/business/messages`, formData, {'Content-Type': 'multipart/form-data'}),
    types.CREATE_EMPLOYEE_MESSAGE
  );
};

export const deleteEmployeeMessage = (id) => {
  return wrapCall(
    api.delete(`/business/messages/${id}`),
    types.DELETE_EMPLOYEE_MESSAGE,
    {id}
  );
};
