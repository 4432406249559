import {isString, isNumber} from 'lodash';
import constants from '../constants';

const validations = constants.validations.gift;

export default function giftValidator(field, value, options) {
  switch(field) {
    case 'name':
      return validateGiftName(value, options);
    case 'description':
      return validateDescription(value, options);
    case 'descriptionAr':
      return validateDescriptionAr(value, options);
    case 'tnc':
      return validateTnc(value, options);      
    case 'tncAr':
      return validateTncAr(value, options);
    case 'imageFile':
      return validateImageFile(value, options);
    case 'pointsToRedeem':
      return validatePointsToRedeem(value, options);
    case 'startAt':
      return validateStartAt(value, options);
    case 'endAt':
      return validateEndAt(value, options);
  }
}

export function validateGiftName(value) {
  const prefix = 'admin.gift.validation.name';

  if (validations.name.isRequired && (!isString(value) || !value.trim())) {
    return prefix + '.required';
  }
}

export function validateDescription(value) {
  const prefix = 'admin.gift.validation.description';

  if (
    validations.description.maxChar && 
    (
      value &&
      value.length > validations.description.maxChar
    )
  ) {
    return prefix + '.max_char'
  }
}

export function validateTnc(value) {
  const prefix = 'admin.gift.validation.tnc';

  if (
    validations.tnc.maxChar &&
    (
      value &&
      value.length > validations.tnc.maxChar
    )
  ) {
    return prefix + '.max_char'
  }
}

export function validateDescriptionAr(value) {
  const prefix = 'admin.gift.validation.description_ar';

  if (
    validations.description.maxChar &&
    (
      value &&
      value.length > validations.description.maxChar
    )
  ) {
    return prefix + '.max_char'
  }
}

export function validateTncAr(value) {
  const prefix = 'admin.gift.validation.tnc_ar';

  if (
    validations.tnc.maxChar &&
    (
      value &&
      value.length > validations.tnc.maxChar
    )
  ) {
    return prefix + '.max_char'
  }
}

export function validateImageFile(value, options) {
  const prefix = 'admin.gift.validation.image_file';

  if (validations.imageFile.isRequired && !value && !options.is_edit) {
    return prefix + '.required';
  }
}

export function validatePointsToRedeem(value) {
  const prefix = 'admin.gift.validation.points_to_redeem';

  if (validations.pointsToRedeem.isRequired && (!isNumber(value) && (!isString(value) || !value.trim()))) {
    return prefix + '.required';
  }
}

export function validateStartAt(value) {
  const prefix = 'admin.gift.validation.start_at';

  if (validations.startAt.isRequired && !(value instanceof Date) && (!isString(value) || !value.trim())) {
    return prefix + '.required';
  }
}

export function validateEndAt(value) {
  const prefix = 'admin.gift.validation.end_at';

  if (validations.endAt.isRequired && !(value instanceof Date) && (!isString(value) || !value.trim())) {
    return prefix + '.required';
  }
}
