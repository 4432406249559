import constants from '../constants';

export default {
  app: {
    defaultLocale: 'ar',
    isLTR: false,
    locale: [
      {
        label: 'English',
        value: 'en',
        direction: 'ltr'
      },
      {
        label: 'عربى',
        value: 'ar',
        direction: 'rtl'
      }
    ],
    helps: {
      errMsg: '',
      help: {
      }
    },
    nationalities: {
      data: [],
      error: null,
    },
    countries: {
      data: [{id: 1, numberCode: 966, numberLength: 9, name: 'Saudi Arabia'}],
      error: null,
      isFetched: false,
    },
  },
  ui: {
    snackbar: {}
  },
  home: {
    landing: {}
  },
  login: {
    errMsg: '',
    isRegistrationSuccess: false,
    isPasswordChangeSuccess: false
  },
  signUp: {
    errMsg: '',
    isRegistrationSuccess: false,
  },
  registration: {
    currentStep: 1,
    canGoNextStep: false,
    showMobileVerificationModal: false,
    categories: [],
    subCategories: [],
    localities: [],
    subLocalities: [],
    requiredFields: {
      1: ['businessName', 'recordNumber', 'recordImageFile', /*'owingType',*/ 'categoryId', /*'formImageFile'*/],
      2: [/*'contactPersonName', 'contactPersonNumber', 'contactPersonEmail',*/ 'name', 'imageFile'],
      3: ['localityId', 'subLocalityId', 'latitude', 'longitude'],
      4: ['email', 'username', 'mobileNumber', 'password', 'confirmPassword']
    },
    data: {
      username: '',
      password: '',
      email: '@gmail.com',
      mobileNumber: '',
      businessName: '',
      recordNumber: '',
      recordImageFile: null,
      formImageFile: null,
      imageFile: null,
      owingType: 1,
      categoryId: null,
      contactPersonName: '',
      contactPersonEmail: '',
      contactPersonNumber: '',
      name: '',
      nameAr: '',
      localityId: null,
      subLocalityId: null,
      latitude: null,
      longitude: null
    },
    errors: {
    },
    isSuccess: false,
    errMsg: ''
  },
  redeemOffer: {
    errMsg: '',
    activity: null,
  },
  admin: {
    location: {
      pathname: '',
      search: '',
      hash: ''
    },
    cacheListData: {},
    user: {},
    business: {},
    profile: {
      showChangePasswordModal: false,
      showChangeSettingsModal: false,
      showChangeMobileModal: false,
      isEditing: false,
      isSuccess: false,
      successMsg: '',
      errMsg: '',
      errMsgChangePassword: '',
      errMsgSettings: '',
      errMsgChangeMobile: '',
      data: {},
      existing_data: {},
      categories: [],
      subCategories: [],
      localities: [],
      subLocalities: [],
      errors: {
      },
      requiredFields: ['bio'],
    },
    localities: [],
    subLocalities: [],
    branches: {
      errMsg: '',
      paginated: {
        data: [],
        perPage: constants.table.perPage,
        page: constants.table.page,
        canLoadMore: false,
        totalSize: 0
      },
      requiredFields: ['name', 'nameAr', 'address', 'addressAr', 'localityId', 'latitude', 'longitude'],
      branch: {
        showModal: false,
        data: {
          name: '',
          nameAr: '',
          address: '',
          localityId: null,
          subLocalityId: null,
          latitude: null,
          longitude: null
        },
        errors: {
        },
        errMsg: ''
      }
    },
    games: {
      errMsg: '',
      paginated: {
        data: [],
        perPage: constants.table.perPage,
        page: constants.table.page,
        canLoadMore: false,
        totalSize: 0
      },
      activity: {
        data: {
          type: 1,
          name: '',
          nameAr: '',
          description: '',
          descriptionAr: '',
          guide: '',
          guideAr: '',
          imageFile: null,
        },
        errors: {
        },
        errMsg: '',
      },
    },
    screens: {
      errMsg: '',
      paginated: {
        data: [],
        perPage: constants.table.perPage,
        page: constants.table.page,
        canLoadMore: false,
        totalSize: 0
      },
      item: {
        data: {
          type: 1,
          name: '',
        },
        errors: {
        },
        errMsg: '',
      },
    },
    screenCampaignRequests: {
      errMsg: '',
      paginated: {
        data: [],
        perPage: constants.table.perPage,
        page: constants.table.page,
        canLoadMore: false,
        totalSize: 0
      },
      item: {
        data: {
          type: 1,
          name: '',
        },
        errors: {
        },
        errMsg: '',
      },
    },
    gifts: {
      errMsg: '',
      paginated: {
        data: [],
        perPage: constants.table.perPage,
        page: constants.table.page,
        canLoadMore: false,
        totalSize: 0
      },
      requiredFields: ['name', 'imageFile', 'pointsToRedeem', 'startAt', 'endAt', 'description', 'descriptionAr', 'tnc', 'tncAr'],
      gift: {
        showModal: false,
        data: {
          branchId: '',
          name: '',
          nameAr: '',
          imageFile: null,
          imageFileAr: null,
          consumeLimit: null,
          pointsToRedeem: '',
          startAt: null,
          endAt: null,
        },
        errors: {
        },
        errMsg: ''
      }
    },
    earnPointFormulas: {
      errMsg: '',
      paginated: {
        data: [],
        perPage: constants.table.perPage,
        page: constants.table.page,
        canLoadMore: false,
        totalSize: 0
      },
      requiredFields: [/*'name', 'priority', 'startAt', 'endAt',*/ 'spendsPerPoint'],
      earnPointFormula: {
        showModal: false,
        data: {
          branchId: '',
          name: '',
          priority: '',
          startAt: null,
          endAt: null,
          spendsPerPoint: null,
          minBillToEarnPoint: null,
          maxEarnPoints: null
        },
        errors: {
        },
        errMsg: ''
      },
      allowBalanceTransfer: 0,
    },
    redeemPointFormulas: {
      errMsg: '',
      showAddButton: true,
      paginated: {
        data: [],
        perPage: constants.table.perPage,
        page: constants.table.page,
        canLoadMore: false,
        totalSize: 0
      },
      requiredFields: ['name', 'priority', 'startAt', 'endAt', 'minSpendsToRedeem', 'minPointsCanRedeem', 'maxPointsCanRedeem', 'maxPointsCanRedeemDaily', 'minWalletBalanceToRedeem'],
      redeemPointFormula: {
        showModal: false,
        data: {
          branchId: '',
          name: '',
          priority: '',
          startAt: null,
          endAt: null,
          minSpendsToRedeem: null,
          minPointsCanRedeem: null,
          maxPointsCanRedeem: null,
          maxPointsCanRedeemDaily: null,
          minWalletBalanceToRedeem: null
        },
        errors: {
        },
        errMsg: ''
      }
    },
    employees: {
      errMsg: '',
      paginated: {
        data: [],
        perPage: constants.table.perPage,
        page: constants.table.page,
        canLoadMore: false,
        totalSize: 0
      },
      requiredFields: ['mobileNumbers'],
      employee: {
        showModal: false,
        data: {
          mobileNumbers: [],
          role: 31,
          branchIds: []
        },
        errors: {
        },
        errMsg: ''
      }
    },
    users: {
      errMsg: '',
      paginated: {
        data: [],
        perPage: constants.table.perPage,
        page: constants.table.page,
        canLoadMore: false,
        totalSize: 0
      },
      user: {
        showModal: false,
        data: {
        },
        errors: {
        },
        errMsg: ''
      }
    },
    posts: {
      errMsg: '',
      paginated: {
        data: [],
        perPage: constants.table.perPage,
        page: constants.table.page,
        canLoadMore: false,
        totalSize: 0
      },
      requiredFields: [],
      post: {
        showModal: false,
        data: {
        },
        defaultComment: {
          id: 0,
          createdById: 0,
          postId: 0,
          title: '',
          createdAt: '',
          audioDuration: null,
          state: 1,
          type: 2,
          parentId: null,
          level: 1,
          likesCount: 0,
          dislikesCount: 0,
          imageUrl: null,
          audioUrl: null,
          createdBy: {},
          parent: {}
        },
        comments: {
          data: [],
          perPage: constants.table.perPage,
          page: constants.table.page,
          canLoadMore: false,
          totalSize: 0
        },
        errors: {
        },
        errMsg: ''
      }
    },
    activities: {
      errMsg: '',
      paginated: {
        data: [],
        perPage: constants.table.perPage,
        page: constants.table.page,
        canLoadMore: false,
        totalSize: 0
      },
      requiredFields: ['type', 'title', 'startAt', 'endAt', 'imageFile', 'qTitle', 'qOptions', 'cQuestions'],
      activity: {
        showCommentModal: false,
        showModal: false,
        showDisplayCriteriaModal: false,
        data: {
          type: 1,
          title: '',
          titleAr: '',
          description: '',
          descriptionAr: '',
          tnc: '',
          tncAr: '',
          imageFile: null,
          imageFileAr: null,
          audioFile: null,
          audioFileAr: null,
          priority: '',
          startAt: null,
          endAt: null,
          displayLimit: '',
          displayLimitPerUser: '',
          consumeLimit: '',
          consumeLimitPerUser: '',
          clickLimit: '',
          answerLimit: '',
          transferLimitPerUser: '',
          clickedPoints: '',
          answeredPoints: '',
          consumedPoints: '',
          isTransferable: 0,
          isCodeNeeded: 0,
          isBusinessTargeted: 1,
          branchIds: [],
          cRegionIds: [],
          cLocalityIds: [],
          cSubLocalityIds: [],
          cGender: '',
          cMaritalStatus: '',
          cAgeGroups: [],
          cEducationLevels: [],
          cNationalities: [],
          cProfessions: [],
          cCompanyIds: [],
          cCompanyLevels: [],
          cEarnedSystemPoint: '',
          cTargetedUsers: [],
          qOptions: [],
          cQuestions: [],
          consumableWDays: [],
          rightOption: '',
          rightOptionHint: '',
          rightOptionHintAr: '',
          feedbackHint: '',
          feedbackHintAr: '',
          isUserTargeted: 0,
        },
        defaultComment: {
          id: 0,
          createdById: 0,
          activityId: 0,
          title: '',
          createdAt: '',
          audioDuration: null,
          state: 1,
          type: 2,
          parentId: null,
          level: 1,
          likesCount: 0,
          dislikesCount: 0,
          imageUrl: null,
          audioUrl: null,
          createdBy: {},
          parent: {}
        },
        comment: {
        },
        comments: {
          data: [],
          perPage: constants.table.perPage,
          page: constants.table.page,
          canLoadMore: false,
          totalSize: 0
        },
        errors: {
        },
        errMsg: '',
      },
      showAnswersModal: false,
      answers: {},
      showRatingsModal: false,
      ratings: {},
      regions: [],
      localities: [],
      subLocalities: [],
      companies: [],
      companyLevels: [],
      users: [],
      questions: [],
      questionOptions: {},
      ageGroups: [],
      questionsNOptions: [],
    },
    funActivities: {
      errMsg: '',
      lastPaginatedData1: [],
      lastPaginatedData2: [],
      paginated: {
        data: [],
        perPage: constants.table.perPage,
        page: constants.table.page,
        canLoadMore: false,
        totalSize: 0
      },
      requiredFields: ['type', 'title', 'startAt', 'endAt', 'imageFile', 'qTitle', 'qOptions', 'cQuestions'],
      activity: {
        showCommentModal: false,
        showModal: false,
        showDisplayCriteriaModal: false,
        data: {
          type: 1,
          title: '',
          titleAr: '',
          description: '',
          descriptionAr: '',
          tnc: '',
          tncAr: '',
          imageFile: null,
          imageFileAr: null,
          audioFile: null,
          audioFileAr: null,
          priority: '',
          startAt: null,
          endAt: null,
          displayLimit: '',
          displayLimitPerUser: '',
          consumeLimit: '',
          consumeLimitPerUser: '',
          clickLimit: '',
          answerLimit: '',
          transferLimitPerUser: '',
          clickedPoints: '',
          answeredPoints: '',
          consumedPoints: '',
          isTransferable: 0,
          isCodeNeeded: 0,
          isBusinessTargeted: 1,
          branchIds: [],
          cRegionIds: [],
          cLocalityIds: [],
          cSubLocalityIds: [],
          cGender: '',
          cMaritalStatus: '',
          cAgeGroups: [],
          cEducationLevels: [],
          cNationalities: [],
          cProfessions: [],
          cCompanyIds: [],
          cCompanyLevels: [],
          cEarnedSystemPoint: '',
          cTargetedUsers: [],
          qOptions: [],
          cQuestions: [],
          consumableWDays: [],
          rightOption: '',
          rightOptionHint: '',
          rightOptionHintAr: '',
          feedbackHint: '',
          feedbackHintAr: '',
          isUserTargeted: 0,
        },
        defaultComment: {
          id: 0,
          createdById: 0,
          activityId: 0,
          title: '',
          createdAt: '',
          audioDuration: null,
          state: 1,
          type: 2,
          parentId: null,
          level: 1,
          likesCount: 0,
          dislikesCount: 0,
          imageUrl: null,
          audioUrl: null,
          createdBy: {},
          parent: {}
        },
        comment: {
        },
        comments: {
          data: [],
          perPage: constants.table.perPage,
          page: constants.table.page,
          canLoadMore: false,
          totalSize: 0
        },
        errors: {
        },
        errMsg: '',
      },
      showAnswersModal: false,
      answers: {},
      showRatingsModal: false,
      ratings: {},
      regions: [],
      localities: [],
      subLocalities: [],
      companies: [],
      companyLevels: [],
      users: [],
      questions: [],
      questionOptions: {},
      ageGroups: [],
      questionsNOptions: [],
    },
    activityInsight: {
      configs: null,
      errMsg: '',
      data: [],
    },
    activityWinners: {
      isLoading: true,
      errMsg: '',
      paginated: {
        data: [],
        perPage: constants.table.perPage,
        page: constants.table.page,
        canLoadMore: false,
        totalSize: 0
      }
    },
    activityPlanRecharges: {
      isLoading: true,
      errMsg: '',
      paginated: {
        data: [],
        perPage: constants.table.perPage,
        page: constants.table.page,
        canLoadMore: false,
        totalSize: 0
      }
    },
    partners: {
      isLoading: true,
      errMsg: '',
      paginated: {
        data: [],
        perPage: constants.table.perPage,
        page: constants.table.page,
        canLoadMore: false,
        totalSize: 0
      }
    },
    businessesWallets: {
      isLoading: true,
      errMsg: '',
      paginated: {
        data: [],
        perPage: constants.table.perPage,
        page: constants.table.page,
        canLoadMore: false,
        totalSize: 0
      }
    },
    activityAnswerDistributions: {
      isLoading: true,
      errMsg: '',
      totalAnswers: 0,
      answers: [],
    },
    employeeChats: {
      errMsg: '',
      paginated: {
        data: [],
        perPage: constants.table.perPage,
        page: constants.table.page,
        canLoadMore: false,
        totalSize: 0
      },
      requiredFields: [],
      employeeChat: {
        showModal: false,
        user: {
        },
        data: {
        },
        messages: {
          formatted: [],
          data: [],
          perPage: 50 || constants.table.perPage,
          page: constants.table.page,
          canLoadMore: false,
          totalSize: 0
        },
        errors: {
        },
        errMsg: ''
      }
    },
    customerChats: {
      errMsg: '',
      paginated: {
        data: [],
        perPage: constants.table.perPage,
        page: constants.table.page,
        canLoadMore: false,
        totalSize: 0
      },
      requiredFields: [],
      customerChat: {
        showModal: false,
        user: {
        },
        data: {
        },
        messages: {
          formatted: [],
          data: [],
          perPage: 50 || constants.table.perPage,
          page: constants.table.page,
          canLoadMore: false,
          totalSize: 0
        },
        errors: {
        },
        errMsg: ''
      }
    },
    insights: {
      errMsg: '',
      tabs: {
        employeesData: [],
        salesData: [],
        campaignsData: [],
        productsData: [],
        profileData: [],
      },
      focusAreas: [],
      charts: [],
      filters: [],
      employees: {
        data: [],
        perPage: constants.table.perPage,
        page: constants.table.page,
        canLoadMore: false,
        totalSize: 0
      },
      sEmployees: {
        data: [],
        perPage: constants.table.perPage,
        page: constants.table.page,
        canLoadMore: false,
        totalSize: 0
      },
      users: {
        data: [],
        perPage: constants.table.perPage,
        page: constants.table.page,
        canLoadMore: false,
        totalSize: 0
      },
      sUsers: {
        data: [],
        perPage: constants.table.perPage,
        page: constants.table.page,
        canLoadMore: false,
        totalSize: 0
      },
      productItems: {
        data: [],
        perPage: constants.table.perPage,
        page: constants.table.page,
        canLoadMore: false,
        totalSize: 0
      },
      ageGroups: [],
      regions: [],
      activityRegions: [],
      sRegions: [],
      sActivityRegions: [],
      localities: [],
      employeeLocalities: [],
      sEmployeeLocalities: [],
      activityLocalities: [],
      sLocalities: [],
      sActivityLocalities: [],
      subLocalities: [],
      activitySubLocalities: [],
      sSubLocalities: [],
      sActivitySubLocalities: [],
      questions: [],
      answers: [],
      sQuestions: [],
      sAnswers: [],
      focusAreaQuestions: [],
      companies: [],
      activityCompanies: [],
      sActivityCompanies: [],
      sCompanies: [],
      activityBranches: [],
      sBranches: [],
      sActivityBranches: [],
      offers: [],
      sOffers: [],
      gifts: {
        data: [],
        perPage: constants.table.perPage,
        page: constants.table.page,
        canLoadMore: false,
        totalSize: 0
      },
      sGifts: {
        data: [],
        perPage: constants.table.perPage,
        page: constants.table.page,
        canLoadMore: false,
        totalSize: 0
      },
      activities: {
        data: [],
        perPage: constants.table.perPage,
        page: constants.table.page,
        canLoadMore: false,
        totalSize: 0
      },
      sActivities: {
        data: [],
        perPage: constants.table.perPage,
        page: constants.table.page,
        canLoadMore: false,
        totalSize: 0
      }
    },
    transactions: {
      errMsg: '',
      paginated: {
        data: [],
        perPage: constants.table.perPage,
        page: constants.table.page,
        canLoadMore: false,
        totalSize: 0
      }
    },
    transferTransactions: {
      errMsg: '',
      paginated: {
        data: [],
        perPage: constants.table.perPage,
        page: constants.table.page,
        canLoadMore: false,
        totalSize: 0
      }
    },
    userBalances: {
      errMsg: '',
      paginated: {
        data: [],
        perPage: constants.table.perPage,
        page: constants.table.page,
        canLoadMore: false,
        totalSize: 0
      }
    },
    systemUsers: {
      errMsg: '',
      paginated: {
        data: [],
        perPage: constants.table.perPage,
        page: constants.table.page,
        canLoadMore: false,
        totalSize: 0
      },
      requiredFields: ['email', 'mobileNumber', 'access'],
      systemUser: {
        showModal: false,
        data: {
          email: '',
          mobileNumber: '',
          branchIds: [],
          access: []
        },
        errors: {
        },
        errMsg: ''
      }
    },

    productCategories: {
      errMsg: '',
      paginated: {
        data: [],
        perPage: constants.table.perPage,
        page: constants.table.page,
        canLoadMore: false,
        totalSize: 0
      },
      requiredFields: ['name', 'nameAr'],
      productCategory: {
        showModal: false,
        data: {
          name: '',
          nameAr: '',
        },
        errors: {
        },
        errMsg: ''
      }
    },
    productItems: {
      errMsg: '',
      paginated: {
        data: [],
        perPage: constants.table.perPage,
        page: constants.table.page,
        canLoadMore: false,
        totalSize: 0
      },
      requiredFields: ['categoryId', 'name', 'nameAr', 'imageUrls', 'price', 'discountedPrice'],
      productItem: {
        showModal: false,
        data: {
          category: '',
          name: '',
          nameAr: '',
          details: '',
          detailsAr: '',
          imageUrls: [],
          price: '',
          discountPrice: '',
          canChat: 1,
        },
        errors: {
        },
        errMsg: ''
      }
    },

    notifications: {
      errMsg: '',
      showModal: false,
      showSystemNotification: '',
      paginated: {
        data: [],
        perPage: constants.table.perPage,
        page: constants.table.page,
        canLoadMore: false,
        totalSize: 0,
        unreadCount: 0
      }
    },
    supportChats: {
      supportChat: {
        showModal: false,
        user: {
        },
        data: {
        },
        messages: {
          formatted: [],
          data: [],
          perPage: 50 || constants.table.perPage,
          page: constants.table.page,
          canLoadMore: false,
          totalSize: 0
        },
        errors: {
        },
        errMsg: ''
      }
    }
  }
};
