import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Loader from "../commons/Loader";
import {baseStyles, BORDER_RADIUS, DRAWER_WIDTH, withMediaQueries, withMultipleStyles} from "src/styles/Styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Avatar, Button, Divider, List, Typography} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {get} from "lodash";
import Space from "../commons/Space";
import {ColorAccent, ColorAccentLite, ColorDivider, ColorTheme, ColorWhite} from "src/styles/Colors";
import {FormattedMessage} from "react-intl";
import LinkHelper from "../../utils/LinkHelper";
import {history} from "../../store/configureStore";
import TextView from "../commons/TextView";
import {PathLogout, PathPlays} from "src/js/components/routes/NavHelper";

const useStyles = () => ({
  viewLoading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  listItemMenu: {
    borderRadius: BORDER_RADIUS,
    margin: 8,
    marginTop: 12,
    marginBottom: 12,
    width: DRAWER_WIDTH - 20,
    "&:hover": {
      backgroundColor: ColorAccentLite,
    },
    "&.Mui-focused": {
      backgroundColor: ColorAccent,
    },
    "&.Mui-selected": {
      backgroundColor: ColorAccent,
    },
    "&.Mui-selected:hover": {
      backgroundColor: ColorAccent,
    },
  }
});

class AdminStandalone extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openDrawer: false
    }
    this.menus = [
      {nameId: 'menu.play', pathname: PathPlays},
    ];
    this.menus1 = [
      // {nameId: 'menu.discover', pathname: '/'},
      {nameId: 'menu.campaigns', pathname: '/campaigns'},
      // {nameId: 'menu.marketingCampaigns', pathname: '/marketingCampaigns'},
      // {nameId: 'menu.loyaltyCampaigns', pathname: '/loyaltyCampaigns'},
      // {nameId: 'menu.educationCampaigns', pathname: '/educationCampaigns'},
      // {nameId: 'menu.trainingCampaigns', pathname: '/trainingCampaigns'},
      // {nameId: 'menu.socialCampaigns', pathname: '/socialCampaigns'},
      // {nameId: 'menu.influencerCampaigns', pathname: '/influencerCampaigns'},
      {nameId: 'menu.myRewards', pathname: '/rewards'},
    ];
    this.menus2 = [
      {nameId: 'menu.settings', pathname: '/profile'},
    ];
  }

  render() {
    const {isLoading, isBlockedLoading, admin, app, classes, isDrawerHidden} = this.props;
    const {openLogoutDialog} = this.state;

    return (
      <div className={classes.flexColumn} style={{minWidth: window.appWidth, minHeight: window.appHeight, padding: 0}}>
        {this.props.children}

        {isLoading && (
          <div className={classes.viewLoading}>
            <CircularProgress />
          </div>
        )}
        {isBlockedLoading && <Loader />}
      </div>
    );
  }

  getDrawerWrapperView() {
    const {app, classes, history, admin, isDrawerHidden} = this.props;

    const path = get(history, 'location.pathname');

    const getListItems = (menus) => {
      return (
        menus.map((menu, index) => {
          const isSelected = path.startsWith(menu.pathname)
          return(
            <ListItem className={classes.listItemMenu} button selected={isSelected} key={index +'-'+ menu.name} onClick={this.onDrawerMenuClicked.bind(this, menu)}>
              {/*<img src={path.startsWith(menu.pathname) ? menu.imageS : menu.image} width={28} height={'auto'} />*/}
              <Space vertical={8} />
              <Typography variant={'subtitle1'} className={isSelected ? classes.typoBold : classes.typoNormal} style={{paddingTop: 2, paddingBottom: 2, color: ColorWhite}}>
                <FormattedMessage id={menu.nameId} />
              </Typography>
            </ListItem>
          )
        })
      )
    };

    const drawerView = (
      <div className={classes.flexColumn} style={{width: DRAWER_WIDTH, backgroundColor: ColorTheme}}>
        <Toolbar style={{width: DRAWER_WIDTH}}>
          {isDrawerHidden ?
            <div className={classes.flexRow} >
              <Space start={app.isLTR ? -8 : 0} end={app.isLTR ? 0 : -8} />
              <IconButton style={{color: ColorWhite}} onClick={() => {this.toggleDrawer(false)}}>
                {app.isLTR ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
              <Space vertical={8} />
              <Button style={{color: ColorWhite}} size={"large"} onClick={this.onHomeClicked}>
                <div className={classes.flexRow}>
                  <Avatar variant="square" src={require('../../../images/r-logo-gold.png')} />
                  <Space vertical={10} />
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <TextView textId={'app.name1'} color={ColorWhite} fontWeight={'bold'} />
                  </div>
                </div>
              </Button>
            </div>
            : null
          }
        </Toolbar>
        {/*<Divider style={{width: '100%'}}/>*/}
        <Divider style={{width: '100%', marginBottom: 0, backgroundColor: ColorDivider}}/>
        <List disablePadding>
          {getListItems(this.menus)}
        </List>
        <Divider style={{width: '100%', marginBottom: 0, backgroundColor: ColorDivider}}/>
        <List disablePadding>
          {getListItems(this.menus1)}
        </List>
        <Divider light style={{width: '100%', marginTop: 0, marginBottom: 0, backgroundColor: ColorDivider}}/>
        <List disablePadding>
          {getListItems(this.menus2)}
        </List>
        {/*<ListItem button className={classes.listItemMenu} style={{marginTop: 0}} key={'keyLogout'} onClick={this.onToggleDialogLogoutDialog.bind(this, true)}>*/}
        {/*  <Space vertical={8} />*/}
        {/*  <Typography variant={'subtitle1'} style={{paddingTop: 2, paddingBottom: 2, color: ColorNegative}}>*/}
        {/*    <FormattedMessage id={'generic.logout'} />*/}
        {/*  </Typography>*/}
        {/*</ListItem>*/}
        <div className={classes.flexGrow} style={{height: '100%'}} />

        <ListItem key={'keyInfo'}>
          <img src={admin.user.imageUrl} width={40} height={40} style={{borderRadius: 8}} />
          <Space vertical={12} />
          <Typography variant={'subtitle2'} style={{paddingTop: 4, paddingBottom: 4, color: ColorWhite}}>
            {admin.user.name}
          </Typography>
        </ListItem>
        {/*<Divider style={{width: '100%'}}/>*/}
        {/*<Divider style={{width: '100%'}}/>*/}
        {/*<div className={classes.flexRow} style={{padding: 8}}>*/}
        {/*  <LanguageSelector />*/}
        {/*</div>*/}
        {/*<Divider light style={{width: '100%'}}/>*/}
        {/*<ListItem key={'keyTnc'} button onClick={this.onTncClicked}>*/}
        {/*  <Typography variant={'caption'}>*/}
        {/*    <FormattedMessage id='login.tnc' />*/}
        {/*  </Typography>*/}
        {/*</ListItem>*/}
      </div>
    );
    return (
      <div>
        {isDrawerHidden ?
          <Drawer
            variant="temporary"
            anchor={'left'}
            open={this.state.openDrawer}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
              onBackdropClick: () => {this.toggleDrawer(false)}
            }}>
            {drawerView}
          </Drawer>
          : <Drawer
            variant="permanent"
            open>
            {drawerView}
          </Drawer>
        }
      </div>
    );
  }

  onHomeClicked = () => {
    history.replace('/');
  };

  onTncClicked = () => {
    LinkHelper.openTnc(this.props.app.defaultLocale);
  };

  onToggleDialogLogoutDialog = (show) => {
    this.setState({openLogoutDialog: show});
  };

  onLogoutClicked = () => {
    this.setState({openLogoutDialog: false});
    LinkHelper.open(PathLogout);
  };

  onDrawerMenuClicked = (menu) => {
    const {history} = this.props;
    this.toggleDrawer(false);
    if (menu.pathname === '/home') {
      history.replace(menu.pathname);
    } else {
      const path = history.location.pathname + history.location.hash;
      if (menu.pathname !== path) {
        history.push(menu.pathname);
      } else {
        history.push(menu.pathname +'#0');
      }
    }
  };

  toggleDrawer(open) {
    this.setState({
      openDrawer: open
    });
  }
}

AdminStandalone.propTypes = {
  classes: PropTypes.object,
  app: PropTypes.object,
  isLoading: PropTypes.bool,
  isBlockedLoading: PropTypes.bool,
  history: PropTypes.object,
  admin: PropTypes.object,
  children: PropTypes.element
};

AdminStandalone.contextTypes = {
  intl:PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    app: state.app,
    isLoading: state.isLoading,
    isBlockedLoading: state.isBlockedLoading,
    admin: state.admin,
  };
}

const mapDispatchToProps = (/*dispatch*/) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withMultipleStyles(baseStyles, useStyles)(withMediaQueries([
  ['isDrawerHidden', theme => theme.breakpoints.down('sm'), {
    defaultMatches: true
  }]
])(AdminStandalone)));
