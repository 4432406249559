import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {get} from 'lodash';

import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../../../styles/admin/admin-push-notification.scss';

import {history} from '../../store/configureStore';
import auth from '../../utils/auth';
import constants from '../../constants';
import {
  askForPermissionToReceiveNotifications,
  getSavedToken,
  isFcmSupported,
  registerMessageHandler,
  saveToken
} from '../../utils/pushNotification';

import {doRegisterDevice} from '../../actions/profileActions';

import {
  doUpdateNotificationState,
  fetchNotifications,
  hideSystemNotificationModal,
  showSystemNotificationModal,
} from '../../actions/notificationActions';

import {fetchEmployeeMessages} from '../../actions/employeeChatsActions';
import {fetchCustomerMessages} from '../../actions/customerChatsActions';
import {fetchPostComments} from '../../actions/postsActions';
import {fetchActivityComments} from '../../actions/activitiesActions';
import {fetchSupportMessages, showSupportChatModal} from '../../actions/supportChatsActions';
import {baseStyles, withMultipleStyles} from "src/styles/Styles";
import {ColorSubTitle, ColorTheme, ColorTitle} from "src/styles/Colors";
import AppIcon, {IconNotification} from "src/js/components/commons/AppIcon";
import TextView from "src/js/components/commons/TextView";
import {FontWeightMedium, FontWeightRegular} from "src/res/FontWeight";
import Space from "src/js/components/commons/Space";
import {colorWithAlpha} from "src/js/utils/AppUtils";
import LinkHelper from "src/js/utils/LinkHelper";

const useStyles = (theme) => ({
});

toast.configure({
  autoClose: 5000,
  draggable: false,
  hideProgressBar: true,
  pauseOnFocusLoss: false
});

class PushNotification extends React.Component {
  constructor(props) {
    super(props);

    this.errorCounter = 0;
    this.state = {
      notifications: []
    };

    this.onSystemNotificationHide = this.onSystemNotificationHide.bind(this);
    this.onClickToast = this.onClickToast.bind(this);
    this.onCloseToast = this.onCloseToast.bind(this);
  }

  componentDidMount() {
    this.processForPushToken();

    // if ('serviceWorker' in navigator) {
    //   this.messageHandler = ({payload}) => {
    //     console.log('FCM:MessageHandler', payload);
    //     const notification = get(payload, 'firebase-messaging-msg-data.notification');
    //     const message = get(payload, 'firebase-messaging-msg-data.data');
    //
    //     _this.showToast(notification, message);
    //   }
    //   navigator.serviceWorker.addEventListener('message', this.messageHandler);
    // }
  }

  componentWillUnmount() {
    registerMessageHandler(null);
    // if (('serviceWorker' in navigator) && this.messageHandler) {
    //   navigator.serviceWorker.removeEventListener('message', this.messageHandler);
    // }
  }

  processForPushToken = () => {
    const _this = this;

    // workaround to fetch again, since it throws error that the service worker is not registered
    const processAgain = () => {
      if (this.errorCounter < 1) {
        this.errorCounter++;
        setTimeout(() => {
          _this.processForPushToken();
        }, 2000);
      }
    }

    isFcmSupported().then(() => {
      const savedToken = getSavedToken();
      if (!savedToken) {
        askForPermissionToReceiveNotifications()
          .then((token) => {
            if (token) {
              console.log('FCM:token', token);
              saveToken(token);
              registerMessageHandler(_this.onForegroundMessage);
              _this.props.doRegisterDevice(token);
            } else {
              console.log('FCM:invalidToken');
              processAgain();
            }
          }).catch((err) => {
          processAgain();
          console.log('FCM:error', err);
          window.console.error(err)
        });
      }
      if (savedToken) {
        registerMessageHandler(this.onForegroundMessage);
        const user = auth.getUser();
        if (user && user.id && (!user.device || !user.device.pushNotificationToken)) {
          this.props.doRegisterDevice(savedToken);
        }
      }
    });
  }

  render() {
    return (
      <div className='push-notifications-container'>
        <ToastContainer enableMultiContainer containerId={'toastContainer'} position={toast.POSITION.TOP_RIGHT} />
      </div>
    );
  }

  onForegroundMessage = (payload) => {
    console.log('FCM:PushNotificationMessageReceived', payload);
    if (!payload.data) {
      payload.data = {};
    }
    payload.data.url = payload.fcmOptions ? payload.fcmOptions.link : null;
    this.showToast(payload.notification, payload.data);
  }

  renderToast = (notification, data, sharedData) => {
    const {classes} = this.props;
    return (
      <div
        className={classes.viewRowFullWidthNoCenter}
        style={{padding: 6}}
        onClick={() => {
          sharedData.isClicked = true;
          LinkHelper.open(data.url);
        }}>
        <AppIcon icon={IconNotification} color={colorWithAlpha(ColorTheme, 0.8)} style={{width: 20, height: 20, marginTop: 4}}/>
        <Space vertical={12}/>
        <div className={classes.viewColumnFullWidthNoCenter1}>
          <TextView color={ColorTitle} fontWeight={FontWeightMedium}>
            {get(notification, 'title')}
          </TextView>
          <TextView variant={'body2'} color={ColorSubTitle} fontWeight={FontWeightRegular}>
            {get(notification, 'body')}
          </TextView>
        </div>
      </div>
    );
  }

  showToast(notification, data) {
    // if (this.processMessage(message)) {
    //   return;
    // }

    const position = (auth.getLocale() === 'en') ? toast.POSITION.TOP_RIGHT : toast.POSITION.TOP_LEFT;
    const _this = this;
    const sharedData = {
      isClicked: false
    };
    const toastEl = () => {
      return _this.renderToast(notification, data, sharedData);
    }

    toast(toastEl, {
      containerId: 'toastContainer',
      position,
      onClose: (props) => !sharedData.isClicked
    });
  }

  processMessage(message) {
    const {user, employeeChat, customerChat, supportChat, post, activity} = this.props;

    if (!message) {
      return true;
    }

    switch(parseInt(message.type)) {

      case constants.notifications.type.TYPE_MESSAGE: {
        const userId = (user.id == message.createdById) ? message.createdForId : message.createdById;

        if ((message.createdByType == constants.user.type.CUSTOMER) && customerChat.showModal && customerChat.user.id == userId) {
          this.props.fetchCustomerMessages(userId, null, 1, null, null, true);
          return true;
        } else if ((message.createdByType == constants.user.type.EMPLOYEE) && employeeChat.showModal && employeeChat.user.id == userId) {
          this.props.fetchEmployeeMessages(userId, null, 1, null, null, true);
          return true;
        } else if ((message.createdByType == constants.user.type.SUPPORT) && supportChat.showModal) {
          this.props.fetchSupportMessages(null, 1, null, null, true);
          return true;
        }

        break;
      }

      case constants.notifications.type.TYPE_REPLY_IN_COMMENT: {
        if (post.showModal) {
          const newPost = JSON.parse(message.post);
          if (newPost.id == post.data.id) {
            this.props.fetchPostComments(post.data.id, null, 1, null, true);
          }
          return true;
        }
        break;
      }

      case constants.notifications.type.TYPE_REPLY_IN_COMMENT_IN_ACTIVITY: {
        if (activity.showCommentModal) {
          const newActivity = JSON.parse(message.activity);
          if (newActivity.id == activity.data.id) {
            this.props.fetchActivityComments(activity.data.id, null, 1, null, true);
          }
        }
        break;
      }
    }
  }

  onCloseToast() {
    this.props.fetchNotifications(1, null, true);
  }

  onClickToast(message) {
    const {user, showSystemNotificationModal} = this.props;

    if (message.type != constants.notifications.type.TYPE_SYSTEM) {
      this.props.doUpdateNotificationState(message.id, constants.notifications.state.READ);
    }

    switch(parseInt(message.type)) {
      case constants.notifications.type.TYPE_MESSAGE: {
        const userId = (user.id == message.createdById) ? message.createdForId : message.createdById;

        if(message.createdByType == constants.user.type.CUSTOMER) {
          setTimeout(() => {history.replace(`/home/chats/customers/${userId}`)}, 100);
        } else if (message.createdByType == constants.user.type.EMPLOYEE) {
          setTimeout(() => {history.replace(`/home/chats/employees/${userId}`)}, 100);
        } else if (message.createdByType == constants.user.type.SUPPORT) {
          this.props.showSupportChatModal();
        }
        break;
      }

      case constants.notifications.type.TYPE_REVIEW:
      case constants.notifications.type.TYPE_REPLY_IN_COMMENT: {
        const post = JSON.parse(message.post);
        setTimeout(() => {history.replace(`/home/comments/posts/${get(post, 'id') || ''}`)}, 100);
        break;
      }

      case constants.notifications.type.TYPE_COMMENT_IN_ACTIVITY:
      case constants.notifications.type.TYPE_REPLY_IN_ACTIVITY: {
        const activity = JSON.parse(message.activity);
        const activityId = activity.id;
        let path = `/home/comments/activities/${activityId}`;

        setTimeout(() => { history.replace(path) }, 100);
        break;
      }

      case constants.notifications.type.TYPE_SYSTEM: {
        const systemNotification = JSON.parse(message.systemNotification);
        showSystemNotificationModal(systemNotification.id)
        break;
      }

      case constants.notifications.type.TYPE_ACTIVITY_APPROVED:
      case constants.notifications.type.TYPE_ACTIVITY_REJECTED: {
        const activity = JSON.parse(message.activity);
        setTimeout(() => { history.replace(`/home/management/activities/${get(activity, 'id') || ''}`) }, 100);
        break;
      }
    }
  }

  onSystemNotificationHide() {
    const {
      hideSystemNotificationModal,
    } = this.props;

    hideSystemNotificationModal()
  }
}

PushNotification.propTypes = {
  user: PropTypes.object,
  post: PropTypes.object,
  activity: PropTypes.object,
  notifications: PropTypes.object,
  employeeChat: PropTypes.object,
  customerChat: PropTypes.object,
  supportChat: PropTypes.object,
  doRegisterDevice: PropTypes.func,
  doUpdateNotificationState: PropTypes.func,
  fetchEmployeeMessages: PropTypes.func,
  fetchCustomerMessages: PropTypes.func,
  fetchPostComments: PropTypes.func,
  fetchActivityComments: PropTypes.func,
  showSupportChatModal: PropTypes.func,
  fetchSupportMessages: PropTypes.func,
  fetchNotifications: PropTypes.func,
  showSystemNotificationModal: PropTypes.func,
  hideSystemNotificationModal: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    user: state.admin.user,
    post: state.admin.posts.post,
    activity: state.admin.activities.activity,
    employeeChat: state.admin.employeeChats.employeeChat,
    customerChat: state.admin.customerChats.customerChat,
    supportChat: state.admin.supportChats.supportChat,
    notifications: state.admin.notifications,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    doRegisterDevice: (token) => dispatch(doRegisterDevice(token)),
    doUpdateNotificationState: (id, state) => dispatch(doUpdateNotificationState(id, state)),
    fetchEmployeeMessages: (id, search, page, perPage, user, isLoadingDisabled) => dispatch(fetchEmployeeMessages(id, search, page, perPage, user, isLoadingDisabled)),
    fetchCustomerMessages: (id, search, page, perPage, user, isLoadingDisabled) => dispatch(fetchCustomerMessages(id, search, page, perPage, user, isLoadingDisabled)),
    fetchPostComments: (id, search, page, perPage, isLoadingDisabled) => dispatch(fetchPostComments(id, search, page, perPage, isLoadingDisabled)),
    fetchActivityComments: (id, search, page, perPage, isLoadingDisabled) => dispatch(fetchActivityComments(id, search, page, perPage, isLoadingDisabled)),
    showSupportChatModal: () => dispatch(showSupportChatModal()),
    fetchSupportMessages: (search, page, perPage, user, isLoadingDisabled) => dispatch(fetchSupportMessages(search, page, perPage, user, isLoadingDisabled)),
    fetchNotifications: (page, perPage, isLoadingDisabled) => dispatch(fetchNotifications(page, perPage, isLoadingDisabled)),
    showSystemNotificationModal: (id) => dispatch((showSystemNotificationModal(id))),
    hideSystemNotificationModal: () => dispatch((hideSystemNotificationModal())),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withMultipleStyles(baseStyles, useStyles)(PushNotification));
