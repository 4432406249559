'use strict';
import React from 'react';
import Space from "src/js/components/commons/Space";
import {FIELD_SPACE, FIELD_SPACE_XS, withMediaQueries} from "src/styles/Styles";
import PropTypes from "prop-types";

class FieldSpace extends React.PureComponent {

  render() {
    const {isXS, multiplier = 1, vertical = false} = this.props;

    return (
      <Space
        top={vertical ? 0 : (isXS ? FIELD_SPACE_XS : FIELD_SPACE) * multiplier}
        start={!vertical ? 0 : (isXS ? FIELD_SPACE_XS : FIELD_SPACE) * multiplier}/>
    );
  }
}

FieldSpace.propTypes = {
  multiplier: PropTypes.number,
  vertical: PropTypes.bool,
};

export default (withMediaQueries([
    ['isXS', theme => theme.breakpoints.down('xs'), {defaultMatches: true}]
  ])(FieldSpace)
);
