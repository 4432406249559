import {PathCampaigns, PathPlays} from "src/js/components/routes/NavHelper";

export const BOTTOM_MENUS = [
  {nameId: 'tab.play', pathname: PathPlays, morePathnames: ['/wons'], image: require('src/images/ic_menu_play_t.png'), imageS: require('src/images/ic_menu_play_st.png')},
  {nameId: 'tab.add', pathname: '/campaigns/manage', morePathnames: [], image: require('src/images/ic_menu_add.png'), imageS: require('src/images/ic_menu_add.png'), moveToTop: true, hideTab: true},
  {nameId: 'tab.build', pathname: PathCampaigns, morePathnames: ['/rewards'], image: require('src/images/ic_menu_campaigns_t.png'), imageS: require('src/images/ic_menu_campaigns_st.png')},
];

export default class PathHelper {

  static isBottomTabPresent(isDrawerHidden, history) {
    if (!isDrawerHidden) {
      return false;
    }

    const currentPath = history.location.pathname;
    const supportedPaths = [];
    BOTTOM_MENUS.forEach((menuL) => {
      if (!menuL.hideTab) {
        supportedPaths.push(menuL.pathname);
        if (menuL.morePathnames && menuL.morePathnames.length > 0) {
          menuL.morePathnames.forEach((pathname) => {
            supportedPaths.push(pathname);
          });
        }
      }
    });
    let isTab = false;
    for (let i = 0; i < supportedPaths.length; i++) {
      if (currentPath === supportedPaths[i]) {
        isTab = true;
        break;
      }
    }
    return isTab;
  }

}
