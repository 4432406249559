import * as types from '../constants/actionTypes';
import { wrapCall } from '../utils/serviceUtils';
import { api } from '../utils/services';
import auth from '../utils/auth';

export const resetCustomerChatsData = (fields) => {
  return {
    type: types.RESET_CUSTOMER_CHATS_DATA,
    fields
  };
};

export const showCustomerChatModal = (id) => {
  return {
    type: types.SHOW_CUSTOMER_CHAT_MODAL,
    id
  };
}

export const hideCustomerChatModal = () => {
  return {
    type: types.HIDE_CUSTOMER_CHAT_MODAL
  };
}

export const updateCustomerChatData = (field, value) => {
  return {
    type: types.UPDATE_CUSTOMER_CHAT_DATA,
    field,
    value
  };
}

export const validateCustomerChatData = (field, value) => {
  return {
    type: types.VALIDATE_CUSTOMER_CHAT_DATA,
    field,
    value
  }
};

export const fetchCustomerChats = (search, page, perPage) => {
  return wrapCall(
    api.get(`/business/conversations?page=${page}&perPage=${perPage || 10}${search ? '&search=' + search : ''}&anotherIdType=1`),
    types.FETCH_CUSTOMER_CHATS
  );
};

export const fetchCustomerMessages = (id, search, page, perPage, user, isLoadingDisabled) => {
  return wrapCall(
    api.get(`/business/messages?page=${page}&perPage=${perPage || 20}${search ? '&search=' + search : ''}&anotherIdType=1&anotherId=${id}`),
    types.FETCH_CUSTOMER_MESSAGES,
    {id, user, isLoadingDisabled}
  );
};

export const createCustomerMessage = (id, data) => {
  let formData = new FormData();
  for (let key in data) {
    formData.append(key, data[key]);
  }
  formData.append('createdForId', id);
  formData.append('createdForType', 1);
  formData.append('token', auth.getToken());

  return wrapCall(
    api.post(`/business/messages`, formData, {'Content-Type': 'multipart/form-data'}),
    types.CREATE_CUSTOMER_MESSAGE
  );
};

export const deleteCustomerMessage = (id) => {
  return wrapCall(
    api.delete(`/business/messages/${id}`),
    types.DELETE_CUSTOMER_MESSAGE,
    {id}
  );
};
