import {Scene} from 'phaser';

export class GCCBoot extends Scene {

  constructor() {
    super('GCCBoot');
  }

  preload() {
    this.load.image('background', 'assets/gameCannonCraze/bg.1.png');
  }

  create() {
    this.scene.start('GCCPreloader');
  }
}
