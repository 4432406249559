import {Scene} from 'phaser';
import {EventBus} from "src/gameTapTapShots/EventBus";
import {logDebug} from "src/js/utils/AppLog";

export class Game extends Scene {
  constructor() {
    super('Game');
  }

  onEventRestart = () => {
    EventBus.off('mute', this.onEventMute);
    EventBus.off('restart', this.onEventRestart);
    this.scene.stop('Game');
    this.scene.start('MainMenu');
  };

  onEventMute = (mute) => {
    if (this.audioJump) {
      this.audioJump.setMute(mute);
    }
    if (this.audioBasket) {
      this.audioBasket.setMute(mute);
    }
    if (this.audioGameEnd) {
      this.audioGameEnd.setMute(mute);
    }
  };

  create() {
    this.stringGameOver = this.game.options.locale && this.game.options.locale == 'ar' ? 'انتهت اللعبة' : 'GAME OVER';
    this.stringWellDone = this.game.options.locale && this.game.options.locale == 'ar' ? 'أحسنت' : 'WELL DONE';

    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;

    this.loopSide = null;
    this.hoopHeight = 0;
    this.isHit = false;
    this.isHit2 = false;
    this.hitCounter = 0;
    this.timeDuration = 10 * 1000;
    this.gameEnded = false;
    this.showAdBanner = false;
    // this.objectScales = [1.2, 1.4, 1.5];
    this.objectScales = [1.4];
    this.randomHoopPosition();
    this.objectScale = 1.4;
    const hoopPosition = this.getHoopPosition();

    this.audioJump = this.sound.add('audio_jump').setMute(this.game.options.mute);
    this.audioJump.setVolume(0.3);
    this.audioBasket = this.sound.add('audio_basket').setMute(this.game.options.mute);
    this.audioBasket.setVolume(0.7);
    this.audioGameEnd = this.sound.add('audio_game_end').setMute(this.game.options.mute);
    this.audioGameEnd.setVolume(0.2);
    this.audioGameWin = this.sound.add('audio_game_win').setMute(this.game.options.mute);
    this.audioGameWin.setVolume(0.5);
    EventBus.on('mute', this.onEventMute);
    EventBus.on('restart', this.onEventRestart);

    this.platforms = this.physics.add.staticGroup();
    this.platforms.create(gameWidth, gameHeight - 8, 'ground');
    this.platforms.create(0, gameHeight - 8, 'ground');

    const bgImage = this.add.image(gameWidth / 2, gameHeight / 2, 'background');
    bgImage.setDisplaySize(gameWidth, gameHeight);
    bgImage.preFX.addBlur(2, 2, 2, 1);

    const barWidth = gameWidth - 96;
    this.innerBarWidth = barWidth - 8;
    this.progressTimerBg = this.add.rectangle(gameWidth / 2, 52, barWidth, 32).setStrokeStyle(2, 0xEEEEEE);
    this.progressTimerBg.setAlpha(0);
    this.progressTimer = this.add.rectangle(gameWidth / 2, 52, this.innerBarWidth + 4, 28, 0xEEEEEE);
    this.progressTimer.setAlpha(0);

    this.textScore = this.add.text(gameWidth / 2, 125, this.hitCounter, {
      fontSize: 90, color: '#fff',
      stroke: '#48464F', strokeThickness: 4,
      align: 'center'
    }).setOrigin(0.5).setAlpha(0);

    this.adImage1 = this.add.image(gameWidth / 2, (gameWidth - 48) / 2 + 180, 'adImage1').setAlpha(0.2);
    this.adImage1.setDisplaySize(gameWidth - 48, gameWidth - 48);
    this.adImage = this.adImage1;
    this.adImage2 = this.add.image(gameWidth / 2, (gameWidth - 48) / 2 + 180, 'adImage2').setAlpha(0.2);
    this.adImage2.setDisplaySize(gameWidth - 48, gameWidth - 48);
    this.adImage2.setVisible(false);
    this.adImage3 = this.add.image(gameWidth / 2, (gameWidth - 48) / 2 + 180, 'adImage3').setAlpha(0.2);
    this.adImage3.setDisplaySize(gameWidth - 48, gameWidth - 48);
    this.adImage3.setVisible(false);

    this.hoop1 = this.physics.add.staticImage(hoopPosition.hoop1.x, hoopPosition.hoop1.y, 'hoop1');
    this.hoop2 = this.physics.add.staticImage(hoopPosition.hoop2.x, hoopPosition.hoop2.y, 'hoop2');
    this.hoop3 = this.physics.add.staticImage(hoopPosition.hoop3.x, hoopPosition.hoop3.y, 'hoop3');
    this.hoop5 = this.physics.add.staticImage(hoopPosition.hoop5.x, hoopPosition.hoop5.y, 'hoop5');
    this.hoop6 = this.physics.add.staticImage(hoopPosition.hoop6.x, hoopPosition.hoop6.y, 'hoop6');

    this.hoopb1 = this.physics.add.staticImage(hoopPosition.hoopb1.x, hoopPosition.hoopb1.y, 'hoopb1');
    this.hoopb2 = this.physics.add.staticImage(hoopPosition.hoopb2.x, hoopPosition.hoopb2.y, 'hoopb2');
    this.hoopb3 = this.physics.add.staticImage(hoopPosition.hoopb3.x, hoopPosition.hoopb3.y, 'hoopb3');

    this.ball = this.physics.add.sprite(gameWidth * 0.4, gameHeight * 0.5, 'ballC');
    this.ball.setBounce(0.7, 0.7);
    this.ball.setGravity(0, 1000);

    this.hoop4 = this.physics.add.staticImage(hoopPosition.hoop4.x, hoopPosition.hoop4.y, 'hoop4');
    this.hoopNet = this.physics.add.staticImage(hoopPosition.hoopNet.x, hoopPosition.hoopNet.y, 'hoopNet');

    this.hoopNetScore = this.physics.add.staticImage(hoopPosition.hoopNetScore.x, hoopPosition.hoopNetScore.y, 'hoop4');
    this.hoopNetScore.setAlpha(0);

    this.hoopNetHidden = this.physics.add.staticImage(hoopPosition.hoopNetHidden.x, hoopPosition.hoopNetHidden.y, 'hoop4');
    this.hoopNetHidden.setAlpha(0);
    this.hiddenBottomCollider = this.physics.add.collider(this.ball, this.hoopNetHidden);

    // this.scoringZone = this.physics.add.staticSprite(this.hoop.x, this.hoop.y - this.hoop.height / 2, 'invisible'); // Replace with invisible texture
    // this.scoringZone.body.setSize(80, 80); // Adjust size of scoring zone
    // this.scoringZone.body.setOffset(40, 40); // Adjust offset for positioning within hoop
    // this.scoringZone.setVisible(false); // Make scoring zone invisible

    this.physics.add.collider(this.ball, this.platforms);
    this.physics.add.collider(this.ball, this.hoop2);
    this.physics.add.collider(this.ball, this.hoop6);
    this.physics.add.collider(this.ball, this.hoopb1);
    this.physics.add.collider(this.ball, this.hoopb3);
    this.physics.add.collider(this.ball, this.hoopb2);
    this.overlap = this.physics.add.overlap(this.ball, this.hoop3, this.onHit);
    this.overlap2 = this.physics.add.overlap(this.ball, this.hoopNetScore, this.onHit1);
    // this.overlap2.overlapOnly = false;

    // this.followBall = this.add.particles(-2*gameWidth, 40, 'particle1', {
    //     quantity: 5,
    //     speed: 50,
    //     scale: { start: 0.2, end: 0},
    //     angle: { min: -180, max: 180},
    //     alpha: 0.3,
    //     lifespan: 500,
    //     gravityY: 1000
    // });
    // this.followBall.startFollow(this.ball);
    // this.followBall.setAlpha(0);

    // gameTapTapShots over view
    this.gameOverRect = this.add.rectangle(gameWidth / 2, gameHeight / 2, gameWidth - 40, gameHeight - 40, 0x000).setAlpha(0);
    this.gameOverText = this.add.text(gameWidth / 2, 240, this.stringGameOver, {
      // fontFamily: 'Arial',
      fontSize: 70, color: '#eeeeee',
      stroke: '#48464F', strokeThickness: 4,
      align: 'center'
    }).setOrigin(0.5).setAlpha(0);
    // this.gameOverScoreText = this.add.text(gameWidth / 2, gameHeight / 2 - 200, 'SCORE: ' + this.hitCounter, {
    //   // fontFamily: 'Arial',
    //   fontSize: 80, color: '#ffffff',
    //   stroke: '#48464F', strokeThickness: 12,
    //   align: 'center'
    // }).setOrigin(0.5).setAlpha(0);
    this.gameOverRestart = this.add.text(gameWidth * 0.5, gameHeight * 0.8, 'RESTART', {
      // fontFamily: 'Arial',
      fontSize: 60, color: '#ffffff',
      stroke: '#48464F', strokeThickness: 4,
      align: 'center'
    }).setOrigin(0.5).setAlpha(0);

    // ad banner view
    this.adRect = this.add.rectangle(gameWidth / 2, gameHeight / 2, gameWidth, gameHeight, 0x000).setAlpha(0);
    this.adGameResume = this.add.text(gameWidth * 0.5, gameHeight * 0.9, 'RESUME', {
      // fontFamily: 'Arial',
      fontSize: 40, color: '#ffffff',
      stroke: '#48464F', strokeThickness: 4,
      align: 'center'
    }).setOrigin(0.5).setAlpha(0);

    this.input.on('pointerdown', this.onClicked);
    console.log('onCreate');

    this.setObjectBounds(this.hoop1, hoopPosition.hoop1);
    this.setObjectBounds(this.hoop2, hoopPosition.hoop2);
    this.setObjectBounds(this.hoop3, hoopPosition.hoop3);
    this.setObjectBounds(this.hoop4, hoopPosition.hoop4);
    this.setObjectBounds(this.hoop5, hoopPosition.hoop5);
    this.setObjectBounds(this.hoop6, hoopPosition.hoop6);
    this.setObjectBounds(this.hoopb1, hoopPosition.hoopb1);
    this.setObjectBounds(this.hoopb2, hoopPosition.hoopb2);
    this.setObjectBounds(this.hoopb3, hoopPosition.hoopb3);
    this.setObjectBounds(this.hoopNet, hoopPosition.hoopNet);
    this.setObjectBounds(this.hoopNetScore, hoopPosition.hoopNetScore);
    this.setObjectBounds(this.hoopNetHidden, hoopPosition.hoopNetHidden);
    this.setObjectScale(this.ball);
  }

  update() {
    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;

    // rotate ball based on velocity
    if (Math.abs(this.ball.body.velocity.x) > 5 || Math.abs(this.ball.body.velocity.y) > 5) {
      this.ball.setAngularVelocity(this.ball.body.velocity.x * 1.2);
    } else {
      this.ball.setAngularVelocity(0);
    }

    // make ball to come from another side if gone outside to one side
    if (this.ball.x > gameWidth + 80) {
      this.ball.x = -40;
    } else if (this.ball.x < -80) {
      this.ball.x = gameWidth + 40;
    }

    // update timer
    if (this.timer) {
      const elapsed = Math.max(0, this.timer.getElapsed() - 3*1000);
      const progress = Math.min(1, (this.timeDuration - elapsed) / this.timeDuration);
      this.progressTimer.width = this.innerBarWidth * progress;
      if (progress === 1) {
        this.progressTimer.width += 4;
      }
    }

    if (this.adImage) {
      if (this.showAdBanner) {
        if (this.adImage.alpha < 0.8) {
          this.adImage.setAlpha(this.adImage.alpha + 0.05);
        }
      } else {
        if (this.adImage.alpha > 0.2) {
          this.adImage.setAlpha(this.adImage.alpha - 0.03);
        }
      }
    }
  }

  checkOverlap = (object1, object2) => {
    return Phaser.Geom.Intersects.RectangleToRectangle(object1.getBounds(), object2.getBounds());
  }

  randomHoopPosition = () => {
    const gameHeight = this.game.config.height;
    const height = Phaser.Math.Between(gameHeight * 0.3, gameHeight * 0.7);
    this.hoopHeight = this.hoopHeight ? height : gameHeight * 0.5;
    this.loopSide = this.loopSide === 'right' ? 'left' : 'right';
    // this.objectScale = this.objectScale && this.loopSide === 'right' ? this.objectScales[Phaser.Math.Between(0, this.objectScales.length - 1)] : 1;
    this.objectScale = this.objectScale ? this.objectScales[Phaser.Math.Between(0, this.objectScales.length - 1)] : 1.4;
    console.log('new position', this.hoopHeight);
    console.log('objectScale', this.objectScale);
  }

  getHoopPosition = () => {
    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;

    let scaleY = this.objectScale;
    if (this.objectScale === 1.7) {
      scaleY = this.objectScale * .84;
    } else if (this.objectScale === 1.4) {
      scaleY = this.objectScale * .88;
    } else if (this.objectScale === 1.2) {
      scaleY = this.objectScale * .92;
    } else if (this.objectScale === .75) {
      scaleY = this.objectScale * 1.15;
    }
    if (this.loopSide === 'right') {
      return {
        flipX: false,

        hoop1: {x: gameWidth - 200 * this.objectScale, y: Math.ceil(this.hoopHeight + 71 + 10 - 10 * scaleY), width: 16, height: 10},
        hoop2: {x: gameWidth - 200 * this.objectScale, y: Math.floor(this.hoopHeight + 82 - 12 + 12 * scaleY), width: 16, height: 12},
        hoop3: {x: gameWidth - 127 * this.objectScale, y: Math.ceil(this.hoopHeight + 68 + 16 - 16 * scaleY), width: 130, height: 16},
        hoop4: {x: gameWidth - 127 * this.objectScale, y: Math.floor(this.hoopHeight + 85 - 18 + 18 * scaleY), width: 130, height: 18},
        hoopNetScore: {x: gameWidth - 127 * this.objectScale, y: Math.floor(this.hoopHeight + 85 + 18 * this.objectScale - 18 + 18 * scaleY), width: 130, height: 18},

        hoop5: {x: gameWidth - 54 * this.objectScale, y: Math.ceil(this.hoopHeight + 71 + 10 - 10 * scaleY), width: 16, height: 10},
        hoop6: {x: gameWidth - 54 * this.objectScale, y: Math.floor(this.hoopHeight + 82 - 12 + 12 * scaleY), width: 16, height: 12},

        hoopb1: {x: gameWidth - 40 * this.objectScale, y: this.hoopHeight + 77, width: 12, height: 12},
        hoopb2: {x: gameWidth - 26 * this.objectScale, y: this.hoopHeight, width: 16, height: 240},
        hoopb3: {x: gameWidth - 9 * this.objectScale, y: this.hoopHeight + 77, width: 18, height: 28},
        hoopNet: {x: gameWidth - 127 * this.objectScale, y: Math.floor(this.hoopHeight + 120 - 76 + 76 * scaleY), width: 155, height: 76},
        hoopNetHidden: {x: gameWidth - 127 * this.objectScale, y: this.hoopHeight + 85 + 64 * this.objectScale - 18 + 18 * scaleY, width: 130, height: 18},
      }
    }
    return {
      flipX: true,

      hoop1: {x: 53 * this.objectScale, y: Math.ceil(this.hoopHeight + 71 + 10 - 10 * scaleY), width: 16, height: 10},
      hoop2: {x: 53 * this.objectScale, y: Math.floor(this.hoopHeight + 82 - 12 + 12 * scaleY), width: 16, height: 12},
      hoop3: {x: 125 * this.objectScale, y: Math.ceil(this.hoopHeight + 68 + 16 - 16 * scaleY), width: 130, height: 16},
      hoop4: {x: 125 * this.objectScale, y: Math.floor(this.hoopHeight + 85 - 18 + 18 * scaleY), width: 130, height: 18},
      hoopNetScore: {x: 125 * this.objectScale, y: Math.floor(this.hoopHeight + 85 + 18 * this.objectScale - 18 + 18 * scaleY), width: 130, height: 18},

      hoop5: {x: 197 * this.objectScale, y: Math.ceil(this.hoopHeight + 71 + 10 - 10 * scaleY), width: 16, height: 10},
      hoop6: {x: 197 * this.objectScale, y: Math.floor(this.hoopHeight + 82 - 12 + 12 * scaleY), width: 16, height: 12},

      hoopb1: {x: 39 * this.objectScale, y: this.hoopHeight + 77, width: 12, height: 12},
      hoopb2: {x: 26 * this.objectScale, y: this.hoopHeight, width: 16, height: 240},
      hoopb3: {x: 9 * this.objectScale, y: this.hoopHeight + 77, width: 18, height: 28},
      hoopNet: {x: 127 * this.objectScale, y: Math.floor(this.hoopHeight + 120 - 76 + 76 * scaleY), width: 155, height: 76},
      hoopNetHidden: {x: 125 * this.objectScale, y: this.hoopHeight + 85 + 64 * this.objectScale - 18 + 18 * scaleY, width: 130, height: 18},
    }
  }

  onClickRestart = () => {
    // this.audioGameBg.stop();
    // this.input.off('pointerdown', this.onClickRestart);
    this.scene.switch('Game', 'MainMenu');
  }

  onClicked = (event) => {
    if (this.gameEnded) {
      return;
    }

    logDebug('event', event.position.x);
    logDebug('event1', this.ball.x);

    let motion = 'left';
    if (this.game.options.bounceLoopSide) {
      motion = this.loopSide;
    } else {
      motion = 'left';
      if (event.position.x < this.ball.x + this.ball.width / 2) {
        motion = 'right';
      }
    }

    let velocityX = 0;
    let accX = 0;
    // if (this.loopSide === 'right') {
    if (motion === 'right') {
      velocityX = 140;
      accX = 10;
      // this.ball.setAccelerationX(-5);
    } else {
      velocityX = -140;
      accX = -10;
      // this.ball.setAccelerationX(5);
    }
    this.ball.setVelocity(velocityX, -1000 - 10 * this.hitCounter);
    this.ball.setAccelerationY(500);
    this.audioJump.play();
    // this.followBall.x = 0;
    // this.followBall.y = 40;
    // this.followBall.startFollow(this.ball);
    // this.followBall.setAlpha(1);
    // if (this.timeoutFollowBallHide) {
    //     clearTimeout(this.timeoutFollowBallHide);
    //     this.timeoutFollowBallHide = null;
    // }
    // this.timeoutFollowBallHide = setTimeout(() => {
    //     // this.followBall.setAlpha(0);
    //     this.followBall.x = -2*gameWidth;
    //     this.followBall.y = 40;
    //     this.followBall.stopFollow();
    //     this.timeoutFollowBallHide = null;
    // }, 100);
    // this.ball.setAcceleration(accX* this.hitCounter*5, -10* this.hitCounter*20);
  }

  onHit = (ball, hoop) => {
    if (this.gameEnded) {
      return;
    }

    // const direction = ball.body.velocity.y > 0 ? 1 : -1;  // Check collision direction (downward: 1, upward: -1)
    if (!this.isHit2 && !this.isHit) {
      if (this.checkOverlap(hoop, ball)) {
        this.isHit = true;
      } else {
        this.isHit = false;
        // this.overlap2.overlapOnly = false;
        // this.overlap.overlapOnly = false;
        // this.hidden.body.setEnable(true);

        // setTimeout(() => {
        //     this.overlap2.overlapOnly = true;
        // this.overlap.overlapOnly = true;
        // this.hidden.body.setEnable(false);
        // }, 50);
        // console.log('miss');
      }
    }
  }

  onHit1 = (ball, hoop) => {
    if (this.gameEnded) {
      return;
    }

    // const direction = ball.body.velocity.y > 0 ? 1 : -1;  // Check collision direction (downward: 1, upward: -1)
    if (this.isHit && !this.isHit2) {
      if (this.checkOverlap(hoop, ball)) {
        this.hiddenBottomCollider.overlapOnly = true;
        if (this.hitCounter === 0) {
          EventBus.emit('eventOnStart');
        }
        this.isHit2 = true;
        this.hitCounter++;
        this.progressTimer.setAlpha(1);
        this.progressTimerBg.setAlpha(1);
        this.audioBasket.play();
        this.textScore.setAlpha(1);
        this.textScore.setText(this.hitCounter);
        if (this.hitCounter % 3 === 0) {
          this.timeDuration -= 1 * 1000; // reduce 1 second every 3 baskets
        }
        console.log('hitCounter', this.hitCounter);
        console.log('this.timeDuration', this.timeDuration);
        if (this.timer) {
          this.timer.destroy();
          this.timer = undefined;
        }

        if (this.game.options.scoreToWin > 0 && this.hitCounter >= this.game.options.scoreToWin) {
          this.endGame(true);
        } else {
          setTimeout(() => {
            this.hiddenBottomCollider.overlapOnly = false;
            this.randomHoopPosition();
            const hoopPosition = this.getHoopPosition();
            this.setObjectBounds(this.hoop1, hoopPosition.hoop1);
            this.setObjectBounds(this.hoop2, hoopPosition.hoop2);
            this.setObjectBounds(this.hoop3, hoopPosition.hoop3);
            this.setObjectBounds(this.hoop4, hoopPosition.hoop4);
            this.setObjectBounds(this.hoop5, hoopPosition.hoop5);
            this.setObjectBounds(this.hoop6, hoopPosition.hoop6);
            this.setObjectBounds(this.hoopb1, hoopPosition.hoopb1);
            this.setObjectBounds(this.hoopb2, hoopPosition.hoopb2);
            this.setObjectBounds(this.hoopb3, hoopPosition.hoopb3);
            this.setObjectBounds(this.hoopNet, hoopPosition.hoopNet);
            this.setObjectBounds(this.hoopNetScore, hoopPosition.hoopNetScore);
            this.setObjectBounds(this.hoopNetHidden, hoopPosition.hoopNetHidden);
            this.setObjectScale(this.ball);

            this.isHit = false;
            this.isHit2 = false;

            // if (this.hitCounter % 2 === 0) {
            this.showAd();
            // } else {
            this.startTimer();
            // }

          }, 500);
        }
      } else {
        this.isHit = false;
        this.isHit2 = false;
        // this.overlap2.overlapOnly = false;
        // this.overlap.overlapOnly = false;
        // this.hidden.body.setEnable(true);

        // setTimeout(() => {
        //     this.overlap2.overlapOnly = true;
        // this.overlap.overlapOnly = true;
        // this.hidden.body.setEnable(false);
        // }, 50);
        console.log('miss');
      }
    }
  }

  onMiss = (input) => {
    console.log('collide', input);
  }

  setObjectBounds = (object, bounds) => {
    object.x = bounds.x;
    object.y = bounds.y;
    object.setScale(this.objectScale);
    if (object.body) {
      object.body.x = bounds.x - bounds.width * this.objectScale / 2;
      object.body.y = bounds.y - bounds.height * this.objectScale / 2;
      object.body.width = object.width * this.objectScale;
      object.body.height = object.height * this.objectScale;
    }
  }

  setObjectScale = (object) => {
    object.setScale(this.objectScale + .5);
  }

  onTimeEnded = () => {
    this.endGame();
  }

  endGame = (isWin) =>  {
    console.log('gameEnded');
    this.gameEnded = true;
    this.ball.setAlpha(0.6);
    this.ball.setVelocity(0);
    this.ball.setAngularVelocity(0);
    this.showAdBanner = true;
    this.adImage.setAlpha(0.8);
    if (isWin) {
      this.audioGameWin.play();
      this.gameOverText.setText(this.stringWellDone);
    } else {
      this.audioGameEnd.play();
      this.gameOverText.setText(this.stringGameOver);
    }
    setTimeout(this.onGameEnded, 1000);
  }

  onGameEnded = () => {
    this.gameEnded = true;
    // this.platforms.setAlpha(0);
    // this.gameOverRect.setAlpha(0.8);
    this.gameOverText.setAlpha(1);
    // this.gameOverScoreText.setAlpha(1);
    // this.gameOverScoreText.setText('SCORE: ' + this.hitCounter);
    this.input.off('pointerdown', this.onClicked);
    setTimeout(() => {
      EventBus.emit('eventOnEnd', this.hitCounter);
    }, 2000);
  }

  showAd = () => {
    // this.input.off('pointerdown', this.onClicked);
    // this.physics.pause();
    // this.adRect.setAlpha(0.8);

    this.showAdBanner = true;
    this.updateAdImage();
    // this.adImage.setAlpha(0.8);
    setTimeout(() => {
      this.showAdBanner = false;
      // this.input.on('pointerdown', this.hideAd);
      // this.adGameResume.setAlpha(1);
      // this.adImage.setAlpha(0.2);
    }, 3000)
  }

  hideAd = () => {
    this.input.off('pointerdown', this.hideAd);
    this.adRect.setAlpha(0);
    this.adImage.setAlpha(0);
    this.adGameResume.setAlpha(0);
    setTimeout(() => {
      this.input.on('pointerdown', this.onClicked);
      this.physics.resume();
      this.startTimer();
    }, 500);
  }

  startTimer = () => {
    this.timer = this.time.addEvent({
      delay: this.timeDuration + 3 * 1000, // extra three seconds to show the ad banner
      callback: this.onTimeEnded,
    });
  }

  updateAdImage = () => {
    if (this.adImage == this.adImage1) {
      this.adImage1.setVisible(false);
      this.adImage3.setVisible(false);
      this.adImage2.setVisible(true);
      this.adImage = this.adImage2;
    } else if (this.adImage == this.adImage2) {
      this.adImage1.setVisible(false);
      this.adImage2.setVisible(false);
      this.adImage3.setVisible(true);
      this.adImage = this.adImage3;
    } else {
      this.adImage2.setVisible(false);
      this.adImage3.setVisible(false);
      this.adImage1.setVisible(true);
      this.adImage = this.adImage1;
    }
  }
}
