import React from "react";
import TextView from "./TextView";

const AppTextWrapper = ({...props}) => {
  return (
    <TextView {...props}>{props.children}</TextView>
  );
};

export default AppTextWrapper;
