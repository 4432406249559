import React from "react";
import AppImage from "./AppImage";
import utils from "../../utils/utils";

const ImageBackground = (props) => {
  let {resizeMode, style, source} = props;
  if (!resizeMode) {
    resizeMode = 'fill';
  }
  if (!style) {
    style = {};
  }
  style.position = 'relative';
  let propsImage = utils.clone(props);
  delete propsImage.children;

  return (
    <div {...props} style={style}>
      {source ?
        <AppImage resizeMode={resizeMode} source={source} style={{width: '100%', height: '100%', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}} />
        : <div style={{width: '100%', height: '100%', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}} />
      }
      {props.children}
    </div>
  );
};

export default ImageBackground;
