/* eslint-disable no-fallthrough */
import {cloneDeep, isArray, keys, toNumber} from 'lodash';

import initialState from './initialState';
import * as types from '../constants/actionTypes';
import validator from '../validator';
import {validateConfirmPassword} from '../validator/registrationValidator'

export default function registrationReducer(state = cloneDeep(initialState.registration), action) {
  let newState = cloneDeep(state);

  switch (action.type) {
    case types.UPDATE_REGISTRATION_NEXT_STEP: {
      newState.errMsg = '';
      newState.errors = getErrors(newState.currentStep, newState.requiredFields, newState.data);

      if (keys(newState.errors).length > 0) {
        return newState;
      }

      if (newState.currentStep <= 3) {
        newState.currentStep++
      }
      newState.canGoNextStep = false;
      return newState;
    }

    case types.UPDATE_REGISTRATION_DATA: {
      if (action.field === 'categoryId') {
        newState.data.subCategoryId = null;
        newState.subCategories = [];
      }
      if (action.field === 'localityId') {
        newState.data.subLocalityId = null;
        newState.data.latitude = '';
        newState.data.longitude = '';
        newState.subLocalities = [];
      }

      newState.data[action.field] = sanitizeValue(action.field, action.value);
      delete newState.errors[action.field];
      //newState.errors[action.field] = validator('registration', action.field, newState.data[action.field]);
      //newState.canGoNextStep = canGoNextStep(newState.data, newState.errors, newState.requiredFields, newState.currentStep);
      return newState;
    }

    case types.VALIDATE_REGISTRATION_DATA: {
      /*if (action.field === 'password' && newState.data.confirmPassword) {
        newState.errors.confirmPassword = validateConfirmPassword(newState.data.confirmPassword, action.value);
      } else if (action.field === 'confimPassword' && newState.data.password) {
        newState.errors.confirmPassword = validateConfirmPassword(action.value, newState.data.password);
      } else {
        newState.errors[action.field] = validator('registration', action.field, sanitizeValue(action.field, action.value));
      }*/
      return newState;
    }

    case types.SHOW_VALIDATE_MOBILE_MODAL: {
      newState.showMobileVerificationModal = true;
      return newState;
    }

    case types.HIDE_VALIDATE_MOBILE_MODAL: {
      newState.showMobileVerificationModal = false;
      return newState;
    }

    case types.FETCH_CATEGORIES_SUCCESS: {
      let data = (action.data && isArray(action.data.data)) ? action.data.data : [];
      if (action.id) {
        newState.data.subCategoryId = null;
        newState.subCategories = data;
      } else {
        newState.data.categoryId = null;
        newState.categoryId = null;
        newState.categories = data;
      }
      return newState;
    }

    case types.FETCH_BANKS_SUCCESS: {
      newState.banks = (action.data && isArray(action.data.data)) ? action.data.data : [];
      return newState;
    }

    case types.FETCH_LOCALITIES_SUCCESS: {
      let data = (action.data && isArray(action.data.data)) ? action.data.data : [];
      if (action.id) {
        newState.data.subLocalityId = null;
        newState.subLocalities = data;
      } else {
        newState.data.localityId = null;
        newState.data.subLocalityId = null;
        newState.localities = data;
      }
      return newState;
    }

    case types.RESET_LOCALITIES_DATA: {
      newState.localities = [];
      if (newState.errors.subLocalityId && !newState.data.localityId) {
        newState.errors.subLocalityId = undefined;
      }
      return newState;
    }

    case types.RESET_SUB_LOCALITIES_DATA: {
      newState.subLocalities = [];
      if (newState.errors.subLocalityId && !newState.data.localityId) {
        newState.errors.subLocalityId = undefined;
      }
      return newState;
    }

    case types.RESET_REGISTRATION_DATA: {
      if (isArray(action.fields)) {
        action.fields.forEach(function(field) {
          if (newState[field]) {
            newState[field] = cloneDeep(initialState.registration)[field];
          }
        });
      } else {
        newState = cloneDeep(initialState.registration);
      }
      return newState;
    }

    case types.RESET_REGISTRATION_ERROR_MESSAGE: {
      newState.errMsg = '';
      return newState;
    }

    case types.DO_REGISTRATION_VERIFICATION:
    case types.DO_REGISTRATION: {
      newState.errMsg = '';
      return newState;
    }

    case '@@router/LOCATION_CHANGE':
    case types.DO_LOGOUT: {
      newState = cloneDeep(initialState.registration);
      return newState;
    }

    case types.DO_REGISTRATION_SUCCESS: {
      newState = cloneDeep(initialState.registration);
      newState.isSuccess = true;
      return newState;
    }

    case types.DO_REGISTRATION_VERIFICATION_FAILURE:
    case types.DO_REGISTRATION_FAILURE: {
      if (action.data && action.data.error) {
        newState.errMsg = action.data.error;
      } else {
        newState.errMsg = 'generic.server_error_msg';
      }
      return newState;
    }

    default:
      return newState;
  }
}

function sanitizeValue(field, value) {
  switch(field) {
    case 'username':
    case 'email':
    case 'mobileNumber':
    case 'password':
    case 'confirmPassword':
    case 'businessName':
    case 'recordNumber':
    case 'formImageFile':
    case 'recordImageFile':
    case 'imageFile':
    case 'contactPersonName':
    case 'contactPersonEmail':
    case 'contactPersonNumber':
    case 'name':
    case 'nameAr':
    case 'locality':
      return value;

    case 'owingType':
    case 'categoryId':
    case 'subCategoryId':
    case 'localityId':
    case 'subLocalityId':
    case 'latitude':
    case 'longitude':
      if (!value) {
        return null;
      } else {
        return toNumber(value);
      }
  }
}

export const getErrors = function(currentStep, requiredFields,  data) {
  let errors = {};
  let fields = [];

  switch(currentStep) {
    case 4:
      fields.push(...requiredFields[4]);
    case 3:
      fields.push(...requiredFields[3]);
    case 2:
      fields.push(...requiredFields[2]);
    case 1:
      fields.push(...requiredFields[1]);
  }

  fields.forEach((field) => {
    const options = {};

    if (field === 'subLocalityId') {
      options.locality = data.locality;
    }

    const error = validator('registration', field, data[field], options);
    if (error) {
      errors[field] = error;
    } else {
      delete errors[field];
    }
  });

  if (!errors['confirmPassword'] && currentStep === 4) {
    const cnfPassErr = validateConfirmPassword(data.confirmPassword, data.password);
    if (cnfPassErr) {
      errors.confirmPassword = cnfPassErr;
    }
  }

  return errors;
}

/*function canGoNextStep(data, errors, requiredFields, currentStep) {
  const currentStepRequireFields = requiredFields[currentStep];
  if (currentStepRequireFields.length === 0) {
    return true;
  }

  for(let i = 0; i < currentStepRequireFields.length; i++) {
    const requiredField = currentStepRequireFields[i];
    if(!data[requiredField]) {
      return false;
    }

    if(errors[requiredField]) {
      return false;
    }
  }

  return true;
}*/
