
let browserInstallPrompt = null;

export const checkForInstallPrompt = () => {
  if (!window.matchMedia('(display-mode: standalone)').matches) {
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      browserInstallPrompt = event;
    }, {once: true});
  }
}

export const canShowInstallPrompt = () => {
  return browserInstallPrompt != null;
}

export const showInstallPrompt = () => {
  if (browserInstallPrompt) {
    browserInstallPrompt.prompt();
    browserInstallPrompt = null;
  }
}

export const cancelInstallPrompt = () => {
  browserInstallPrompt = null;
}
