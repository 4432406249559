import {Scene} from 'phaser';

export class GAHPreloader extends Scene {
  constructor() {
    super('GAHPreloader');
  }

  init() {
    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;
    //  We loaded this image in our GFKBoot Scene, so we can display it here
    // const bgImage = this.add.image(gameWidth / 2, gameHeight / 2, 'background');
    // bgImage.setDisplaySize(gameWidth, gameHeight);

    const barWidth = gameWidth - 96;
    const innerBarWidth = barWidth - 8;

    //  A simple progress bar. This is the outline of the bar.
    this.add.rectangle(gameWidth / 2, gameHeight / 2, barWidth, 32).setStrokeStyle(1, 0xEEEEEE);

    //  This is the progress bar itself. It will increase in size from the left based on the % of progress.
    const bar = this.add.rectangle((gameWidth - innerBarWidth) / 2, gameHeight / 2, 4, 28, 0xEEEEEE);

    //  Use the 'progress' event emitted by the LoaderPlugin to update the loading bar
    this.load.on('progress', (progress) => {

      //  Update the progress bar (our bar is 464px wide, so 100% = 464px)
      bar.width = 4 + (innerBarWidth * progress);

    });
  }

  preload() {
    let bgAudioUrl = null;
    let ballTopImageUrl = null;
    let adImageUrl = null;
    const options = this.game.options;
    if (options) {
      if (options.images && options.images.length > 0) {
        options.images.forEach((item) => {
          if (item.url && (item.url.startsWith('file:') || item.url.startsWith('blob:') || item.url.startsWith('http:') || item.url.startsWith('https:'))) {
            if (item.number === 0) {
              ballTopImageUrl = item.url;
            } else if (item.number === 1) {
              adImageUrl = item.url;
            }
          }
        });
      }
      if (options.audios && options.audios.length > 0) {
        options.audios.forEach((item) => {
          if (item.url && (item.url.startsWith('file:') || item.url.startsWith('blob:') || item.url.startsWith('http:') || item.url.startsWith('https:'))) {
            if (item.number === 0) {
              bgAudioUrl = item.url;
            }
          }
        });
      }
    }

    this.load.setPath('assets/gameArcHunter');

    this.load.image('ballTop', ballTopImageUrl || 'ballTop.png');
    this.load.image('rawAdImage1', adImageUrl || 'adImage.png');
    this.load.audio('audio_bg', bgAudioUrl || 'audios/audio_bg.m4a');

    this.load.image('rawBall', 'ball.png');
    this.load.image('handPointer', 'handPointer.png');
    this.load.audio('audio_fire', 'audios/audio_fire.mp3');
    this.load.audio('audio_hit', 'audios/audio_hit.mp3');
    this.load.audio('audio_game_end', 'audios/audio_game_end.wav');
    this.load.audio('audio_game_win', 'audios/audio_game_win.mp3');
  }

  create() {
    const space = 16;
    const ballSize = 200;
    const ballTopSize = ballSize - 2*space;
    const ballCenterX = ballSize/2;
    const ballCenterY = ballSize/2;
    const renderTextureBall = this.make.renderTexture({x: ballCenterX, y: ballCenterY, width: ballSize, height: ballSize}, false);
    const imageBall = this.add.image(ballCenterX, ballCenterY, 'rawBall')
      .setDisplaySize(ballSize, ballSize)
      .setVisible(false);
    const maskShapeBallTop = this.make.graphics({}, false)
      .fillCircle(ballCenterX, ballCenterY, ballTopSize/2);
    const imageBallTop = this.add.image(ballCenterX, ballCenterY, 'ballTop')
      .setDisplaySize(ballTopSize, ballTopSize)
      .setVisible(false);
    imageBallTop.setMask(maskShapeBallTop.createGeometryMask());
    renderTextureBall.draw(imageBall, ballCenterX, ballCenterY, 1);
    renderTextureBall.draw(imageBallTop, ballCenterX, ballCenterY, 1);
    renderTextureBall.saveTexture('ball');
    renderTextureBall.destroy(true);

    const bannerSize = 400;
    const bannerCenterX = bannerSize/2;
    const bannerCenterY = bannerSize/2;
    const renderTextureBanner = this.make.renderTexture({x: bannerCenterX, y: bannerCenterY, width: bannerSize, height: bannerSize}, false);
    const imageBanner = this.add.image(bannerCenterX, bannerCenterY, 'rawAdImage1')
      .setDisplaySize(bannerSize, bannerSize)
      .setVisible(false);
    const maskShapeBannerTop = this.make.graphics({}, false)
      .fillCircle(bannerCenterX, bannerCenterY, bannerSize/2);
    imageBanner.setMask(maskShapeBannerTop.createGeometryMask());
    renderTextureBanner.draw(imageBanner, bannerCenterX, bannerCenterY, 1);
    renderTextureBanner.saveTexture('adImage1');
    renderTextureBanner.destroy(true);

    this.scene.start('GAHGame');
  }
}
