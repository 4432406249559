import DateTimeHelper from "./DateTimeHelper";

export const handlePromiseError = (error) => {
    logError('handlePromiseError', error.message);
};

export function logDebug(tag, data = '') {
    if (process.env.NODE_ENV === 'development') {
        const date = new Date();
        console.log('R7D ['+ DateTimeHelper.getLogTimeString(date) +':'+ date.getMilliseconds() +'] '+ tag, data);
    }
}

export function logInfo(tag, data = '') {
    if (process.env.NODE_ENV === 'development') {
        const date = new Date();
        console.log('R7I ['+ DateTimeHelper.getLogTimeString(date) +':'+ date.getMilliseconds() +'] '+ tag, data);
    }
}

export function logError(tag, data= '') {
    if (process.env.NODE_ENV === 'development') {
        console.error(new Date() +': '+ tag, data);
    }
}


