import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import auth from '../utils/auth';
import {deleteToken} from '../utils/pushNotification';
import {unregister} from '../utils/serviceWorker';

import {doLogout} from '../actions/loginActions';
import {PathJoin, PathLanding, PathLogin} from "src/js/components/routes/NavHelper";
import ViewLoading from "src/js/components/commons/ViewLoading";

class Logout extends React.Component {

  componentDidMount() {
    this.props.doLogout().then(this.onLogout).catch(this.onLogout);
  }

  onLogout = () => {
    const {history} = this.props;
    unregister();
    deleteToken();
    auth.deleteUserToken();
    setTimeout(() => {
      if (history.location.search && history.location.search.length > 0) {
        this.props.history.replace({
          pathname: PathJoin,
          search: history.location.search
        });
      } else {
        history.replace(PathLanding);
      }
    }, 100);
  }

  render() {
    return <ViewLoading />;
  }
}

Logout.propTypes = {
  history: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    classes: state.classes,
    app: state.app,
    router: state.router,
    isLoading: state.isLoading,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    doLogout: () => dispatch(doLogout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
