'use strict';
import React from 'react';
import {Link, Typography} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {ColorTitle} from "src/styles/Colors";
import Linkify from 'react-linkify';

const useStyles = () => ({

});

class TextView extends React.Component {

  render() {
    let {color, variant, fontStyle, fontSizeN, fontWeight, textAlign, align, text, textId, numberOfLines, className} = this.props;
    variant = variant || "subtitle1";
    textAlign = textAlign || align;
    let style = this.props.style;
    if (style) {
      if (style.color) {
        color = style.color;
      }
      if (style.align) {
        textAlign = style.align;
        delete style.align;
      }
    }
    if (!color) {
      color = ColorTitle;
    }

    if (!style) {
      style = {};
    }
    style.wordBreak = 'break-word';
    if (numberOfLines > 0) {
      style.display = '-webkit-box';
      style.overflow = 'hidden';
      style.WebkitBoxOrient = 'vertical';
      style.WebkitLineClamp = numberOfLines;
    }

    // if (variant === 'h6') {
    //   variant = 'h5';
    // }
    style.color = color;
    delete style.fontSize;
    if (style.fontSizeN || fontSizeN) {
      let rems = 1;
      if (variant === 'h5') {
        rems = 1.5;
      } else if (variant === 'h6') {
        rems = 1.25;
      } else if (variant === 'subtitle2' || variant === 'body2') {
        rems = 0.875;
      } else if (variant === 'caption') {
        rems = 0.75;
      }
      let fontSize = style.fontSizeN || fontSizeN;
      fontSize = rems/16 * fontSize;
      style.fontSize = fontSize + 'rem';
      delete style.fontSizeN;
    }
    // else if (variant === 'h5') {
    //   let fontSize = style.fontSizeN || fontSizeN;
    //   fontSize = 1.3/16 * fontSize;
    //   style.fontSize = fontSize + 'rem';
    //   delete style.fontSizeN;
    // }

    if (fontStyle) {
      style.fontStyle = fontStyle;
    }
    if (fontWeight) {
      style.fontWeight = fontWeight;
    }
    const eText = text || (textId ? <FormattedMessage id={textId} /> : '');

    let childrenView = null;
    if (this.props.children) {
      childrenView = this.props.children;
      if (typeof this.props.children == "string") {
        childrenView = this.props.children.split(/\n/).map(line => <React.Fragment key={line}>{line}<br/></React.Fragment>);
      }
    }

    if (this.props.onLinkClick) {
      return (
        <Linkify component onClick={this.props.onLinkClick}>
          <Typography variant={variant} align={textAlign} style={style} className={className}>
              {eText}
              {childrenView}
          </Typography>
        </Linkify>
      );
    }
    return (
      <Typography variant={variant} align={textAlign} style={style} className={className}>
        {eText}
        {childrenView}
      </Typography>
    );
  }
}

TextView.propTypes = {
  classes: PropTypes.object,
  color: PropTypes.string,
  variant: PropTypes.string,
  fontStyle: PropTypes.string,
  numberOfLines: PropTypes.number,
  fontWeight: PropTypes.string | PropTypes.number,
  textAlign: PropTypes.string,
  text: PropTypes.object | PropTypes.string,
  textId: PropTypes.string,
}

export default withStyles(useStyles())(TextView);
