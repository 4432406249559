import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import auth from '../../utils/auth';

import AdminHome from '../admin/AdminHome';
import {connect} from "react-redux";
import {getRedirectSearch, PathAccount, PathLogout} from "src/js/components/routes/NavHelper";

export function PrivateNavRoute ({component: Component, user, ...rest}) {
  const isAuthed = auth.isAuthenticated();
  const isAccountPending = user.registerType > 0 && !user.gender;

  return (
    <Route
      {...rest}
      render={(props) => isAuthed === true
        ? (!isAccountPending ?
            <AdminHome history={props.history}>
              <Component {...props} />
            </AdminHome>
          : <Redirect to={{pathname: PathAccount, search: getRedirectSearch(props.history.location)}} />
        ) : <Redirect to={{pathname: PathLogout, search: getRedirectSearch(props.history.location)}} />}
    />
  )
}

PrivateNavRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object
  ]),
  authed: PropTypes.bool,
  history: PropTypes.object,
  location: PropTypes.object,
  user: PropTypes.object
};

function mapStateToProps(state) {
  return {
    user: state.admin.user,
  };
}

export default connect(mapStateToProps)(PrivateNavRoute);
