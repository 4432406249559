import {
  cloneDeep,
  concat,
  each,
  extend,
  findIndex,
  findLastIndex,
  isArray,
  isEmpty,
  isNumber,
  isObject,
  isPlainObject,
  map,
  set,
  toInteger,
  toNumber
} from 'lodash';

import initialState from './initialState';
import * as types from '../constants/actionTypes';
import validator from '../validator';
import constants from '../constants';
import CampaignHelper from "../utils/CampaignHelper";
import {FormattedMessage} from "react-intl";
import React from "react";
import auth from "src/js/utils/auth";

export const SORT_BY_LABEL_ASC = 1;
export const SORT_BY_LABEL_DESC = 2;
export const SORT_BY_VALUE_ASC = 3;
export const SORT_BY_VALUE_DESC = 4;
export const SORT_BY_VALUE1_ASC = 5;
export const SORT_BY_VALUE1_DESC = 6;
export const SORT_BY_VALUE2_ASC = 7;
export const SORT_BY_VALUE2_DESC = 8;

export default function activitiesReducer(state = cloneDeep(initialState.admin), action) {
  switch (action.type) {

    case types.FETCH_COMPANY_LEVELS_SUCCESS: {
      let data = (action.data && isArray(action.data.data)) ? action.data.data : [];
      state.activities.companyLevels = data;
      return state;
    }

    case types.FETCH_COMPANIES_SUCCESS: {
      let data = (action.data && isArray(action.data.data)) ? action.data.data : [];
      state.activities.companies = data;
      return state;
    }

    case types.FETCH_USERS_SUCCESS: {
      let data = (action.data && isArray(action.data.data)) ? action.data.data : [];
      data.forEach((item) => {
        item.name = 'Rab7# ' + item.rab7Number;
        item.isLocalModified = true;
      });
      state.activities.users = data;
      return state;
    }

    case types.FETCH_ACTIVITY_NATIONALITIES_SUCCESS: {
      const data = (action.data && isArray(action.data.data)) ? action.data.data : [];
      state.activities.nationalities = data;
      return state;
    }

    case types.FETCH_COUNTRIES_SUCCESS: {
      const data = (action.data && isArray(action.data.data)) ? action.data.data : [];
      state.activities.countries = data;
      return state;
    }

    case types.FETCH_REGIONS_SUCCESS: {
      const data = (action.data && isArray(action.data.data)) ? action.data.data : [];
      if (data) {
        data.forEach((item) => {
          item.name += ', '+ item.countryName;
        });
      }
      state.activities.regions = data;
      return state;
    }

    case types.FETCH_LOCALITIES_SUCCESS: {
      let data = (action.data && isArray(action.data.data)) ? action.data.data : [];
      if (data) {
        data.forEach((item) => {
          item.name += ', '+ item.adminName;
        });
      }
      state.activities.localities = data;
      return state;
    }

    case types.FETCH_SUB_LOCALITIES_SUCCESS: {
      let data = (action.data && isArray(action.data.data)) ? action.data.data : [];
      if (data) {
        data.forEach((item) => {
          item.name += ', '+ item.parentName;
        });
      }
      state.activities.subLocalities = data;
      return state;
    }

    case types.RESET_COMPANIES_DATA: {
      state.activities.companies = [];
      return state;
    }

    case types.RESET_USERS_DATA: {
      state.activities.users = [];
      return state;
    }

    case types.RESET_REGIONS_DATA: {
      state.activities.regions = [];
      return state;
    }

    case types.RESET_LOCALITIES_DATA: {
      state.activities.localities = [];
      return state;
    }

    case types.RESET_SUB_LOCALITIES_DATA: {
      state.activities.subLocalities = [];
      return state;
    }

    case types.RESET_ACTIVITIES_DATA: {
      if (isArray(action.fields)) {
        action.fields.forEach(function (field) {
          if (state.activities[field]) {
            state.activities[field] = cloneDeep(initialState.admin.activities)[field];
          }
        });
      } else {
        state.activities = cloneDeep(initialState.admin.activities);
      }
      return state;
    }

    case types.FETCH_CACHE_ACTIVITIES: {
      if (state.cacheListData && state.cacheListData.data && state.cacheListData.type === action.type) {
        state.activities.paginated = state.cacheListData.data;
        state.activities.scrollTo = state.cacheListData.scrollPosition;
        state.activities.isFetchInProgress = false;
        state.activities.cache = true;
      } else {
        state.activities.cacheMiss = true;
      }
      return state;
    }
    case types.FETCH_ACTIVITIES: {
      delete state.activities.cache;
      delete state.activities.cacheMiss;
      state.activities.isFetchInProgress = true;
      state.activities.errMsg = '';
      state.activities.errMsgF = '';
      return state;
    }
    case types.FETCH_ACTIVITIES_SUCCESS: {
      const data = isPlainObject(action.data) && action.data;
      if (!isEmpty(data)) {
        data.data.forEach((item) => {
          if (item.isVoucher) {
            item.type = constants.activity.types.VOUCHER;
          }
        });

        if (data.page === 1) {
          state.activities.paginated = data;
        } else {
          state.activities.paginated.data = concat(state.activities.paginated.data, data.data);
        }
        state.activities.paginated.page = data.page;
        state.activities.paginated.perPage = data.perPage;
        state.activities.paginated.canLoadMore = data.canLoadMore;
        state.activities.paginated.totalSize = calculateTotalActivitySize(data);
        if (data.userWallet) {
          auth.setUserWallet(data.userWallet);
          state.user = auth.getUser();
        }
      }
      state.activities.isFetchInProgress = false;
      return state;
    }
    case types.FETCH_ACTIVITIES_FAILURE: {
      if (action.data && action.data.error) {
        state.activities.errMsgF = action.data.error;
      } else {
        state.activities.errMsgF = 'generic.server_error_msg';
      }
      state.activities.isFetchInProgress = false;
      return state;
    }

    case types.FETCH_ACTIVITY_PLAN_RECHARGES: {
      state.activityPlanRecharges.errMsg = '';
      state.activityPlanRecharges.errMsgF = '';
      state.activityPlanRecharges.isLoading = true;
      return state;
    }
    case types.FETCH_CACHE_ACTIVITY_PLAN_RECHARGES: {
      if (state.cacheListData && state.cacheListData.data && state.cacheListData.type === action.type) {
        state.activityPlanRecharges.paginated = state.cacheListData.data;
        state.activityPlanRecharges.scrollTo = state.cacheListData.scrollPosition;
        state.activityPlanRecharges.isFetchInProgress = false;
        state.activityPlanRecharges.cache = true;
      } else {
        state.activityPlanRecharges.cacheMiss = true;
      }
      return state;
    }
    case types.FETCH_ACTIVITY_PLAN_RECHARGES_SUCCESS: {
      state.activityPlanRecharges.isLoading = false;
      const data = isPlainObject(action.data) && action.data;
      if (!isEmpty(data)) {
        if (data.page === 1) {
          state.activityPlanRecharges.paginated = data;
        } else {
          state.activityPlanRecharges.paginated.data = concat(state.activityPlanRecharges.paginated.data, data.data);
        }
        state.activityPlanRecharges.paginated.page = data.page;
        state.activityPlanRecharges.paginated.perPage = data.perPage;
        state.activityPlanRecharges.paginated.canLoadMore = data.canLoadMore;
        state.activityPlanRecharges.paginated.totalSize = calculateTotalActivitySize(data);
      }
      return state;
    }
    case types.FETCH_ACTIVITY_PLAN_RECHARGES_FAILURE: {
      state.activityPlanRecharges.isLoading = false;
      if (action.data && action.data.error) {
        state.activityPlanRecharges.errMsg = action.data.error;
      } else {
        state.activityPlanRecharges.errMsgF = 'generic.server_error_msg';
      }
      return state;
    }
    case types.FETCH_ACTIVITY_PLAN_RECHARGE_SUCCESS: {
      const data = isPlainObject(action.data) && action.data;
      if (state.activityPlanRecharges.paginated.data) {
        for (let i = 0; i < state.activityPlanRecharges.paginated.data.length; i++) {
          if (state.activityPlanRecharges.paginated.data[i].id === action.id) {
            data.activity = state.activityPlanRecharges.paginated.data[i].activity;
            state.activityPlanRecharges.paginated.data[i] = data;
          }
        }
      }
      return state;
    }

    case types.DECLARE_ACTIVITY_WINNERS_SUCCESS: {
      const data = map(state.activities.paginated.data, (activity) => {
        if (activity.id === action.id) {
          activity.canProcessWinners = 0;
          activity.endAt = action.data.endAt;
          activity.restartAt = action.data.restartAt;
          if (action.data.ad) {
            activity.ad.planEndAt = action.data.ad.planEndAt;
          }
        }
        return activity;
      });
      state.activities.paginated.data = data;
      state.activities.successMsg = <FormattedMessage id={action.aType ? 'campaign.winnersDeclared' : 'campaign.endedDone'} />;
      state.activities.errMsg = null;
      return state;
    }

    case types.FETCH_ACTIVITY_INSIGHT_CONFIGS: {
      state.activityInsight.errMsgF = '';
      state.activityInsight.errMsg = '';
      return state;
    }
    case types.FETCH_ACTIVITY_INSIGHT_CONFIGS_SUCCESS: {
      const data = isPlainObject(action.data) && action.data;
      if (!isEmpty(data)) {
        state.activityInsight.configs = data;
      }
      return state;
    }
    case types.FETCH_ACTIVITY_INSIGHT_CONFIGS_FAILURE: {
      if (action.data && action.data.error) {
        state.activityInsight.errMsg = action.data.error;
      } else {
        state.activityInsight.errMsgF = 'generic.server_error_msg';
      }
      return state;
    }

    case types.RESET_ACTIVITY_INSIGHT: {
      state.activityInsight.data = [];
      state.activityInsight.errMsgF = '';
      state.activityInsight.errMsg = '';
      return state;
    }
    case types.FETCH_ACTIVITY_INSIGHT: {
      state.activityInsight.errMsgF = '';
      state.activityInsight.errMsg = '';
      return state;
    }
    case types.FETCH_ACTIVITY_INSIGHT_SUCCESS: {
      if (action.data && action.data.length > 0) {
        action.data.forEach((data) => {
          data.excludeZero = true;
        });
      }
      state.activityInsight.data = action.data;
      return state;
    }
    case types.FETCH_ACTIVITY_INSIGHT_FAILURE: {
      state.activityInsight.data = null;
      if (action.data && action.data.error) {
        state.activityInsight.errMsg = action.data.error;
      } else {
        state.activityInsight.errMsgF = 'generic.server_error_msg';
      }
      return state;
    }
    case types.SORT_ACTIVITY_INSIGHT: {
      if (state.activityInsight.data && state.activityInsight.data.length > action.dataIndex) {
        sortInsightData(state.activityInsight.data[action.dataIndex], action.sortBy);
      }
      return state;
    }
    case types.TOGGLE_ZERO_IN_ACTIVITY_INSIGHT: {
      if (state.activityInsight.data && state.activityInsight.data.length > action.dataIndex) {
        const data = state.activityInsight.data[action.dataIndex];
        data.excludeZero = !data.excludeZero;
      }
      return state;
    }
    case types.TOGGLE_SHOW_HIDE_QNS_IN_ACTIVITY_INSIGHT: {
      if (state.activityInsight.data && state.activityInsight.data.length > action.dataIndex) {
        const data = state.activityInsight.data[action.dataIndex];
        data.showQns = !data.showQns;
      }
      return state;
    }

    case types.FETCH_ACTIVITY_COMMENTS_SUCCESS: {
      const data = isPlainObject(action.data) && action.data;
      if (!isEmpty(data) && isArray(data.data)) {
        if (data.page === 1) {
          state.activities.activity.comments = data;
        } else {
          state.activities.activity.comments.data = concat(state.activities.activity.comments.data, data.data);
          state.activities.activity.comments.page = data.page;
          state.activities.activity.comments.perPage = data.perPage;
          state.activities.activity.comments.canLoadMore = data.canLoadMore;
        }

        state.activities.activity.comments.data = processCommentForParent(state.activities.activity.comments.data);
      }
      state.activities.activity.comments.totalSize = calculateTotalActivitySize(state.activities.activity.comments);
      return state;
    }

    case types.CREATE_ACTIVITY_COMMENT_LIKE_SUCCESS: {
      state.activities.activity.comments.data = map(state.activities.activity.comments.data, (comment) => {
        if (comment.id === action.id) {
          const isLike = action.like;
          if (isLike === 1) {
            comment.likesCount += 1;
            if (comment.like === -1) {
              comment.dislikesCount -= 1;
            }
          } else if (isLike === -1) {
            comment.dislikesCount += 1;
            if (comment.like === 1) {
              comment.likesCount -= 1;
            }
          } else if (isLike === 0) {
            if (comment.like === -1) {
              comment.dislikesCount -= 1;
            }
            if (comment.like === 1) {
              comment.likesCount -= 1;
            }
          }
          comment.like = action.like;
        }

        return comment;
      });
      return state;
    }

    case types.SHOW_ACTIVITY_MODAL: {
      state.activities.errMsg = '';
      state.activities.activity.showModal = true;
      return state;
    }

    case types.SHOW_DISPLAY_CRITERIA_MODAL: {
      state.activities.errMsg = '';
      state.activities.activity.showDisplayCriteriaModal = true;
      return state;
    }

    case types.HIDE_DISPLAY_CRITERIA_MODAL:
    case types.HIDE_ACTIVITY_MODAL: {
      state.localities = [];
      state.subLocalities = [];
      state.activities.activity = cloneDeep(initialState.admin.activities.activity);
      return state;
    }

    case types.SHOW_ACTIVITY_COMMENT_MODAL: {
      state.activities.errMsg = '';
      state.activities.activity.showCommentModal = true;
      return state;
    }

    case types.HIDE_ACTIVITY_COMMENT_MODAL: {
      state.localities = [];
      state.subLocalities = [];
      state.activities.activity = cloneDeep(initialState.admin.activities.activity);
      return state;
    }

    case types.SHOW_ACTIVITY_ANSWERS_MODAL: {
      state.activities.answers.errMsg = '';
      state.activities.showAnswersModal = true;
      return state;
    }

    case types.HIDE_ACTIVITY_ANSWERS_MODAL: {
      state.activities.answers = cloneDeep(initialState.admin.activities.answers);
      state.activities.showAnswersModal = false;
      return state;
    }

    case types.FETCH_ACTIVITY_ANSWERS_SUCCESS: {
      const selectedActivity = state.activities.paginated.data.find(({id}) => id === action.id);
      state.activities.answers.data = {
        qTitle: selectedActivity && selectedActivity.qTitle,
        ...action.data,
      };
      return state;
    }

    case types.SHOW_ACTIVITY_RATINGS_MODAL: {
      state.activities.ratings.errMsg = '';
      state.activities.showRatingsModal = true;
      return state;
    }

    case types.HIDE_ACTIVITY_RATINGS_MODAL: {
      state.activities.ratings = cloneDeep(initialState.admin.activities.ratings);
      state.activities.showRatingsModal = false;
      return state;
    }

    case types.FETCH_ACTIVITY_RATINGS_SUCCESS: {
      state.activities.ratings.data = action.data;
      return state;
    }

    case types.UPDATE_ACTIVITY_DATA: {
      set(state.activities.activity.data, action.field, action.value);

      switch (action.field) {
        case 'type': {
          if (action.value == constants.activity.types.SMART_AD) {
            state.activities.activity.data.consumeLimit = '';
            state.activities.activity.data.consumeLimitPerUser = '';
            state.activities.activity.data.isCodeNeeded = 0;
            state.activities.activity.data.feedbackHint = '';
            state.activities.activity.data.feedbackHintAr = '';
          }
        }
      }

      set(state.activities.activity.errors, action.field, undefined);
      return state
    }

    case types.VALIDATE_ACTIVITY_DATA: {
      state.activities.activity.errMsg = '';
      if (action.field, action.value) {
        state.activities.activity.errors[action.field] = validator('activity', action.field, action.value);
      } else {
        const data = sanitizeData(state.activities.activity.data);
        state.activities.activity.errors = getValidationErrors(data);
      }
      return state;
    }

    case types.UPDATE_ACTIVITY:
    case types.CREATE_ACTIVITY_COMMENT: {
      state.activities.activity.errMsg = '';
      return state;
    }

    case types.CREATE_ACTIVITY_COMMENT_SUCCESS: {
      const newComment = extend({}, cloneDeep(state.activities.activity.defaultComment), action.data);
      if (newComment.level === 1) {
        state.activities.activity.comments.data = [newComment, ...state.activities.activity.comments.data];
      } else if (newComment.level > 1) {
        newComment.parent = action.createdBy;
        const indexOfParent = findIndex(state.activities.activity.comments.data, {'id': action.data.parentId});
        if (indexOfParent >= 0) {
          const lastIndexOfChild = findLastIndex(state.activities.activity.comments.data, {'parentId': action.data.parentId});
          let newCommentIndex = indexOfParent + 1;
          if (lastIndexOfChild >= 0) {
            newCommentIndex = lastIndexOfChild + 1;
          }
          state.activities.activity.comments.data.splice(newCommentIndex, 0, newComment);
        } else {
          state.activities.activity.comments.data = [newComment, ...state.activities.activity.comments.data];
        }
      }
      state.activities.activity.comments.data = processCommentForParent(state.activities.activity.comments.data);
      state.activities.activity.comments.totalSize += 1;
      return state;
    }

    case types.UPDATE_ACTIVITY_SUCCESS: {
      const activityModalStatus = state.activities.activity.showModal;
      state.activities = cloneDeep(initialState.admin.activities);

      // don't reset activity modal as it'll show message and redirect user on its own
      if (action.type === types.CREATE_ACTIVITY_SUCCESS) {
        state.activities.activity.showModal = activityModalStatus;
      }

      return state;
    }

    case types.UPDATE_ACTIVITY_FAILURE:
    case types.CREATE_ACTIVITY_COMMENT_FAILURE: {
      if (action.data && action.data.error) {
        state.activities.activity.errMsg = action.data.error;
      } else {
        state.activities.activity.errMsg = 'generic.server_error_msg';
      }
      return state;
    }

    case types.CREATE_ACTIVITY_LIKE_SUCCESS: {
      if (state.activities.activity.data.id === action.id) {
        const isLike = action.like;
        if (isLike === 1) {
          state.activities.activity.data.likesCount += 1;
          if (state.activities.activity.data.like === -1) {
            state.activities.activity.data.dislikesCount -= 1;
          }
        } else if (isLike === -1) {
          state.activities.activity.data.dislikesCount += 1;
          if (state.activities.activity.data.like === 1) {
            state.activities.activity.data.likesCount -= 1;
          }
        } else if (isLike === 0) {
          if (state.activities.activity.data.like === -1) {
            state.activities.activity.data.dislikesCount -= 1;
          }
          if (state.activities.activity.data.like === 1) {
            state.activities.activity.data.likesCount -= 1;
          }
        }
        state.activities.activity.data.like = isLike;
      }
      return state;
    }

    case types.TOGGLE_ACTIVITY_STATE_SUCCESS: {
      const data = map(state.activities.paginated.data, (activity) => {
        if (activity.id === action.id) {
          activity.state = action.newState;
        }
        return activity;
      });
      let successMessageId = null;
      if (action.aType === constants.activity.types.OFFER
        || action.aType === constants.activity.types.DISCOUNT
        || action.aType === constants.activity.types.VOUCHER) {
        successMessageId = action.newState === constants.activity.state.INACTIVE ? 'campaign.rewardDeactivatedWithId' : 'campaign.rewardActivatedWithId';
      } else {
        successMessageId = action.newState === constants.activity.state.INACTIVE ? 'campaign.campaignDeactivatedWithId' : 'campaign.campaignActivatedWithId';
      }
      state.activities.paginated.data = data;
      state.activities.successMsg = <FormattedMessage id={successMessageId}/>;
      state.activities.errMsg = null;
      return state;
    }

    case types.DELETE_ACTIVITY_SUCCESS: {
      let successMessageId = null;
      if (action.aType === constants.activity.types.OFFER
        || action.aType === constants.activity.types.DISCOUNT
        || action.aType === constants.activity.types.VOUCHER) {
        successMessageId = 'campaign.rewardDeletedWithId';
      } else {
        successMessageId = 'campaign.campaignDeletedWithId';
      }
      let index = -1;
      for (let i = 0; i < state.activities.paginated.data.length; i++) {
        let item = state.activities.paginated.data[i];
        if (item.id === action.id) {
          index = i;
          break;
        }
      }
      if (index > -1) {
        state.activities.paginated.data.splice(index, 1);
        state.activities.paginated.totalSize--;
      }
      state.activities.successMsg = <FormattedMessage id={successMessageId}/>;
      state.activities.errMsg = null;
      return state;
    }

    case types.RESET_ACTIVITIES_ERROR: {
      state.activities.errMsg = null;
      state.activities.successMsg = null;
      return state;
    }

    case types.SHOW_ACTIVITY_LINK_COPIED_SNACKBAR: {
      state.activities.errMsg = null;
      state.activities.successMsg = <FormattedMessage id={'campaign.linkCopied'}/>;
      return state;
    }

    case types.DELETE_ACTIVITY_FAILURE:
    case types.TOGGLE_ACTIVITY_STATE_FAILURE:
    case types.DECLARE_ACTIVITY_WINNERS_FAILURE: {
      if (action.data && action.data.error) {
        state.activities.errMsg = action.data.error;
      } else {
        state.activities.errMsg = <FormattedMessage id={'generic.server_error_msg'}/>;
      }
      return state;
    }

    case types.FETCH_ACTIVITY_QUESTIONS: {
      state.activities.questions = [];
      return state;
    }

    case types.FETCH_ACTIVITY_QUESTIONS_DROP_DOWN_SUCCESS: {
      const data = (action.data && isArray(action.data.data)) ? action.data.data : [];
      data.forEach((item) => {
        item.name = item.title;
        item.qTitle = item.title;
      });
      state.activities.questionsNOptions = data;
      if (state.activities.questionsNOptions) {
        state.activities.questionsNOptions.forEach((option) => {
          option.subOptions = option.qOptions;
          option.subOptionsSelected = [];
          delete option.qOptions;
        });
      }
      return state;
    }

    case types.FETCH_ACTIVITY_QUESTIONS_SUCCESS: {
      const data = (action.data && isArray(action.data.data)) ? action.data.data : [];
      data.forEach((item) => {
        item.name = CampaignHelper.getName(item);
      });
      state.activities.questions = data;
      return state;
    }

    case types.FETCH_ACTIVITY_QUESTION_OPTIONS: {
      state.activities.questionOptions[action.id] = [];
      return state;
    }

    case types.FETCH_ACTIVITY_QUESTION_OPTIONS_SUCCESS: {
      const data = (action.data && isArray(action.data)) ? action.data : [];
      // const newOptions = cloneDeep(state.activities.questionOptions);
      // newOptions[action.id] = data;
      state.activities.questionOptions[action.id] = data;
      return state;
    }

    case types.FETCH_AGE_GROUP_SUCCESS: {
      state.activities.ageGroups = action.data;
      return state;
    }

    case types.CREATE_ACTIVITY: {
      let newActivity = cloneDeep(initialState.admin.activities.activity);
      newActivity.errMsg = null;
      state.activities.activity = newActivity;
      return state;
    }

    case types.CREATE_ACTIVITY_SUCCESS: {
      let newActivity = cloneDeep(initialState.admin.activities.activity);
      newActivity.isSuccess = true;
      state.activities.activity = newActivity;
      return state;
    }

    case types.CREATE_ACTIVITY_FAILURE: {
      let newActivity = cloneDeep(initialState.admin.activities.activity);
      if (action.data && action.data.error) {
        newActivity.errMsg = action.data.error;
      } else {
        newActivity.errMsg = 'generic.server_error_msg';
      }
      state.activities.activity = newActivity;
      return state;
    }

    case types.MANAGE_ACTIVITY:
    case types.MANAGE_ACTIVITY_TARGETING: {
      let newActivity = cloneDeep(initialState.admin.activities.activity);
      newActivity.errMsg = null;
      state.activities.activity = newActivity;
      return state;
    }

    case types.MANAGE_ACTIVITY_SUCCESS:
    case types.MANAGE_ACTIVITY_TARGETING_SUCCESS: {
      let newActivity = cloneDeep(initialState.admin.activities.activity);
      newActivity.isSuccess = true;
      state.activities.activity = newActivity;
      return state;
    }

    case types.MANAGE_ACTIVITY_FAILURE:
    case types.MANAGE_ACTIVITY_TARGETING_FAILURE: {
      let newActivity = cloneDeep(initialState.admin.activities.activity);
      if (action.data && action.data.error) {
        newActivity.errMsg = action.data.error;
      } else {
        newActivity.errMsg = 'generic.server_error_msg';
      }
      state.activities.activity = newActivity;
      return state;
    }

    case types.ADD_REWARD_IN_ACTIVITY_SUCCESS:
    case types.ADD_REWARD_IN_ACTIVITY_FAILURE: {
      return state;
    }

    case types.RESET_MANAGE_ACTIVITY_ERROR_MESSAGE:
    case types.RESET_MANAGE_ACTIVITY_TARGETING_ERROR_MESSAGE: {
      let newActivity = cloneDeep(initialState.admin.activities.activity);
      newActivity.errMsg = null;
      state.activities.activity = newActivity;
      return state;
    }

    case types.FETCH_ACTIVITY: {
      state.activities.activity.errMsg = null;
      return state;
    }

    case types.FETCH_ACTIVITY_SUCCESS:
    case types.FETCH_ACTIVITY_NO_LOADING_SUCCESS: {
      state.activities.activity = cloneDeep(initialState.admin.activities.activity);
      state.activities.activity.data = action.data;
      if (action.data.business) {
        state.activities.activity.data.businesses = [action.data.business];
      }
      if (action.data.branches) {
        state.activities.activity.data.branchIds = map(action.data.branches, (branch) => branch.id);
      }
      if (!action.data.qOptions) {
        state.activities.activity.data.qOptions = [];
      }
      if (!action.data.cQuestions) {
        state.activities.activity.data.cQuestions = [];
      }
      if (!action.data.consumableWDays) {
        state.activities.activity.data.consumableWDays = [];
      }
      if (action.data.rewardedOffer) {
        action.data.rewardedOffer.name = action.data.rewardedOffer.title; // CampaignHelper.getName(action.data.rewardedOffer);
        state.activities.activity.data.rewards = [action.data.rewardedOffer];
      } else {
        state.activities.activity.data.rewards = [];
      }
      if (action.data.offer) {
        action.data.offer.name = action.data.offer.title; // CampaignHelper.getName(action.data.offer);
        state.activities.activity.data.winRewards = [action.data.offer];
      } else {
        state.activities.activity.data.winRewards = [];
      }
      if (action.data.slotValues) {
        action.data.slotValues.forEach((slot) => {
          if (slot.offers) {
            slot.offers.forEach((offer) => {
              offer.name = offer.title; // CampaignHelper.getName(offer);
            });
          }
        });
      }
      if (action.data.cQuestions) {
        action.data.cQuestions.forEach((question) => {
          question.name = question.title; // CampaignHelper.getName(question);
          state.activities.questionOptions[question.id] = [];
        });
      }
      if (action.data.adsActivities) {
        action.data.adsActivities.forEach((activity) => {
          activity.name = CampaignHelper.getName(activity);
        });
        state.activities.activity.data.searchedAdsActivities = action.data.adsActivities;
      } else {
        state.activities.activity.data.searchedAdsActivities = [];
      }

      state.activities.activity.data.videoStreamUrl = null; // enable it once hls video player is supported

      if (action.data.userWallet) {
        auth.setUserWallet(action.data.userWallet);
        state.user = auth.getUser();
      }

      return state;
    }

    case types.FETCH_ACTIVITY_FAILURE:
    case types.FETCH_ACTIVITY_NO_LOADING_FAILURE: {
      state.activities.activity = cloneDeep(initialState.admin.activities.activity);
      if (action.data && action.data.error) {
        state.activities.activity.errMsg = action.data.error;
      } else {
        state.activities.activity.errMsg = 'generic.server_error_msg';
      }
      // state.activities.activity = newActivity;
      return state;
    }

    case types.FETCH_ACTIVITY_WINNERS: {
      state.activityWinners.errMsg = '';
      state.activityWinners.errMsgF = '';
      state.activityWinners.isLoading = true;
      return state;
    }

    case types.FETCH_ACTIVITY_WINNERS_SUCCESS: {
      state.activityWinners.isLoading = false;
      const data = isPlainObject(action.data) && action.data;
      if (!isEmpty(data)) {
        state.activityWinners.paginated = data;
        state.activityWinners.paginated.totalSize = calculateTotalActivitySize(data);
      }
      return state;
    }

    case types.FETCH_ACTIVITY_WINNERS_FAILURE: {
      state.activityWinners.isLoading = false;
      if (action.data && action.data.error) {
        state.activityWinners.errMsgF = action.data.error;
      } else {
        state.activityWinners.errMsgF = 'generic.server_error_msg';
      }
      return state;
    }

    case types.FETCH_ACTIVITY_WIN_REWARDS_SUCCESS: {
      const data = (action.data && isArray(action.data.data)) ? action.data.data : [];
      data.forEach((item) => {
        item.name = item.title; // CampaignHelper.getName(item);
      });
      state.activities.activity.data.winRewards = data;
      return state;
    }

    default:
      return state;
  }

}

export const sanitizeData = (data, isEditing) =>  {
  let newData = {};

  each(data, (value, key) => {
    switch(key) {
      case 'startAt': {
        if (value) {
          const date = new Date(value);
          newData[key] = date.toISOString();
        }
      }
        break;

      case 'endAt': {
        if (value) {
          const date = new Date(value);
          newData[key] = date.toISOString();
        }
      }
        break;

      case 'type':
      case 'cGender':
      case 'cMaritalStatus': {
        if (value) {
          newData[key] = toInteger(value)
        }
      }
        break;

      case 'imageFile': {
        if (value && (value instanceof File)) {
          newData[key] = value
          delete newData.isImageDeleted;
        } else if (isEditing) {
          newData.isImageDeleted = 1;
        }
      }
        break;

      case 'imageFileAr': {
        if (value && (value instanceof File)) {
          newData[key] = value
          delete newData.isImageArDeleted;
        } else if (isEditing) {
          newData.isImageArDeleted = 1;
        }
      }
        break;

      case 'audioFile': {
        if (value && (value instanceof File)) {
          newData[key] = value
          delete newData.isAudioDeleted;
        } else if (isEditing) {
          newData.isAudioDeleted = 1;
        }
      }
        break;

      case 'audioFileAr': {
        if (value && (value instanceof File)) {
          newData[key] = value
          delete newData.isAudioArDeleted;
        } else if (isEditing) {
          newData.isAudioArDeleted = 1;
        }
      }
        break;

      case 'priority': {
        if(value || value == 0) {
          newData[key] = toNumber(value);
        }
      }
        break;

      default: {
        const isObjectType = isObject(value);
        if ((isObjectType && !isEmpty(value)) || (!isObjectType && (value || isNumber(value)))) {
          newData[key] = value;
        }
      }
    }
  });

  return newData;
};

export const getValidationErrors = function(data) {
  let errors = {};

  initialState.admin.activities.requiredFields.forEach((field) => {
    const options = {
      type: data.type,
    };

    if (field === 'imageFile') {
      options.imageUrl = data.imageUrl;
    }
    else if (field === 'qOptions') {
      options.qTitle = data.qTitle;
    }

    const error = validator('activity', field, data[field], options);
    if (error) {
      errors[field] = error;
    } else {
      delete errors[field];
    }
  });

  return errors;
}

const processCommentForParent = (comments) => {
  const parentMeta = {};
  return map(comments, (comment) => {
    parentMeta[comment.id] = comment.createdBy;

    if (comment.level >= 2 && comment.parentId && !comment.parent) {
      comment.parent = parentMeta[comment.parentId];
    }
    return comment;
  });
}

export const calculateTotalActivitySize = (data) => {
  const count = data.data.length;
  if (data.page === 1 && count === 0) return 0;

  if (!data.canLoadMore) {
    return ((data.page - 1) * data.perPage) + count;
  }

  return ((data.page - 1) * data.perPage) + count + 1;
};

const sortInsightDataLabelAsc = (a, b) => {
  const parsedA = parseInt(a.label);
  const parsedB = parseInt(b.label);
  if (!isNaN(parsedA) && !isNaN(parsedB)) {
    return (parsedA > parsedB) ? 1 : -1;
  }
  // if (this.isLabelSortById()) {
  //     return (a.id > b.id) ? 1 : -1;
  // }
  return (a.label.toString().toLowerCase().localeCompare(b.label.toString().toLowerCase())) >= 0 ? 1 : -1;
};

const sortInisghtDataLabelDesc = (a, b) => {
  const parsedA = parseInt(a.label);
  const parsedB = parseInt(b.label);
  if (!isNaN(parsedA) && !isNaN(parsedB)) {
    return (parsedA < parsedB) ? 1 : -1;
  }
  // if (this.isLabelSortById()) {
  //     return (a.id > b.id) ? -1 : 1;
  // }
  return (a.label.toString().toLowerCase().localeCompare(b.label.toString().toLowerCase())) < 0 ? 1 : -1;
};

const sortInsightData = (response, sortBy) => {
  if (sortBy === SORT_BY_LABEL_ASC) {
    if (response.values) {
      response.values.sort(sortInsightDataLabelAsc);
    }
    response.sortBy = SORT_BY_LABEL_ASC;
  } else if (sortBy === SORT_BY_LABEL_DESC) {
    if (response.values) {
      response.values.sort(sortInisghtDataLabelDesc);
    }
    response.sortBy = SORT_BY_LABEL_DESC;
  } else if (sortBy === SORT_BY_VALUE_ASC) {
    if (response.values) {
      response.values.sort((a, b) => (a.value > b.value) ? 1 : -1);
    }
    response.sortBy = SORT_BY_VALUE_ASC;
  } else if (sortBy === SORT_BY_VALUE_DESC) {
    if (response.values) {
      response.values.sort((a, b) => (a.value > b.value) ? -1 : 1);
    }
    response.sortBy = SORT_BY_VALUE_DESC;
  }
}
