import {cloneDeep} from 'lodash';

import initialState from './initialState';
import * as types from '../constants/actionTypes';
import auth from "src/js/utils/auth";

export default function homeReducer(state = initialState.home, action) {

  switch (action.type) {
    case types.FETCH_HOME: {
      const newState = cloneDeep(state);
      const landingInfo = auth.getLandingInfo() || {};
      newState.landing = landingInfo;
      return newState;
    }

    case types.FETCH_HOME_SUCCESS: {
      const landingInfo = action.data ? cloneDeep(action.data) : {};
      if (action.data) {
        auth.setLandingInfo(landingInfo);
      }
      const newState = cloneDeep(state);
      newState.landing = landingInfo;
      return newState;
    }

    case types.FETCH_HOME_FAILURE: {
      return state;
    }

    default:
      return state;
    }
}
