import {get, isString, isNumber} from 'lodash';
import constants from '../constants';

const validations = constants.validations.registration;

export default function registrationValidator(field, value, options) {
  switch(field) {
    case 'name':
      return validateShortName(value);
    case 'nameAr':
      return validateShortNameAr(value);
    case 'businessName':
      return validateCompanyName(value);
    case 'recordNumber':
      return validateRecordNumber(value);
    case 'owingType':
      return validateOwingType(value);
    case 'recordImageFile':
      return validateRecordImageFile(value);
    case 'imageFile':
      return validateImageFile(value);
    case 'categoryId':
      return validateCategoryId(value);
    case 'formImageFile':
      return validateFormImage(value);
    case 'subCategoryId':
      return validateSubCategoryId(value);
    case 'contactPersonName':
      return validateContactPersonName(value);
    case 'contactPersonNumber':
      return validateContactPersonNumber(value);
    case 'contactPersonEmail':
      return validateContactPersonEmail(value);
    case 'localityId':
      return validateLocalityId(value);
    case 'subLocalityId':
      return validateSubLocalityId(value, options);
    case 'latitude':
      return validateLatitude(value);
    case 'longitude':
      return validateLongitude(value);
    case 'username':
      return validateUsername(value);
    case 'email':
      return validateEmail(value);
    case 'mobileNumber':
      return validateMobileNumber(value);
    case 'password':
      return validatePassword(value);
    case 'confirmPassword':
      return validateConfirmPassword(value);
  }
}

export function validateShortName(value) {
  const prefix = 'registration.validation.name';

  if (validations.name.isRequired && (!isString(value) || !value.trim())) {
    return prefix + '.required';
  }
  if (value.length > validations.name.maxChar) {
    return prefix + '.max_char';
  }
}

export function validateShortNameAr(value) {
  const prefix = 'registration.validation.name_ar';

  if (validations.name_ar.isRequired && (!isString(value) || !value.trim())) {
    return prefix + '.required';
  }
  if (value.length > validations.name_ar.maxChar) {
    return prefix + '.max_char';
  }
}

export function validateCompanyName(value) {
  const prefix = 'registration.validation.business_name';

  if (validations.business_name.isRequired && (!isString(value) || !value.trim())) {
    return prefix + '.required';
  }
  if (value.length > validations.business_name.maxChar) {
    return prefix + '.max_char';
  }
}

export function validateRecordNumber(value) {
  const prefix = 'registration.validation.record_number';

  if (validations.recordNumber.isRequired && (!isString(value) || !value.trim())) {
    return prefix + '.required';
  }
  if (value.length > validations.recordNumber.maxChar) {
    return prefix + '.max_char';
  }
}

export function validateRecordImageFile(value) {
  const prefix = 'registration.validation.recordImageFile';

  if (validations.recordImageFile.isRequired && !value) {
    return prefix + '.required';
  }
}

export function validateImageFile(value) {
  const prefix = 'registration.validation.imageFile';

  if (validations.imageFile.isRequired && !value) {
    return prefix + '.required';
  }
}

export function validateOwingType(value) {
  const prefix = 'registration.validation.owing_type';

  if (validations.owingType.isRequired && !isNumber(value)) {
    return prefix + '.required';
  }
  if (value < validations.owingType.minNum) {
    return prefix + '.min_num';
  }
  if (value > validations.owingType.maxNum) {
    return prefix + '.max_num';
  }
}

export function validateCategoryId(value) {
  const prefix = 'registration.validation.category_id';

  if (validations.categoryId.isRequired && !isNumber(value)) {
    return prefix + '.required';
  }
}

export function validateFormImage(value) {
  const prefix = 'registration.validation.form_image';

  if (!value) {
    return prefix + '.required';
  }
}

export function validateSubCategoryId(value) {
  const prefix = 'registration.validation.sub_category_id';

  if (validations.subCategoryId.isRequired && !isNumber(value)) {
    return prefix + '.required';
  }
}

export function validateContactPersonName(value) {
  const prefix = 'registration.validation.contact_person_name';

  if (validations.contactPersonName.isRequired && (!isString(value) || !value.trim())) {
    return prefix + '.required';
  }
  if (value.length > validations.contactPersonName.maxChar) {
    return prefix + '.max_char';
  }
}

export function validateContactPersonNumber(value) {
  const prefix = 'registration.validation.contact_person_number';
  const mobileNumber = (isString(value) && get(value.split('-'), '[1]')) || '';

  if (validations.contactPersonNumber.isRequired && (!isString(value) || !value.trim())) {
    return prefix + '.required';
  }
  if (mobileNumber.length > validations.mobileNumber.maxChar) {
    return 'registration.validation.mobile_number.max_char';
  }
}

export function validateContactPersonEmail(value) {
  const prefix = 'registration.validation.contact_person_email';

  if (validations.contactPersonEmail.isRequired && (!isString(value) || !value.trim())) {
    return prefix + '.required';
  }
  if (value.length > validations.contactPersonEmail.maxChar) {
    return prefix + '.max_char';
  }
}

export function validateLocalityId(value) {
  const prefix = 'registration.validation.locality_id';

  if (validations.localityId.isRequired && !isNumber(value)) {
    return prefix + '.required';
  }
}

export function validateSubLocalityId(value, options) {
  const prefix = 'registration.validation.sub_locality_id';
  const isRequired = options.locality.hasChild;
  console.log('options', options);

  if (isRequired && !isNumber(value)) {
    return prefix + '.required';
  }
}

export function validateLatitude(value) {
  const prefix = 'registration.validation.latitude';

  if (validations.latitude.isRequired && !isNumber(value)) {
    return prefix + '.required';
  }
}

export function validateLongitude(value) {
  const prefix = 'registration.validation.longitude';

  if (validations.longitude.isRequired && !isNumber(value)) {
    return prefix + '.required';
  }
}

export function validateUsername(value) {
  const prefix = 'registration.validation.username';

  if (validations.username.isRequired && (!isString(value) || !value.trim())) {
    return prefix + '.required';
  }
  if (value.length < validations.username.minChar) {
    return prefix + '.min_char';
  }
  if (value.length > validations.username.maxChar) {
    return prefix + '.max_char';
  }
  if (!new RegExp(validations.username.regExp).test(value)) {
    return prefix + '.invalid_char';
  }
}

export function validateEmail(value) {
  const prefix = 'registration.validation.email';

  if (validations.email.isRequired && (!isString(value) || !value.trim())) {
    return prefix + '.required';
  }
}

export function validateMobileNumber(value) {
  const prefix = 'registration.validation.mobile_number';
  const mobileNumber = (isString(value) && get(value.split('-'), '[1]')) || '';

  if (validations.mobileNumber.isRequired && (!isString(value) || !value.trim())) {
    return prefix + '.required';
  }
  if (mobileNumber.length < validations.mobileNumber.minChar) {
    return prefix + '.min_char';
  }
  if (mobileNumber.length > validations.mobileNumber.maxChar) {
    return prefix + '.max_char';
  }
}

export function validatePassword(value) {
  const prefix = 'registration.validation.password';

  if (validations.password.isRequired && (!isString(value) || !value.trim())) {
    return prefix + '.required';
  }
  if (value.length < validations.password.minChar) {
    return prefix + '.min_char';
  }
  if (value.length > validations.password.maxChar) {
    return prefix + '.max_char';
  }
}

export function validateConfirmPassword(value, password) {
  const prefix = 'registration.validation.confirmPassword';

  if (validations.confirmPassword.isRequired && (!isString(value) || !value.trim())) {
    return prefix + '.required';
  }
  if (password && (value !== password)) {
    return prefix + '.not_matched';
  }
}
