import constants from '../constants';

const validations = constants.validations.profile;

export default function profileValidator(field, value) {
	switch (field) {
		case 'bio':
			return validateBio(value);
	}
}

export function validateBio(value) {
	const prefix = 'profile.validation.bio';

	if (value && value.length > validations.bio.maxChar) {
		return prefix + '.max_char';
	}
}
