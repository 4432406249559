import {cloneDeep, concat, isEmpty, isPlainObject, map} from 'lodash';

import initialState from './initialState';
import * as types from '../constants/actionTypes';
import constants from '../constants';
import CampaignHelper from "../utils/CampaignHelper";
import {FormattedMessage} from "react-intl";
import React from "react";
import {calculateTotalPaginatedDataSize} from "src/js/utils/ApiHelper";

export default function gamesReducer(state = cloneDeep(initialState.admin), action) {
  switch (action.type) {

    case types.RESET_GAMES_ERROR: {
      state.games.errMsg = null;
      state.games.successMsg = null;
      return state;
    }

    case types.RESET_MANAGE_GAME_ERROR_MESSAGE: {
      let newItem = cloneDeep(initialState.admin.games.activity);
      newItem.errMsg = null;
      state.games.activity = newItem;
      return state;
    }

    case types.FETCH_CACHE_GAMES: {
      if (state.cacheListData && state.cacheListData.data && state.cacheListData.type === action.type) {
        state.games.paginated = state.cacheListData.data;
        state.games.scrollTo = state.cacheListData.scrollPosition;
        state.games.isFetchInProgress = false;
        state.games.cache = true;
      } else {
        state.games.cacheMiss = true;
      }
      return state;
    }
    case types.FETCH_GAMES: {
      delete state.games.cache;
      delete state.games.cacheMiss;
      state.games.isFetchInProgress = true;
      state.games.errMsg = '';
      state.games.errMsgF = '';
      return state;
    }
    case types.FETCH_GAMES_SUCCESS: {
      const data = isPlainObject(action.data) && action.data;
      if (!isEmpty(data)) {
        if (data.page === 1) {
          state.games.paginated = data;
        } else {
          state.games.paginated.data = concat(state.games.paginated.data, data.data);
        }
        state.games.paginated.page = data.page;
        state.games.paginated.perPage = data.perPage;
        state.games.paginated.canLoadMore = data.canLoadMore;
        state.games.paginated.totalSize = calculateTotalPaginatedDataSize(data);
      }
      state.games.isFetchInProgress = false;
      return state;
    }
    case types.FETCH_GAMES_FAILURE: {
      if (action.data && action.data.error) {
        state.games.errMsgF = action.data.error;
      } else {
        state.games.errMsgF = 'generic.server_error_msg';
      }
      state.games.isFetchInProgress = false;
      return state;
    }

    case types.TOGGLE_GAME_STATE_SUCCESS: {
      const data = map(state.games.paginated.data, (activity) => {
        if (activity.id === action.id) {
          activity.state = action.newState;
        }
        return activity;
      });
      const successMessageId = action.newState === constants.activity.state.INACTIVE ? 'games.gameDeactivated' : 'games.gameActivated';
      state.games.paginated.data = data;
      state.games.successMsg = <FormattedMessage id={successMessageId}/>;
      state.games.errMsg = null;
      return state;
    }

    case types.DELETE_GAME_SUCCESS: {
      const successMessageId = 'games.gameDeleted';
      let index = -1;
      for (let i = 0; i < state.games.paginated.data.length; i++) {
        let item = state.games.paginated.data[i];
        if (item.id === action.id) {
          index = i;
          break;
        }
      }
      if (index > -1) {
        state.games.paginated.data.splice(index, 1);
        state.games.paginated.totalSize--;
      }
      state.games.successMsg = <FormattedMessage id={successMessageId}/>;
      state.games.errMsg = null;
      return state;
    }

    case types.DELETE_GAME_FAILURE:
    case types.TOGGLE_GAME_STATE_FAILURE: {
      if (action.data && action.data.error) {
        state.games.errMsg = action.data.error;
      } else {
        state.games.errMsg = <FormattedMessage id={'generic.server_error_msg'}/>;
      }
      return state;
    }

    case types.MANAGE_GAME: {
      let newActivity = cloneDeep(initialState.admin.games.activity);
      newActivity.errMsg = null;
      state.games.activity = newActivity;
      return state;
    }
    case types.MANAGE_GAME_SUCCESS: {
      let newActivity = cloneDeep(initialState.admin.games.activity);
      newActivity.isSuccess = true;
      state.games.activity = newActivity;
      return state;
    }

    case types.FETCH_GAME: {
      state.games.activity.errMsg = null;
      return state;
    }
    case types.FETCH_GAME_SUCCESS:
    case types.FETCH_GAME_NO_LOADING_SUCCESS: {
      state.games.activity = cloneDeep(initialState.admin.games.activity);
      state.games.activity.data = action.data;
      if (action.data.business) {
        state.games.activity.data.businesses = [action.data.business];
      }
      if (action.data.branches) {
        state.games.activity.data.branchIds = map(action.data.branches, (branch) => branch.id);
      }
      if (!action.data.qOptions) {
        state.games.activity.data.qOptions = [];
      }
      if (!action.data.cQuestions) {
        state.games.activity.data.cQuestions = [];
      }
      if (!action.data.consumableWDays) {
        state.games.activity.data.consumableWDays = [];
      }
      if (action.data.rewardedOffer) {
        action.data.rewardedOffer.name = action.data.rewardedOffer.title; // CampaignHelper.getName(action.data.rewardedOffer);
        state.games.activity.data.rewards = [action.data.rewardedOffer];
      } else {
        state.games.activity.data.rewards = [];
      }
      if (action.data.offer) {
        action.data.offer.name = action.data.offer.title; // CampaignHelper.getName(action.data.offer);
        state.games.activity.data.winRewards = [action.data.offer];
      } else {
        state.games.activity.data.winRewards = [];
      }
      if (action.data.slotValues) {
        action.data.slotValues.forEach((slot) => {
          if (slot.offers) {
            slot.offers.forEach((offer) => {
              offer.name = offer.title; // CampaignHelper.getName(offer);
            });
          }
        });
      }
      if (action.data.cQuestions) {
        action.data.cQuestions.forEach((question) => {
          question.name = question.title; // CampaignHelper.getName(question);
          state.games.questionOptions[question.id] = [];
        });
      }
      if (action.data.adsActivities) {
        action.data.adsActivities.forEach((activity) => {
          activity.name = CampaignHelper.getName(activity);
        });
        state.games.activity.data.searchedAdsActivities = action.data.adsActivities;
      } else {
        state.games.activity.data.searchedAdsActivities = [];
      }

      state.games.activity.data.videoStreamUrl = null; // enable it once hls video player is supported

      return state;
    }

    case types.FETCH_GAME_FAILURE:
    case types.FETCH_GAME_NO_LOADING_FAILURE:
    case types.MANAGE_GAME_FAILURE: {
      state.games.activity = cloneDeep(initialState.admin.games.activity);
      if (action.data && action.data.error) {
        state.games.activity.errMsg = action.data.error;
      } else {
        state.games.activity.errMsg = 'generic.server_error_msg';
      }
      return state;
    }

    default:
      return state;
  }

}
